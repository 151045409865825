import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import ee from "../../../services/earth-engine";
import { Box, Button, Checkbox, Typography } from "@material-ui/core";
import GoogleMap from "../map/GoogleMap";
import StepperButtons from "../acquisition/StepperButtons";
import * as MapGoogle from "../../../common/map";
import { Actions as Acquisition } from "../../../store/ducks/acquisition";
import TourGuider from "../tour/TourGuider";
import { useLocalStorage } from "../../../common/utils";
import HelpButton from "../core/HelpButton";
import { getMangroves } from "../../../algorithms/csqueeze";
import Alert from "@material-ui/lab/Alert";
import { get as getSatellite } from "../../../common/satellites";
import {maskS2Clouds as maskS2CloudsSentinel} from "../../../algorithms/satellite/sentinel";
import { IconButton, makeStyles, Tooltip } from "@material-ui/core";
import { HelpOutlineOutlined } from "@material-ui/icons";
import {SentinelCloudCoverageCache} from "../../../algorithms/satellite/sentinel";

// useStyles is a hook for styling this component with Material UI's styling solution

// This is the page of the second step of the aquisition wizard
// it is supposed to get the Area of Interest (AOI) from the map
// and to save it in the storage.
// It also shows a button to start the third step.
const AOIChooser = ({ navigate, ...extra }) => {
  const dispatch = useDispatch();
  const mapDiv = useRef();
  const mapRef = useRef();
  const drawingManager = useRef();
  const mangroveLayer = useRef();

  // get the current language
  const [t] = useTranslation();
  const [overlay, setOverlay] = useState(null);
  const [coordinates, setCoordinates] = useState(null);
  const [areaOfPolygon, setAreaOfPolygon] = useState(null);

  const [mangrove, setMangrove] = useState(false);
  const [continueEnable, setContinueEnable] = useState(false);
 

   let saveZoomChange = function () {
      let zoom=MapGoogle.getZoomLevel();
      if(typeof(zoom)!=="undefined" && zoom !=="undefined"){
        window.sessionStorage.setItem("zoom_level",zoom);
      }
   };
   window.sessionStorage.removeItem("zoom_level"); //clear previous saved zoom (aquisition -first use case)
   setInterval(saveZoomChange, 5000); 
   
  useEffect(() => {
    // const mangroves = getMangroves();
    // console.log("mangroves", mangroves);
/*
    mapRef.current = new window.google.maps.Map(mapDiv.current, {
      center: { lat: -26.285757, lng: -48.73506 },
      zoom: 10,
      scaleControl: true,
      streetViewControl: false,
      mapTypeControl: true,
      mapTypeControlOptions: {
        style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: window.google.maps.ControlPosition.TOP_LEFT,
      },
      fullscreenControl: true,
      styles: [
        {
          featureType: "poi",
          stylers: [{ visibility: "off" }],
        },
      ],
    });

    drawingManager.current = new window.google.maps.drawing.DrawingManager({
      // drawingMode: "rectangle",
      drawingControl: true,
      drawingControlOptions: {
        style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: window.google.maps.ControlPosition.TOP_CENTER,
        drawingModes: [
          window.google.maps.drawing.OverlayType.POLYGON,
          window.google.maps.drawing.OverlayType.RECTANGLE,
        ],
      },
      polygonOptions: {
        fillColor: "#00B3A1",
        fillOpacity: 0.2,
        strokeColor: "#003832",
        strokeOpacity: 1,
        strokeWeight: 0.5,
      },
    });

    drawingManager.current.setMap(mapRef.current);

    window.google.maps.event.addListener(
      drawingManager.current,
      "overlaycomplete",
      handleDrawing
    );
*/
    MapGoogle.setDrawingControlsVisible(false);
      console.log("Map: useEffect", Map);
    return () => {
       MapGoogle.onZoomChange(() => {
        saveZoomChange();
      });
    };
  }, []);

  
  // handle drawing the AOI on the map
  const handleDrawing = (overlay, coordinates) => {
    setOverlay(overlay);
    setCoordinates(coordinates);
    window.localStorage.setItem("AOIIntertidal",JSON.stringify(coordinates));
    // disable the drawing controls, when the AOI is drawn
    MapGoogle.setDrawingControlsVisible(false);
    fillButtonColor("buttonArea");
    setContinueEnable(enableContinue());
  };

  function enableContinue(){
   return  window.localStorage.getItem("AOIIntertidal") !== null && window.localStorage.getItem("tideData") !== null && window.localStorage.getItem("intertidalPeriodData") !== null ;    
  }

  // handle the undoing of the AOI
  const handleUndo = () => {
    overlay.setMap(null);
    setOverlay(null);
    setCoordinates(null);
    // enable the drawing controls
    MapGoogle.setDrawingControlsVisible(true);
  };


  const saveCloudCoverageValues = function (startDate, endDate, roi){
    const cloudPropertyName = "roiCloudPercentage";
    const dtPropertyName = "system:time_start";

    let _cloudCache = new SentinelCloudCoverageCache()
    let collectionWithROICloud = _cloudCache.calculateCloudCoverage(roi)

    let imagesWithCloud = collectionWithROICloud.getInfo(); 
    console.log("SentinelCloudCoverageCache.roiCloudCoveragePerDate (GET INFO)" , imagesWithCloud);

    let dateCloudMap = imagesWithCloud.features.map(function(el){
      return {
        "dt": el.properties[dtPropertyName], 
        "cloud": el.properties[cloudPropertyName]
      };
    });

    console.log("Date to cloud pct mapping", dateCloudMap);
    window.sessionStorage.setItem("roiCloudCoveragePerDate", JSON.stringify(dateCloudMap));  
  }


  const getSelectedSatellite = function (){
    let selectedSatelliteIndex = Number.parseInt(window.sessionStorage.getItem( "selectedSatelliteIndex"));
    let satellite = getSatellite(selectedSatelliteIndex);
    return satellite;
  }

  const handleChoose = () => {
    let satellite = getSelectedSatellite();
    let startYear = satellite.missions[0].startYear;
    let endYear = new Date().getFullYear();
    saveCloudCoverageValues (`${startYear}-01-01`, `${endYear}-12-31`, ee.Geometry.Polygon([coordinates]));
    try{      
      dispatch(
        Acquisition.setAOI(
          overlay,
          coordinates,
          ee.Geometry.Polygon([coordinates])
        )
      );
    }catch(e){
      window.alert("Your account still is not allowed to use GEE API. You should complete your register using this link: https://earthengine.google.com/signup/");
      window.location = "https://earthengine.google.com/signup/";
    }
    try{       
            //get images for next step
            let selectedSatelliteIndex = Number.parseInt(window.sessionStorage.getItem( "selectedSatelliteIndex"));
            let satellite = getSatellite(selectedSatelliteIndex);
            //console.log("Satellite Dynamic", satellite, "missions", satellite.missions);
            let missions = satellite.missions;
            let period  = JSON.parse(window.localStorage.getItem("intertidalPeriodData"));
            let geometry = JSON.parse(window.localStorage.getItem("AOIIntertidal"));
            const cloudCoverageThreshold = Number.parseFloat(period.cloudFilter);
            geometry = ee.Geometry.Polygon(geometry);
           // console.log("geometry", geometry);
            const eeDateStart = new ee.Date(period.startDate);//new Date(period.startDate);
            const eeDateEnd =  new ee.Date(period.endDate); //new Date(period.endDate)
            let mission = missions[selectedSatelliteIndex]; 
            let collection = null;
            collection = ee.ImageCollection(mission.name).filterBounds(geometry);
            collection = collection.filter(ee.Filter.date(eeDateStart, eeDateEnd));
            let firstImage =  collection.first(); 
          collection = collection.getInfo();
          //  console.log("collection-date (after getInfo())", collection); 
          //  console.log("QA BAND", mission.bands.qa);
            const scale = 60;

            //let testCoverage = maskS2CloudsSentinel(firstImage, geometry, mission.bands.qa, scale );
            let tempArray = [];  
            let thumbnailParams ={region: geometry,bands:[mission.bands.red,mission.bands.green,mission.bands.blue],dimensions: 512,max:mission.vizParams.max, min:mission.vizParams.min, format: 'jpg'};       
            window.sessionStorage.setItem("selectedMission",JSON.stringify(mission));
            window.localStorage.setItem("thumbnailParams", JSON.stringify(thumbnailParams));
            let datesAvailable =   collection.features.map((entry) => {
              let dt = new Date(entry.properties["system:time_start"]);
              let cloudCoverage = 0;
              let thumbnailURL = "";
              let  imageFilterObject = null; window.localStorage.getItem(entry.id);
              //if(imageFilterObject === null){
                  try{
                    let eeImage = ee.Image(entry.id);
                    eeImage.idString  = entry.id;
                  // console.log("imgId",eeImage.idString, "band",eeImage.select(mission.bands.qa));
                    cloudCoverage = maskS2CloudsSentinel(eeImage, geometry, mission.bands.qa, scale, dt.toISOString(), true );
                    //console.log("cloudCoverage",  cloudCoverage);  
                    thumbnailURL = eeImage.getThumbURL(thumbnailParams); 
                  }catch(e){
                    console.log("error cloud coverage", e);
                  } 
                  imageFilterObject  = {   
                    id: entry.id,
                    name: mission.name,
                    shortname: mission.shortname,
                    date: dt,
                    dateFormated: dt.getFullYear()+"-"+dt.getMonth() + "-" + dt.getDate(),
                    dateTime: dt.getTime(),
                    content: cloudCoverage,
                    thumbnailURL: thumbnailURL,
                  }; 
                  //window.localStorage.setItem(entry.id, JSON.stringify(imageFilterObject));
              //}else{
               // console.log("entry.id", entry.id, "was cached"); 
                //imageFilterObject = JSON.parse(imageFilterObject);
              //} 
              tempArray.push(imageFilterObject);
              return entry;
            }); 
            //console.log("datesAvailable", datesAvailable, tempArray); 
            //calculate cloud coverage based on avarage of day images
            const map = new Map();
            tempArray.forEach( imgObject =>{
              let objectKey = imgObject.dateFormated;
              let objectFound = map.get(objectKey);
              if(objectFound === undefined){
                map.set(objectKey,imgObject );
                imgObject.imagesSameDay = [];
              }else{
                objectFound.imagesSameDay.push(imgObject);
              } 
            });

            tempArray = Array.from(map, ([name, value]) => ({ ...value })); 
            tempArray = tempArray.map(imgObject =>{
              let biggerDate = imgObject.dateTime;
              imgObject.imagesSameDay.forEach(img => {
                if(img.dateTime>biggerDate){
                  biggerDate = img.dateTime;
                  imgObject.content = img.content; 
                }
              });
              return imgObject;
            });  
            //include cloud filter
           // console.log("Unique dates", tempArray);
            tempArray = tempArray.filter(img=>{
            return  img.content === -1 || cloudCoverageThreshold >= img.content ? true : false;
            });
           // console.log("After cloud filter", tempArray);
            dispatch(Acquisition.setAvailableDates(tempArray));
            
    }catch(e){
      console.error("Error image filter", e); 
    }   
   
  };

  const handleMangrove = (e) => {
    let visible = typeof e === "boolean" ? e : e.target.checked;
    if (!mangroveLayer.current) mangroveLayer.current = getMangroves();

    if (visible) {
      mapRef.current.overlayMapTypes.push(mangroveLayer.current);
    } else {
      mapRef.current.overlayMapTypes.clear();
    }

    setMangrove(visible);
  };

  // check if the AOI is already saved
  const drawn = Boolean(coordinates);

  // defines the steps for the tour
  const steps = [
    {
      selector: "#areachooser",
      content: t("tour.csqueeze.2.aoi"),
    },
    {
      selector: "#mangrove",
      content: t("tour.csqueeze.2.mangrove"),
    },
    {
      selector: "#importKml",
      content: t("tour.csqueeze.2.importKml"),
    },
  ];

  // create a localStorage object to check if the user has already seen the tour
  const [isTourOpen, setIsTourOpen] = useLocalStorage("showROITour", true);

  const readFileTrainingData = function () {
    let fileField = document.getElementById("file_import_training_data");
    if (fileField.files.length === 0) {
      window.alert("Please select a file first.");
      return false;
    }
    const file = fileField.files[0];
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      function () {
        console.log("File data:", reader.result);
        const fileContent = reader.result;
        let lines = fileContent.split("\n");
        lines = lines.splice(1, lines.length);
        let matrix = [];
        lines.forEach((line) => {
          let columns = line.split(",");
          let x = Number.parseFloat(columns[0]);
          let y = Number.parseFloat(columns[1]);
          let z = Number.parseFloat(columns[2]);
          matrix.push([x, y, z]);
        });
        console.log("matrix", matrix);
        window.alert("Training file processed.");
        closeTideFilePanel();
        setContinueEnable(enableContinue());
      },
      false
    );
    if (file) {
      reader.readAsText(file);
    }
  };

  const readFileData = function () {
    let fileField = document.getElementById("file_import_kml");
    if (fileField.files.length === 0) {
      window.alert("Please select a file first.");
      return false;
    }
    const file = fileField.files[0];
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      function () {
        console.log("File data:", reader.result);
        const fileContent = reader.result;
        const kmlTokenBegin = "<coordinates>";
        const kmlTokenEnd = "</coordinates>";
        let isKml = fileContent.indexOf(kmlTokenBegin) !== -1;
        if (isKml) {
          let coordinatesString = fileContent
            .split(kmlTokenBegin)[1]
            .split(kmlTokenEnd)[0];
          let coordinatesArrayStrings = coordinatesString
            .split(" ")
            .map((el) => el.trim());
          let coordinatesArray = [];
          coordinatesArrayStrings.forEach((el) => {
            if (el.trim() !== "") {
              let coordinatesValues = el.split(",");
              coordinatesValues = coordinatesValues.map((el) => parseFloat(el));
              if (!isNaN(coordinatesValues[0])) {
                coordinatesArray.push([
                  coordinatesValues[0],
                  coordinatesValues[1],
                ]);
              }
            }
          });
          //select area
          if (coordinatesArray.length > 0) {
            console.log("coordinatesArray", coordinatesArray);
            let overlayFixed = MapGoogle.drawOutline(coordinatesArray);
            setOverlay(overlayFixed);
            setCoordinates(coordinatesArray);
            window.localStorage.setItem("AOIIntertidal",JSON.stringify(coordinatesArray));
            MapGoogle.centralize(coordinatesArray[0][1], coordinatesArray[0][0]);
            MapGoogle.setDrawingControlsVisible(false);
            window.alert("KML file processed.");
            fillButtonColor("buttonArea");
            document.getElementById("dialogSubArea").close();
            console.log("set button color....");
            closeFilePanel();
            setContinueEnable(enableContinue());
          } else { 
            window.alert("No valid coordinates were found in the KML file.");
          }
        } else {
          window.alert("It was not possible to interpret the selected file.");
        }
      },
      false
    );
    if (file) {
      reader.readAsText(file);
    }
  };


  const readFileTideData = function () {
    let fileField = document.getElementById("file_import_tide_data");
    if (fileField.files.length === 0) {
      window.alert("Please select a file first.");
      return false;
    }
    const file = fileField.files[0];
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      function () {
        console.log("File data:", reader.result);
        const fileContent = reader.result;
        let lines = fileContent.split("\n");
        let tideDataFileContent = [];
        console.log("lines", lines);
        lines.splice(0,1);
        lines.forEach(line =>{
          if(line !== ""){
            let columns = line.split(",");
            if(columns.length === 3){
              let tideDataElement = columns[0];
              let date = columns[1];
              let time = columns [2].replace("\r","");
              tideDataFileContent.push([tideDataElement, date, time]);
            }else{
              console.warn("Problem with line in tide data file:", line, "- do not contains the proper format.");
            }
          }
        });
        window.localStorage.setItem("tideData", JSON.stringify(tideDataFileContent));
        window.alert("Tide data file processed.");
        fillButtonColor("buttonTideData");
        document.getElementById("dialog_tide_data").close();
        document.getElementById("subTideDataButton").close();
        setContinueEnable(enableContinue()); 
        
      },
      false
    );
    if (file) {
      reader.readAsText(file);
    }
  };


  const handleDisplayDefinePeriod = function () {
    let dialog = document.getElementById("dialog_define_period");
    dialog.showModal();
  };

  const handleDisplayImportKML = function () {
    let dialog = document.getElementById("dialog_kml");
    dialog.showModal();
  };

  

  const handleDisplayImportTideData = function () {
    let dialog = document.getElementById("dialog_tide_data");
    dialog.showModal();
  };

  const displaySubAreaButton = function () {
    
    let dialog = document.getElementById("dialogSubArea");
    dialog.showModal();

  };


  const displaySubTideDataButton = function () {
    let dialog = document.getElementById("subTideDataButton");
    dialog.showModal();
    

  };

  

  const displayDrawOnMapDetailsArea = function () {
    if(document.getElementById("drawOnMapDetails").style.display === "block"){
      document.getElementById("drawOnMapDetails").style.display = "none";
    }else{
      document.getElementById("drawOnMapDetails").style.display = "block";
      MapGoogle.setDrawingControlsVisible(true);
      document.getElementById("dialogSubArea").close();
    } 
  };

  

  const closeFilePanel = function () {
    let dialog = document.getElementById("dialog_kml");
    dialog.close();
  }; 

  const closeTideFilePanel = function () {
    let dialog = document.getElementById("dialog_tide_data");
    dialog.close();
  };

  const closePeriodDialog = function () {
    let dialog = document.getElementById("dialog_define_period");
    dialog.close();
  };

  const confirmPeriodDialog = function () {

    let startDate = document.getElementById("intertidalStartDate").value;
    let endDate = document.getElementById("intertidalEndDate").value;
    let cloudFilter = document.getElementById("intertidalCloudFilter").value;

    if(startDate === ""){
      window.alert("Start date is mandatory");
      return;
    }

    if(endDate === "" ){
      window.alert("End date is mandatory");
      return;
    }

    let dtStart = new Date(document.getElementById("intertidalStartDate").valueAsNumber); 
    let dtEnd = new Date(document.getElementById("intertidalEndDate").valueAsNumber);
    if(dtStart > dtEnd){
      window.alert("End date must be after the start date.");
      return;
    }
    let difference = dtEnd - dtStart;
    let diffenceInMonths = difference/1000/60/60/24/30;
    console.log("diffenceInMonths", diffenceInMonths);

    if(cloudFilter === ""){
      window.alert("Cloud filter is mandatory");
      return;
    }

    console.log("Period values:",startDate,endDate, cloudFilter);

    window.localStorage.setItem("intertidalPeriodData",JSON.stringify({startDate, endDate, cloudFilter}));


    fillButtonColor("buttonPeriod"); 
    let dialog = document.getElementById("dialog_define_period");
    dialog.close();
    setContinueEnable(enableContinue());
  };

  const showWarningQA602022 = function (){
    let dtEnd = new Date(document.getElementById("intertidalEndDate").valueAsNumber);
    let display = "none";
    if(dtEnd > new Date(2022,1,1)) display = "block";
    document.getElementById("alert_QA60_2022").style.display = display;
  }

  const displayTrainingButton = function () {
    let url = window.location.href;
    if (url.indexOf("bathymetry") != -1) {
      return "inline-block";
    }
    return "none";
  };

  const showHelpPeriod = function(){
    document.getElementById("alert_period_months").style.display="block";
  }

  const createItems = () => {
    return [0, 0.025, 0.05, 0.1, 0.15, 0.2, 0.3, 0.4, 0.5, 1].map(
      (value, i) => (
        <option key={i} value={value}>
          {`<= ${value * 100} %`}
        </option>
      )
    );
  };

  const fillButtonColor = function(id){
    setTimeout( ()=> {
      document.getElementById(id).classList.add("MuiButton-containedPrimary");
      console.log("printing background...");
    }, 1000);
  }

  const clearButtonColor =  function(id){
    document.getElementById(id).classList.remove("MuiButton-containedPrimary");
  }

 setTimeout(()=>{

  if( window.localStorage.getItem("AOIIntertidal") === null ){
    document.getElementById("buttonArea").classList.remove("MuiButton-containedPrimary");
  }else if (coordinates === null){
    let coordinatesArray =  JSON.parse(window.localStorage.getItem("AOIIntertidal"));
    console.log("coordinatesArray (onload/setTimeout)", coordinatesArray);
    let overlay = MapGoogle.drawOutline(coordinatesArray);
    setOverlay(overlay);
    setCoordinates(coordinatesArray);
    dispatch(
      Acquisition.setAOI(
        overlay,
        coordinatesArray ,
        ee.Geometry.Polygon([coordinatesArray])
      )
    );
  } 
  if( window.localStorage.getItem("tideData") === null ){
    document.getElementById("buttonTideData").classList.remove("MuiButton-containedPrimary");
  } 
  if(window.localStorage.getItem("intertidalPeriodData") === null){
    document.getElementById("buttonPeriod").classList.remove("MuiButton-containedPrimary")
  }    
 }, 500);
 setTimeout( ()=>{ setContinueEnable(enableContinue());},2000);



  return (
    <React.Fragment>
      <Box>
        <Box
          textAlign="center"
          display="flex"
          justifyContent="center"
          marginBottom={areaOfPolygon ? -9 : 0}
          marginTop={areaOfPolygon ? +3 : 0}
          style={{ color: "white" }}
        >
          {areaOfPolygon && (
            <React.Fragment>
              {areaOfPolygon > 20 ? (
                <Typography
                  style={{
                    borderRadius: 10,
                    padding: "10px 20px",
                    backgroundColor: "#f50057",
                    fontWeight: 800,
                  }}
                >
                  {t("forms.csqueeze.2.performanceMessage")
                    .replace("{number}", areaOfPolygon.toFixed(2))
                    .replace(
                      "{level}",
                      t("forms.csqueeze.2.slow").toUpperCase()
                    )}
                </Typography>
              ) : areaOfPolygon > 10 ? (
                <Typography
                  style={{
                    borderRadius: 10,
                    padding: "10px 20px",
                    backgroundColor: "#f44336",
                    fontWeight: 800,
                  }}
                >
                  {t("forms.csqueeze.2.performanceMessage")
                    .replace("{number}", areaOfPolygon.toFixed(2))
                    .replace(
                      "{level}",
                      t("forms.csqueeze.2.moderate").toUpperCase()
                    )}
                </Typography>
              ) : (
                <Typography
                  style={{
                    borderRadius: 10,
                    padding: "10px 20px",
                    backgroundColor: "#009688",
                    fontWeight: 800,
                  }}
                >
                  {t("forms.csqueeze.2.performanceMessage")
                    .replace("{number}", areaOfPolygon.toFixed(2))
                    .replace(
                      "{level}",
                      t("forms.csqueeze.2.fast").toUpperCase()
                    )}
                </Typography>
              )}
            </React.Fragment>
          )}
        </Box>


        <dialog id="dialog_tide_data">
          <fieldset
            style={{ borderColor: "#BBB", display: "block" }}
            id="import_kml_component"
          >
            <legend> Import tide data file</legend>
            <input
              id="file_import_tide_data"
              accept=".csv"
              type="file"
              onChange={readFileTideData}
            />
          </fieldset>
          <br />
          <div>
              <Alert
                elevation={6}
                variant="filled"
                severity="warning"
              >
                <a style={{textDecoration:"none", color:"#FFF"}} href="/tide_Itaqui.csv">Download example file</a>
              </Alert>    
          </div>
          <br />
          <Button
            color="secondary"
            variant="contained"
            onClick={()=>{document.getElementById("dialog_tide_data").close()}}
          >
            {t("forms.map.cancel")}
          </Button>
        </dialog>
  
        <dialog id="dialog_kml">
          <fieldset
            style={{ borderColor: "#BBB", display: "block" }}
            id="import_kml_component"
          >
            <legend> {t("forms.acquisition.2.importLegend")}</legend>
            <input
              id="file_import_kml"
              accept=".kml"
              type="file"
              onChange={readFileData}
            />
          </fieldset>
          <br />
          <Button
            color="secondary"
            variant="contained"
            onClick={closeFilePanel}
          >
            {t("forms.map.cancel")}
          </Button>
        </dialog>
        <Button
          onClick={handleDisplayImportTideData}
          style={{
            cursor: "pointer",
            display: displayTrainingButton(),
            color: "#26a69a",
            left: 675,
            top: 100,
            backgroundColor: "#FFF",
            zIndex: 99,
          }}
        >
          Data Training
        </Button>
        <dialog id="dialog_tide_data">
          <fieldset
            style={{ borderColor: "#BBB", display: "block" }}
            id="import_csv_component"
          >
            <legend> Import tide data</legend>
            <input
              id="file_import_training_data"
              accept=".csv"
              type="file"
              onChange={readFileTrainingData}
            />
          </fieldset>
          <Button
            color="secondary"
            variant="contained"
            onClick={closeTideFilePanel}
          >
            {t("forms.map.cancel")}
          </Button>
        </dialog>

        <dialog id="dialog_define_period" >
          <h1>{t("forms.acquisition.2.intertidalPeriodSelection")}</h1>
          <br /><br />
          <label for="intertidalStartDate">{t("forms.acquisition.2.intertidalPeriodStartDate")}</label><br />
          <input type="date" id="intertidalStartDate" />

          <br /><br />

          <label for="intertidalEndDate">{t("forms.acquisition.2.intertidalPeriodEndDate")}</label><br />
          <input type="date" id="intertidalEndDate" onChange={showWarningQA602022} />

 
          <IconButton
            aria-label="help"
            size="medium"
            onClick={showHelpPeriod}
          >
            <HelpOutlineOutlined color="primary" fontSize="inherit" />
          </IconButton>
     
          <br /><br />

          <label for="intertidalCloudFilter">{t("forms.acquisition.2.intertidalCloudFilter")}</label><br />

          <select id="intertidalCloudFilter">
            {createItems()}
          </select>
          
          <br /><br />

          <Alert style={{display:"none"}}
          elevation={6}
          id="alert_QA60_2022"
          variant="filled"
          severity="warning"
          >
            Sentinel 2: Cloud mask from polygons. Empty after Feb 2022.
            <br />
            <a target="_blank" href="https://developers.google.com/earth-engine/datasets/catalog/COPERNICUS_S2_HARMONIZED">
              See more
            </a>
        </Alert>

          <br /><br />
          <Alert
          style={{display:"none"}}
          id="alert_period_months"
          elevation={6}
          variant="filled"
          severity="warning"
        >
          3 months is the ideal period for analyzes of the intertidal region.<br /> Shorter periods may generate few images for analysis.<br/>Longer periods may present changes in the morphology,<br/>  which may influence the results of topography in the intertidal zone.
        </Alert>
          
          <br />
          
          <Button
            color="secondary"
            variant="contained"
            onClick={closePeriodDialog}
          >
            {t("forms.map.cancel")}
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button
            color="primary"
            variant="contained"
            onClick={confirmPeriodDialog}
          >
            {t("forms.acquisition.2.intertidalConfirmPeriod")}
          </Button>
         
        </dialog>

        <HelpButton
          onClickFunction={() => {
            setIsTourOpen(true);
          }}
        />
        <Box
          id="mangrove"
          style={{
            height: "40px",
            position: "absolute",
            backgroundColor: "#FFF",
            boxShadow: "rgba(0, 0, 0, 0.3) 0px 1px 4px -1px",
            borderRadius: "2px",
            border: "1px solid rgba(0, 0, 0, 0.3)",
            padding: "0px 10px 0px 0px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: "99",
            marginTop: "220px",
            marginLeft: "10px",
            cursor: "pointer",
          }}
          sx={{ boxShadow: 12 }}
          onClick={() => handleMangrove(!mangrove)}
        >
          <Checkbox
            checked={mangrove}
            // onClick={handleMangrove}
            inputProps={{ "aria-label": "primary checkbox" }}
          />
          <Typography>{t("forms.acquisition.2.checkboxIntertidal")} </Typography>
        </Box>
        
        <div>
          <Button
            id="buttonArea"
            onClick={displaySubAreaButton}
            color="primary"
            variant="contained"
            style={{
              cursor: "pointer",
              padding: "30",
              margin: 10
            }}
          >
            {t("forms.acquisition.2.intertidalArea")}
          </Button>
          
          <Button
            id="buttonPeriod"
            onClick={handleDisplayDefinePeriod}
            color="primary"
            variant="contained"
            style={{
              cursor: "pointer",
              padding: "10",
              margin: 10
            }}
          >
            {t("forms.acquisition.2.intertidalPeriod")}
          </Button>

          <Button
           id="buttonTideData"
            onClick={displaySubTideDataButton}
            color="primary"
            variant="contained"
            style={{
              cursor: "pointer",
              padding: "10",
              margin: 10
            }}
          >
            {t("forms.acquisition.2.intertidalTideData")}
          </Button>
        </div>
        <br />

        <dialog id="dialogSubArea">
          <h1 >{t("forms.acquisition.2.intertidalSelectAreaTitle")}</h1>
          <div id="subAreaButton" >
            <Button
                onClick={handleDisplayImportKML}
                color="primary"
                variant="outlined"
                style={{
                  cursor: "pointer",
                  padding: "10",
                  margin: 10
                }}
              >
                {t("forms.acquisition.2.intertidalTideImportKmz")}
              </Button>

              <Button
                onClick={displayDrawOnMapDetailsArea}
                color="primary"
                variant="outlined"
                style={{
                  cursor: "pointer",
                  padding: "10",
                  margin: 10
                }}
              >
                {t("forms.acquisition.2.intertidalDrawOnMap")}
              </Button>
              <br /><br />
              <Button
                color="secondary"
                variant="contained"
                onClick={ ()=>{document.getElementById("dialogSubArea").close();}}
              >
                {t("forms.map.cancel")}
              </Button>
              <br /><br />
          </div>

          <div style={{display: "none"}} id="drawOnMapDetails">
            <br />
            {t("forms.acquisition.2.intertidalDrawOnMapDetails")}
            <br />
          </div>
        </dialog>

<dialog id="subTideDataButton">
        <h1 >{t("forms.acquisition.2.intertidalSelectTideTitle")}</h1>
        <div  >
          <Button
              onClick={handleDisplayImportTideData}
              color="primary"
              variant="outlined"
              style={{
                cursor: "pointer",
                padding: "10",
                margin: 10
              }}
            >
              {t("forms.acquisition.2.intertidalTideImportData")}
            </Button>

            <Button
              onClick={()=>{}}
              color="primary"
              variant="outlined"
              disabled={true}
              style={{
                cursor: "pointer",
                padding: "10",
                margin: 10
              }}
            >
              {t("forms.acquisition.2.intertidalTideExtractData")}
            </Button>
            <br /><br />
            <Button
                color="secondary"
                variant="contained"
                onClick={ ()=>{document.getElementById("subTideDataButton").close();}}
              >
                {t("forms.map.cancel")}
              </Button>
            <br /><br />
        </div>
        </dialog>
        
        <GoogleMap
          style={{ width: "100%", height: 500 } /* @TODO pass as props */}
          onRegionDrawn={handleDrawing}
        />

        <StepperButtons
          navigate={navigate}
          nextDisabled={!continueEnable}
          onNext={  handleChoose }
        >
          <Button
            onClick={handleUndo}
            disabled={drawn === false}
            color="secondary"
            variant="outlined"
          >
            {t("forms.acquisition.2.undo")}
          </Button>
        </StepperButtons>
  
        {
          // if the user hasn't seen the tour, show it
        }
        <TourGuider
          steps={steps}
          isOpen={isTourOpen}
          setIsTourOpen={setIsTourOpen}
        />
      </Box>
    </React.Fragment>
  );
};

export default AOIChooser;
