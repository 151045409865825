const pt = {
  translation: {
    self: {
      title: "CASSIE",
      fullTitle: "Coastal Analyst System from Space Imagery Engine",
      shortDesc:
        "Plataforma web colaborativa com soluções de observações e previsões geoespaciais aplicadas à monitoramento, mitigação e adaptação da zona costeira face às mudanças do clima.",
      abstract:
        "O objetivo geral deste projeto é definir metodologia técnica e operacional e desenvolver uma plataforma web que disponibilize serviço de informação derivada de satélite e inteligência artificial (e.g. linha de costa, topografia, batimetria, compressão costeira, hidrodinâmica) para criação de acervo aplicados à mitigação e adaptação da zona costeira a mudanças do clima.",
      poweredBy: "Powered by: Google Earth Engine",
      imageryProvider:
        "Fontes das imagens dos satélites: NASA (Landsat) e ESA (Sentinel)",
      code: "CASSIE",
      name: "Coastal Analyst System from Space Imagery Engine",
      changeLanguage: "Idioma",
    },
    home: {
      about: {
        title: "Conheça o CASSIE CoRe",
      },
      instructions: {
        title: "Instruções para utilização do CASSIE",
        text:
          "Para utilizar o CASSIE é necessário possuir uma conta google cadastrada na plataforma google earth engine.",
        btnEngineSingUp: "Primeiro Acesso",
        btnManual: "Manual de Uso",
        linkManual: "https://docs.google.com/document/d/14vhAejtHfufRwTacR3d6N21xkv57urnOF57jkwkPuO4/edit?usp=sharing",
        btnVideo: "Video Demonstrativo",
        linkVideo: "https://www.youtube.com/@CASSIE-Core",
      },
      baysqueeze: {
        title: "BaySqueeze",
        text:
          'O projeto procura contribuir com ações e resultados para o bem e preservação do nosso planeta, tomando para si, a tarefa de cumprir os objetivos de desenvolvimento sustentável, estabelecidos pela ONU, bem como atender às necessidades nacionais de conhecimento sobre mudanças climáticas, paralelamente aos objetivos da sub-rede "Zonas Costeiras", da Rede Brasileira de Pesquisas sobre Mudanças Climáticas Globais(Rede Clima).',
        btn: "Mais sobre o projeto",
      },
      riscport: {
        title: "RiskPorts",
        text:
          "Este projeto tem como objetivo principal realizar uma análise dos riscos climáticos (ambientais e econômicos) e determinar as possíveis medidas de adaptação, como base na projeção futura das ameaças ambientais (ex: ondas de tempestades), para incrementar a resiliência do setor portuário e áreas adjacentes na Baía da Babitonga, em um contexto de uma rede interdisciplinar e multi-institucional.",
        btn: "Mais sobre o projeto",
      },
      members: {
        title: "Equipe",
        roles: {
          researcher: "Pesquisador",
          coord: "Coordenador",
          coord_geral: "Coordenador Geral",
          atp: "Bolsista ATP-B",
          bolsista: "Bolsista - Desenvolvimento",
          validacao: "Validação",
          desenvolvimento: "Desenvolvimento",
          fumdes: "Bolsista FUMDES",
          capes: "Bolsista CAPES DTI-A"
        },
      },
      papers: {
        title: "Produção científica",
        resumo_text: "Resumo em Evento",
        paper_text: "Artigo em Revista",
        curso_text: "Curso",
        more: "Saiba Mais",
      },
      sponsor: {
        realiza: "Realização",
        apoio: "Apoio Financeiro",
        apoio_desc: "Atual Processo No. 406603/2022-7. Anteriores: Processo No. 441818/2020-0, CNPq MCTIC/CNPq - N° 21/2017; Processo No. 441545/2017-3.",
        inst: "Apoio Institucional",
        partners: "Parceiros",
      },
      footer: {
        social: "Social",
        copyright: "Todos os direitos reservados. Desenvolvido por",
        help: {
          title: "Ajuda e Suporte",
          faq: "Perguntas Frequentes",
          discussion: "Fórum de Discussões",
          problems: "Problemas Conhecidos",
        },
        contact: {
          title: "Contato",
          manage: "Coordenação do projeto",
          techquest: "Dúvidas Técnicas",
        },
        resources: {
          title: "Recursos",
          code: "Código Fonte",
          license: "Licença",
        },
      },
      warning: {
        text: "O CASSIE ainda está em desenvolvimento.",
        link: "Saiba mais",
      },
      cookies: {
        text: "Nosso site usa cookies para melhorar a navegação.",
        link: "Aceitar",
      }
    },
    faq: {
      title: "Perguntas Frequentes",
      0: {
        title: "Como o CASSIE Funciona?",
        text:
          'O CASSIE utiliza do Google Earth Engine (GEE), uma plataforma de processamento paralelo em nuvem de dados geospaciais (<a href="https://www.sciencedirect.com/science/article/pii/S0034425717302900" target="_blank">Gorelick et al., 2017</a>). O CASSIE se comunica com o GEE por meio de uma API (Application Programming Interface - Interface de Programação de Aplicativos), que o permite utilizar dos datasets e do potencial de processamento do GEE.',
      },
      1: {
        title: "Quais datasets de imagem satélite estão disponíveis no CASSIE?",
        text:
          'Atualmente, o CASSIE possibilita análises com dados do <a href="https://developers.google.com/earth-engine/datasets/catalog/COPERNICUS_S2" target="_blank">Sentinel 2 (MSI, TOA) Level-1C</a> e com a coleção dos Landsats (<a href="https://developers.google.com/earth-engine/datasets/catalog/LANDSAT_LT05_C01_T1_SR" target="_blank">Landsat 5 TM SR Tier 1</a>,<a href="https://developers.google.com/earth-engine/datasets/catalog/LANDSAT_LE07_C01_T1_SR" target="_blank">Landsat 7 ETM+ SR Tier 1</a>, <a href="https://developers.google.com/earth-engine/datasets/catalog/LANDSAT_LC08_C01_T1_SR" target="_blank">Landsat 8 OLI SR Tier 1</a>). Quando não há imagens disponíveis de Tier 1 (maior qualidade) para o Landsat 7 e 8, o CASSIE utiliza de imagens das coleções <a href="https://developers.google.com/earth-engine/datasets/catalog/LANDSAT_LE07_C01_T2_TOA" target="_blank">Landsat 7 TOA Tier 2</a> e <a href="https://developers.google.com/earth-engine/datasets/catalog/LANDSAT_LC08_C01_T2_TOA" target="_blank">Landsat 8 TOA Tier 2</a>.',
      },
      2: {
        title: "Como acesso o CASSIE?",
        text:
          'O CASSIE está disponível como uma ferramenta open source (código aberto), disponível <a href="http://cassie-stable.herokuapp.com/">neste link</a>. O usuário precisa de uma <a href="https://myaccount.google.com">conta Google</a> registrada (e aprovada) na <a href="https://signup.earthengine.google.com">plataforma Google Earth Engine</a>. O código do CASSIE está disponível no <a href="https://github.com/lia-univali/cassie">Github do LIA - Univali</a>.',
      },
      3: {
        title:
          "Ao entrar com a conta da Google, a página fica carregando indefinidamente",
        text:
          'Para acessar e utilizar o CASSIE, a conta do usuário deve estar registrada na plataforma Google Earth Engine (GEE). O cadastro pode ser feito por meio deste <a href="https://earthengine.google.com/signup/" target="_blank">link</a>. Assim que o cadastro for confirmado, o utilizador pode acessar e entrar no CASSIE com a sua conta Google.',
      },
      4: {
        title:
          "Minha conta é registrada no GEE mas não consigo acessar a ferramenta",
        text:
          'O registro na plataforma Google Earth Engine é finalizado com uma confirmação recebida por e-mail. Em alguns casos, o e-mail de confirmação é recebido instantaneamente após o cadastro ser feito. Entretanto, nem sempre a confirmação é instantanea e pode levar um tempo que a equipe do CASSIE não tem controle sobre. Se o utilizador realizou o cadastro e ainda não recebeu o e-mail de confirmação e considera que o tempo de aguardo não é normal, deve entrar em contato com a <a href="https://developers.google.com/earth-engine/help">equipe do Google Earth Engine</a>. Assim que o cadastro for confirmado com o recebimento do e-mail, o utilizador já pode utilizar o CASSIE. Caso o usuário não consiga entrar mesmo após a confirmação, recomenda-se a <a href="https://support.google.com/accounts/answer/32050?co=GENIE.Platform%3DDesktop&hl=pt-BR" target="_blank">limpeza dos cookies do navegador.</a>',
      },
    },
    problems: {
      title: "Problemas Conhecidos",
      text:
        "O CASSIE ainda está em desenvolvimento, alguns processos podem falhar. Esses são alguns problemas conhecidos.",
      0: {
        title: "Análise de muitos dados",
        text:
          "O CASSIE por vezes não é capaz de realizar grandes análises, com região muito grande ou com muitas imagens.",
      },
    },
    general: {
      loading: "Carregando...",
      cancel: "Cancelar",
    },
    auth: {
      signin: "Acessar Ferramenta com o Google",
      signout: "Sair",
      loading: "Autenticando...",
    },
    tour: {
      done: "Concluido",
      of: "de",
      help: "Ajuda",
      acquisition: {
        1: {
          info: "Nesta Etapa você deve escolher o Satélite",
          'Sentinel-2': {
            info: "O Sentinel possui maior resolução espacial e menos tempo de aquisição de dados (de 2013-2021)"
          },
          Landsat: {
            info: "O Landsat possui menor resolução espacial e mais tempo de aquisição de dados(de 1984 a 2021)"
          }
        },
        2: {
          info: "Selecione a área de interesse para a analise"
        },
        3: {
          period: "Selecione um intervalo de datas para serem analizadas",
          point: "Cada Ponto rosa é uma imagem",
          cloud: "Selecione um percentual aceitável de núvens na imagem"
        },
        4: {
          id: "O ID contém informações importantes como a data e hora em que a imagem foi capturada",
          clouds: "Nesta coluna temos percentual calculado de Nuvens na imagem",
          image: "Nesta coluna é apresentada a imagem em questão",
          action: "Nesta última coluna, você pode escolher se deseja ou não incluir esta imagem na análise"
        }
      },
      csqueeze: {
        2: {
          info: "",
          aoi: "Selecione a área de interesse para a analise",
          mangrove: "Neste botão é possível visualizar possíveis áreas de mangue pelo mundo usando a coleção \"ESA/WorldCover/v200\"",
          importKml: "Também é possível importar dados de um arquivo KML para desenhar a área de interesse no mapa."
        },
        3: {
          info: "",
          years: "Defina dois diferentes anos para gerar o estudo de previsão"
        },
        4: {
          info: "",
          years: "Para cada ano deve-se escolher imagens com o menor número de nuvens possível",
          timeselector: "Os pontos rosas representam imagens disponíveis no ano com o filtro de nuvem selecionado",
          viewImageButton: "Ao selecionar o ponto rosa com a imagem disponível é necessário clicar aqui para visualizar. Caso não aconteça nada é possível que a imagem está 100% coberta de nuvens, isso ocorre pois o nível de nuvens é definido pela coleção de satélite e nem todas as regiões são precisas.",
          cloudselector: "Nesta seleção é possível definir a porcentagem de nuvens nas imagens. Ao mudar esse parâmetro a barra com os pontos rosas será atualizada deixando disponível apenas imagens que se encaixem no valor",
          tableImages: "As imagens selecionadas serão mostradas nesta tabela. É necessário selecionar no mínimo 1 de cada ano para ir para a próxima etapa."
        },
        5: {
          info: "",
          selectType: "Selecione um dos tipos para ativar o modo de desenho. Ao escolher a cobertura de solo, clique no mapa para iniciar o desenho de um retângulo na região que representa esse tipo. \n Para apagar o retângulo, segure CTRL e clique nele ou ative o \"Remover polígono\" e clique sobre o polígono. (É necessário desenhar pelo menos 1 polígono de manguezal e outro distinto para continuar).",
          falseColorButton: "Caso a imagem não tenha uma boa nitidez, é possível ativar a máscara de cor falsa que usa a banda de infravermelho ao invés da banda vermelha no mapa.",
          availableDataset: "Datasets são coleções prontas mapeadas por usuarios ou organizações com dados prontos. Se o ano desejado possuir um dataset disponível, será avisado no canto da tela a possibilidade de aplicação dele.",
          importPolygons: "Também é possível importar retângulos com um arquivo CSV que é gerado pelo botão EXPORTAR logo abaixo.",
          generateRaster: "Exportar e limpar todos os retângulos são algumas das opções disponíveis. Caso já tenha desenhado ao menos 1 retângulo de manguezal e outro distinto, o botão \"GERAR RASTER\" aparecerá.",
          yearsButtons: "Não esqueça de gerar os raster dos dois anos para poder continuar."
        }
      },
      map: {
        start: "Esta é a tela de processamento",
        imageChooser: {
          info: "Neste Campo você pode carregar no mapa uma das imagens selecionadas",
          select: "Por padrão, a imagem selecionada é a mais recente, mas você pode escolher outra clicando aqui",
          click: "Após selecionar a Imagem, você pode carregá-la na tela apertando neste botão"
        }
      }
    },
    forms: {
      intertidal:{
        title: "Intermarés",
        next: "Continar",
        1:{
          title:"Escolha do satélite",
          description:"Selecione um dos satélites disponíveis para aquisição de imagens."
        },
        2:{
          title:"Dados de entrada",
          description:"Defina a região de interesse e o arquivo de dados de maré para continuar"
        },
        3:{
          title:"Coleção de imagens",
          description:"Escolha o período de interesse e filtre as imagens par aa coleção"
        },
        4:{
          title:"Área de intermarés",
          description:"Refina a área de intermarés ou aceite a automática",
          returnAndRedo: "Refazer"
        },
        5:{
          title:"Identificação waterline e atribuição de nível",
          description: "Identifique a waterline e atribua o nível",
        },
      },
      algorithm: {
        title: "Seleção de Módulo",
        bathymetry: "Batimetria",
        bathymetryDesc: "Estimativa de profundidade de águas pouco profundas numa região costeira selecionada, usando algoritmos de inversão ótica e machine learning.",
        shoreline: "Análise de Linha de Costa",
        shorelineDesc: "Mapeamento automático e análise estatística da evolução da linha de costa em qualquer área de interesse costeira no mundo, usando este módulo.",
        csqueeze: "Compressão Costeira",
        csqueezeDesc: "Mapeamento e análise de indicadores para obtenção do potencial de Compressão Costeira (Coastal Squeeze) em áreas de manguezais.",
        intermare: "Intermaré (em desenvolvimento)",
        intermareDesc: "Estimativa de profundidade na região entre marés, utilizando o método de linhas de água.",
       
      },
      acquisition: {
        title: "Aquisição de Imagens",
        prev: "Voltar",
        next: "Continuar",
        1: {
          title: "Escolha o satélite",
          description:
            "Selecione um dos satélites disponíveis para a aquisição de imagens.",
          card: {
            opticalResolution: "Resolução ótica",
            opticalResolutionUnit: "metros",
            activityPeriod: "Período de atividade",
            provider: "Fornecedor",
            revisitTime: "Ciclo de captura",
            revisitTimeUnit: "dias",
            choose: "Escolher",
          },
        },
        2: {
          title: "Defina a área de interesse",
          description: "Delimite a área de interesse utilizando o mapa abaixo.",
          showDelimiters: " delimitadores",
          undo: "Desfazer",
          processFile: "Processar arquivo",
          importLegend: "Importe a área de interesse por meio de um arquivo KML:",
          importTitle: "Importar KML",
          example: "Exemplos podem ser baixados aqui",
          dataTrainning: "Dados de treino",
          dataTrainningLegend: "Importe o arquivo de treino",
          dataTrainningFileLabel: "Informe a data do arquivo de treino:",
          dataTrainningDateHint: "*Utilize um arquivo com nome encerrando com _ddmmyyyy.csv para ter a data de treino preenchida automatiamente.",
          bathymetryDescription: "Defina a área de interesse e o arquivo de treino para continuar.",
          labelBathymetryStep: "Defina a área de interesse e dados de treino",
          helpCoordinateSystem: "Por padrão o CASSIE interpreta as coordenadas com o sistema EPSG:4326 (WGS84 - Coordenadas Geográficas).\nMas caso o seu arquivo de treino esteja baseado em outro sistema de coordenadas EPSG,\nvocê pode digitar o código do seu sistema, que o CASSIE irá fazer a devida interpretação dos dados.",
          checkboxIntertidal: "Possíveis áreas de intermaré",
          intertidalArea: "Área",
          intertidalPeriod: "Período",
          intertidalTideData: "Dados de maré",
          intertidalTideImportKmz: "Importar kmz",
          intertidalDrawOnMap: "Desenhar no mapa",
          intertidalDrawOnMapDetails: "Utilize os botões de desenho do mapa para definir a área de interesse.",
          intertidalTideImportData: "Importar dado",
          intertidalTideExtractData: "Extrair dado do modelo",
          intertidalPeriodSelection: "Especifique a data do início e término do conjunto de imagens",
          intertidalPeriodStartDate: "Selecione a data de início do período de análise",
          intertidalPeriodEndDate: "Selecione a data de fim do período de análise",
          intertidalCloudFilter: "Defina a cobertura máxima de núvens",
          intertidalConfirmPeriod: "Confirmar período",
          intertidalSelectAreaTitle: "Escolha uma da opções da definir a área",
          intertidalSelectTideTitle: "Escolha uma da opções da definir o dado de maré",
        },
        3: {
          title: "Defina o período",
          description:
            "Especifique a data de início e data de término do conjunto de imagens.",
          loading: "Carregando...",
          period: "Período",
          to: "a",
          durationDays: {
            singular: "dia",
            plural: "dias",
          },
          durationMonths: {
            singular: "mês",
            plural: "meses",
          },
          durationYears: {
            singular: "ano",
            plural: "anos",
          },
          imageQuantity: {
            singular: "imagem",
            plural: "imagens",
          },
          cloudPercentage: "Nível de nuvens",
          labelBathymetryStep: "Selecione a imagem",
          contentBathymetryStep: "Favor selecione a imagem para continuar",
          bathymetry: {
            description: "Especifique uma imagem para ser utilizada:",
            imageDate: "Data da imagem",
            dataDate: "Data dos dados in-situ",
            downloadImage: "Download imagem",
            intervalSelection: "Clique aqui para selecionar o intervalo para imagem de mediana.",
            medianImagem: "Imagem mediana",
            intervalDefinition: "Definição do intervalo",
          }
        },
        4: {
          title: "Filtre as imagens",
          description:
            "Aplique filtros para manter somente as imagens apropriadas.",
          table: {
            id: "ID",
            cloud: "Nuvens",
            thumbnail: "Miniatura",
            selected: "Selecionada",
          },
          imagesPerPage: "Imagens por página",
          to: "a",
          of: "de",
          labelBathymetryStep: "Selecione as bandas, o algoritmo e a linha de corte",
          contentBathymetryStep: "Selecione as bandas, o algoritmo e linha de corte a ser aplicada.",
          next: "Concluir",
          bathymetry: {
            algorithmSelection: "Selecione um algoritmo",
            algorithmSelectionHint: "Clique no gráfico para selecionar o algoritmos. O algoritimo selecionado ficará com bordas vermelhas.",
            thresholdSelection: "Linha de corte para extinção da luz (metros)",
            algorithmName1: "Razão de bandas",
            bandsSelection: "Seleção de bandas", 
            band: "Banda",
            updateCharts: "Update charts",
          }
        },
        5: {
          bathymetry: {
            bathymetry: "Batimetria",
            loadStatistics: "Carregar estatísticas",
            returnToBegin: "Retornar para seleção de módulos",
            meters: "metros",
            minDepth: "Profundidade mínima estimada",
            maxDepth: "Profundidade máxima estimada",
            processing: "Processando...<br />O tempo necessário para concluir esta operação depende do tamanho do arquivo de treino.",
            downloadStatistics: "Download estatísticas",
          }
        }
      },
      map: {
        roi: "Área de interesse",
        baseline: "Linha de Base",
        shorelines: "Linhas de Costa",
        transects: {
          title: "Transectos",
          stable: "Estável",
          accreted: "Acrescida",
          eroded: "Erodida",
          criticallyEroded: "Criticamente Erodida",
        },
        item: {
          s: "item",
          p: "itens",
        },
        cancel: "Cancelar",
      },
      imageChooser: {
        title: "Imagens disponíveis",
        resultQuantity: "resultados",
        image: "Imagem",
        load: "Carregar",
        actions: {
          title: "Ações",
          analyzeShoreline: {
            title: "Analisar linhas de costa",
            imageSelection: {
              title: "Seleção de imagens",
              cancel: "Cancelar",
              confirm: "Confirmar",
            },
            baselineDraw: "Desenhe a linha de base",
          },
        },
      },
      shorelineParameters: {
        title: "Parâmetros de análise",
        description:
          "Defina os parâmetros de espaçamento e extensão dos transectos, em metros, e o coeficiente de limiarização (valores mais altos são mais rígidos quanto ao que é considerado um corpo de água).",
        spacing: "Espaçamento (m)",
        extension: "Extensão (m)",
        threshold: "Limiar",
        cancel: "Cancelar",
        confirm: "Confirmar",
      },
      shorelineAnalysis: {
        title: "Análise da Linha de Costa",
        transectsReport: {
          title: "Relatório de Transectos",
          headers: {
            id: "ID",
            initialLatitude: "Latitude inicial",
            initialLongitude: "Longitude inicial",
            finalLatitude: "Latitude final",
            finalLongitude: "Longitude final",
            initialDate: "Data inicial",
            finalDate: "Data final",
            intercept: "Intercepto",
            slope: "Inclinação",
            rsquared: "r²",
            lrr: "LRR",
            sce: "SCE",
            nsm: "NSM",
            epr: "EPR",
            label: "Classe",
          },
        },
        process: {
          extraction: "Extraindo linhas de costa",
          estimate: "Estimado",
        },
        exports: {
          title: "Exportar Dados de Transectos",
          csv: "Exportar CSV",
          json: "Exportar JSON",
          shp: "Exportar Shapefile",
        },
        close: "Fechar",
      },
      transectEvolution: {
        statistics: "Estatísticas",
        lrr: "Taxa de alteração (LRR)",
        r: "Coeficiente de correlação (r)",
        sce: "SCE",
        nsm: "NSM",
        epr: "EPR",
        label: "Classificação",
        units: {
          meters: "m",
          mByYr: "m/ano",
          mByMonth: "m/ano",
        },
        labels: {
          x: "Ano",
          y: "Distância da Linha de Base (m)",
          trend: "Tendência",
        },
      },
      imageryOverlay: {
        base: "Base",
        hint: "Nova camada",
        loading: "Carregando",
        plotBathymetry: "Clique aqui para plotar a imagem de batimetria.",
        plotMedian: "Clique aqui para plotar a imagem de mediana.",
        bathymetryLoad: "Clique em 'Criar' para exibir a camada com a imagem da batimetria.",
        medianLoad: "Clique em 'Criar' para exibir a camada com a imagem da mediana.",
        add: {
          title: "Nova camada",
          name: "Nome da camada",
          expression: "Expressão",
          bands: {
            title: "Bandas disponíveis",
            red: "RED: Banda vermelha",
            green: "GREEN: Banda verde",
            blue: "BLUE: Banda azul",
            nir: "NIR: Banda infravermelho próximo",
            swir: "SWIR: Banda infravermelho de ondas curtas",
          },
          suggested: "Expressões",
          create: "Criar",
        },
      },
      csqueeze: {
        title: "Compressão Costeira",
        prev: "Voltar",
        next: "Continuar",
        year: "Ano",
        years: "anos",
        landcovers: {
          mangrove: "Manguezal",
          vegetation: "Vegetação",
          water: "Corpo d'agua",
          human: "Intervenção humana",
          intertidal: "Intermaré",
        },
        1: {
          title: "Escolha o satélite",
          description:
            "Selecione um dos satélites disponíveis para a aquisição de imagens.",
          card: {
            opticalResolution: "Resolução ótica",
            opticalResolutionUnit: "metros",
            activityPeriod: "Período de atividade",
            provider: "Fornecedor",
            revisitTime: "Ciclo de captura",
            revisitTimeUnit: "dias",
            choose: "Escolher",
          },
        },
        2: {
          title: "Defina a área de interesse",
          description: "Delimite a área de interesse utilizando o mapa abaixo.",
          showDelimiters: " delimitadores",
          undo: "Desfazer",
          processFile: "Processar arquivo",
          importLegend: "Importe a área de interesse por meio de um arquivo KML:",
          importTitle: "Importar KML",
          example: "Exemplos podem ser baixados aqui",
          dataTrainning: "Dados de treino",
          dataTrainningLegend: "Importe o arquivo de treino",
          dataTrainningFileLabel: "Informe a data do arquivo de treino:",
          dataTrainningDateHint: "*Utilize um arquivo com nome encerrando com _ddmmyyyy.csv para ter a data de treino preenchida automatiamente.",
          bathymetryDescription: "Defina a área de interesse e o arquivo de treino para continuar.",
          labelBathymetryStep: "Defina a área de interesse e dados de treino",
          helpCoordinateSystem: "Por padrão o CASSIE interpreta as coordenadas com o sistema EPSG:4326 (WGS84 - Coordenadas Geográficas).\nMas caso o seu arquivo de treino esteja baseado em outro sistema de coordenadas EPSG,\nvocê pode digitar o código do seu sistema, que o CASSIE irá fazer a devida interpretação dos dados.",
          performanceMessage: "A área marcada possui {number} km² e terá um desempenho {level} durante os cálculos.",
          slow: "lento",
          moderate: "moderado",
          fast: "rápido",
          viewMangrove: "Possíveis áreas de manguezal",
          geeError: {
            title: 'Aviso de Erro!',
            content: 'Parece que a sua conta ainda não está autorizada a usar as ferramentas do Google Earth Engine. Você precisa completar seu registro através deste link: ',
            close: 'Fechar',
          },
          errorTitle: "Mensagem de erro",
          errorMessageSelectFile: "Por favor, selecione um arquivo primeiro.",
          errorMessageDrawingOutline: "Por favor, selecione um arquivo primeiro.",
          errorMessageNoValidCoord: "Nenhuma coordenada válida foi encontrada no arquivo KML.",
          errorMessageUnableInterpretKml: "Não foi possível interpretar o arquivo KML.",
          infoTitle: "Mensagem informativa",
          infoMessageSuccess: "Arquivo KML processado com sucesso.",
          close: "Fechar"
        },
        3: {
          title: "Defina os periodos",
          description:
            "Selecione dois anos para obter as imagens",
          loading: "Carregando...",
          period: "Período",
          to: "a",
          durationDays: {
            singular: "dia",
            plural: "dias",
          },
          durationMonths: {
            singular: "mês",
            plural: "meses",
          },
          durationYears: {
            singular: "ano",
            plural: "anos",
          },
          imageQuantity: {
            singular: "imagem",
            plural: "imagens",
          },
          cloudPercentage: "Nível de nuvens",
        },
        4: {
          title: "Selecione as imagens",
          description:
            "Visualize as imagens e adicione-as a lista. \n (Imagens com nuvens terão aplicação de filtro de remoção)",
          loading: "Carregando...",
          period: "Período",
          to: "a",
          with: "com",
          tabText: "Selecione as datas disponíveis na barra clicando nos pontos rosas abaixo:",
          emptyImages: "Nenhuma imagem encontrada com essa porcentagem de nuvens.",
          viewImage: "Visualizar imagem",
          listText: "*Selecione ao menos 1 imagem deste ano para o processamento.",
          addImage: "ADICIONAR IMAGEM",
          discard: "DESCARTAR",
          alreadyAdded: "JÁ ADICIONADO",
          table: {
            id: "ID",
            cloud: "Nuvens",
            thumbnail: "Miniatura",
            remove: "Remove",
          },
          durationDays: {
            singular: "dia",
            plural: "dias",
          },
          durationMonths: {
            singular: "mês",
            plural: "meses",
          },
          durationYears: {
            singular: "ano",
            plural: "anos",
          },
          imageQuantity: {
            singular: "imagem",
            plural: "imagens",
          },
          selected: "selecionadas",
          cloudPercentage: "de nível de nuvens",
          undo: "Limpar pontos",
          nextWarning: "Selecione uma 1 imagem de cada ano para continuar"
        },
        5: {
          title: "Classifique a cobertura do solo",
          description: "Desenhe as regiões dos tipos de cobertura do solo no mapa ou, se disponível, selecione uma fonte de dados com as informações de cobertura do solo.",
          cardText: "Considerando que o manguezal cresce em áreas que podem estar até 10 metros acima do nível do mar, o cálculo de compressão utiliza filtros de elevação do solo, com este valor sendo adotado como referência padrão.",
          confirm: "CONFIRMAR",
          undo: "Limpar",
          example: "Exemplo",
          elevation: "Elevação",
          withElevation: "Elevação de 10 metros",
          noElevation: "Sem filtro de elevação",
          defineElevation: "Para definir um valor de elevação diferente do padrão altere o campo abaixo",
          labels: "Legenda",
          labelsInfo: "*Numeros correspondem o codigo do tipo de solo.",
          availableDataset: "DATASET DISPONÍVEL",
          availableDatasetText: "Este ano possui uma coleção de dados de solo mapeada pelo",
          apply: "Aplicar",
          defineLandCovers: "SELECIONE O TIPO PARA DESENHAR NO MAPA",
          removePolygon: "Remover retângulo",
          export: "Exportar",
          import: "Importar",
          importPolygons: "IMPORTAR COORDENADAS DOS POLÍGONOS",
          formatImport: "FORMATO CSV: codigo tipo de solo; lat sul; lng oeste; lat norte; lng leste",
          spanImport: "Após processar o arquivo, os poligonos aparecem automaticamente no mapa",
          toContinue: "*Defina pelo menos um poligono de manguezal e outro poligono diferente para gerar a malha de classificação.",
          generate: "GERAR RASTER",
          confirmRaster: "CONFIRME O RASTER GERADO",
          confirmRasterText: "Verifique o raster gerado no mapa. Se estiver satisfeito, e os raster de ambos os anos foram concluídos, o botão \"CONTINUAR\" será liberado.",
          editPolygons: "EDITAR POLIGONOS",
          nextStepAlert: "*Na próxima etapa não será mais possível voltar para alterar datas, imagens ou o raster.",
          falseColor: "Cor falsa",
          shortcut: "ATALHO: É possível mover o mapa mantendo o botão CTRL pressionado.",
          nextWarning: "Gere o raster dos dois anos para continuar",
          mapBiomasAlertTitle: "Confirmar Aplicação do Dataset MapBiomas",
          mapBiomasAlertText: "Ao confirmar esta ação, o dataset do MapBiomas será aplicado no mapa e o raster será gerado. Todos os polígonos já desenhados serão descartados. Você pode apagar a classificação do MapBiomas e desenhar novos polígonos a qualquer momento clicando no botão \"Editar Poligonos\".",
          alertDialogConfirm: "Confirmar",
          alertDialogCancel: "Cancelar",
          alertDialogClose: "Fechar",
          polygonsFileProcessed: "Arquivo de polígonos processado e importado",
          drawingMode: "Modo de Desenho",
          drawingModeText: "Clique no mapa para desenhar retângulos do tipo ",
          drawingModeRemove: "Modo de Remoção",
          drawingModeTextRemove: "Clique no retângulo para removê-lo"
        },
        result: {
          steps: "Etapas",
          polygons: "Polígonos",
          baseLine: "Linha base",
          drawBaseline: "Desenhar linha de base",
          transect: "Transecto",
          transects: "Transectos",
          visibilityController: "Controlador de visibilidade",
          visibilityControllerDesc: "*As camadas do ano mais recente sobrepõem as camadas do ano mais antigo.",
          configTransects: "Configurar transectos",
          calcule: "Iniciar análise",
          tipBaseline: "Para terminar o desenho você deve clicar duas vezes no mapa no ponto final da linha.",
          confirmBaseline: "Deseja confirmar a linha ou redesenhar?",
          confirm: "Confirmar",
          cancel: "Cancelar",
          redo: "Refazer",
          defineTransects: "Defina a quantidade de transectos que serão desenhados pela linha base:",
          quantityTransects: "Quantidade (aproximadamente)",
          lengthTransects: "Tamanho (para cada lado em metros)",
          chartsTitle: "Gráfico de evolução",
          loading: {
            wait: "*O processo pode demorar alguns minutos.",
            preparing: "Preparando dados..."
          },
          in: "em",
          output: {
            noMangrove: "Não há manguezal suficiente para o calulo deste transecto.",
            noHuman: "Não há intervenção humana suficiente para o cálculo deste transecto.",
            thisTransect: "neste transecto.",
            noSqueeze: "Nenhum squeeze previsto",
            squeeze: "Potencial de {number}% de squeeze",
            growth: "Potencial de {number}% de crescimento",
            fullSqueeze: "O potencial de squeeze é superior a 100%",
            organizeYear: "Organizando dados de {year}...",
            calculatingTransect: "Calculando transecto",
            calculatingComplete: "{year} finalizado...",
            ready: "Análise completa",
          },
          forecast: "Previsão",
          colorLegend: "Legenda de cores",
          legend: {
            fullSqueeze: "Squeeze avançado",
            moderateSqueeze: "Squeeze moderado",
            noSqueeze: "Manguezal estável",
            noMangrove: "Manguezal insuficiente"

          },
          restart: "Recomeçar",
          export: "Exportar arquivo CSV",
          shp: "Exportar Shapefile",
          labels: {
            id: 'ID',
            0: 'MGV',
            1: 'VEG',
            2: 'HUM',
            3: 'WTR'
          }
        }
      }
    },


    intertidal: {
      title: "Intermarés",
      prev: "Voltar",
      next: "Continuar",
      year: "Ano",
      years: "anos",
      1: {
        title: "Seleção do satélite",
        description:
          "Selecione um dos satélites disponíveis para a aquisição de imagens.",
        card: {
          opticalResolution: "Resolução ótica",
          opticalResolutionUnit: "metros",
          activityPeriod: "Período de atividade",
          provider: "Fornecedor",
          revisitTime: "Ciclo de captura",
          revisitTimeUnit: "dias",
          choose: "Escolher",
        },
      },
      2: {
        title: "Seleção de áreas e dados de maré",
        description: "Seleção da área de interesse e dados de maré.",
        showDelimiters: " delimitadores"
      },
      3: {
        title: "Aquisição das imagens",
        description: "Selecione as imagens para compor a coleção. Ideal que se encontre diferentes níveis de maré.",
        loading: "Carregando..."
      },
      4: {
        title: "Aceite da área encontrada",
        description: "Analise a área identificada como zona intermarés.",
        loading: "Carregando...",
        period: "Período",
        returnAndRedo: "Retornar e refazer",
      },
      5: {
        title: "Refinamento da área...",
        description: "Refinamento da área....",
        cardText: "Considerando que o manguezal cresce em áreas que podem estar até 10 metros acima do nível do mar, o cálculo de compressão utiliza filtros de elevação do solo, com este valor sendo adotado como referência padrão.",
        confirm: "CONFIRMAR",
      },
      result: {
        steps: "Etapas",
        chartsTitle: "Gráfico de evolução"    
      }
    }


  },
};

export default pt;
