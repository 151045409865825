import React from "react";
import Zoom from "react-medium-image-zoom";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { formatDate } from "../../../common/utils";

import {
  Button,
  Card,
  CardContent,
  Typography,
  Box,
  CardActions,
  CircularProgress,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    padding: theme.spacing(2),
  },
  content: {
    flex: "1 0 auto",
    padding: theme.spacing(2),
  },
  thumbnail: {
    borderRadius: 5,
    maxHeight: 350,
    maxWidth: "100%",
    objectFit: "contain",
  },
  buttonsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    marginTop: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
    },
  },
}));

// component to display a table of satellite images
const ImageCSqueeze = ({
  images,
  metadata,
  isIncluded = false,
  limit = false,
  onAddImage = () => {},
  onUndo = () => {},
}) => {
  // get the current language
  const [t] = useTranslation();
  const image = images[0];
  const classes = useStyles();

  // a function that gets the metadata for a given image
  const getMetadata = (image, key) => {
    const m = metadata.find(
      (value) => value.date === image.date && value.missionName === image.name
    );
    return m ? m[key] : undefined;
  };

  let url = getMetadata(image, "thumbnail");
  image.thumbnail = url;

  const handleImageAdd = () => onAddImage(image);
  const handleUndo = () => onUndo(image);

  return (
    <Card variant="outlined">
      <CardActions className={classes.root}>
        <Box className={classes.imageContainer}>
          {url && (
            <Zoom
              zoomMargin={50}
              overlayBgColorStart={"rgba(255, 255, 255, 0)"}
              overlayBgColorEnd={"rgba(255, 255, 255, 0.5)"}
            >
              <img
                src={url}
                alt="Satellite Thumbnail"
                className={classes.thumbnail}
              />
            </Zoom>
          )}
          {!url && <CircularProgress />}
        </Box>

        <CardContent className={classes.content}>
          <Typography>
            {image.shortname} - {formatDate(image.date, true)}
          </Typography>
          <Typography variant="subtitle2" color="textSecondary">
            {(image.content * 100).toFixed(1)}% {t("forms.csqueeze.4.cloudPercentage")}
          </Typography>

          <Box className={classes.buttonsContainer}>
            {!isIncluded ? (
              <Button
                onClick={handleImageAdd}
                variant="contained"
                color="primary"
                disabled={limit ? limit : !url}
              >
                {t("forms.csqueeze.4.addImage")}
              </Button>
            ) : (
              <Button variant="contained" color="primary" disabled={true}>
                {t("forms.csqueeze.4.alreadyAdded")}
              </Button>
            )}
            <Button onClick={handleUndo} color="secondary" variant="outlined">
              {t("forms.csqueeze.4.discard")}
            </Button>
          </Box>
        </CardContent>
      </CardActions>
    </Card>
  );
};

export default ImageCSqueeze;
