import {ee} from "../../services/earth-engine";
import * as turf from "@turf/turf";
import {maskLandsatCloudsRatio} from "../satellite/landsat";
import {maskS2ImageMask} from "../satellite/sentinel";

const options = { units: 'meters' };

const landcovers = [
  "Mangue",
  "Vegetação",
  "Corpo d'água",
  "Intervenção humana"
];

function landParsingTurf(landcover, sidesCoords, centerCoord, shoreline) {
  var isWater = landcover.id === 2;
  var center = turf.point(centerCoord);
  var sideOneTurf = turf.lineString(sidesCoords[0])
  var sideOne = turf.buffer(sideOneTurf, 1, options);
  var sideTwoTurf = turf.lineString(sidesCoords[1])
  var sideTwo = turf.buffer(sideTwoTurf, 1, options);
  var landcoverTurf = turf.feature(landcover.geometry)

  var intersectionSideOne = turf.intersect(sideOne, landcoverTurf);
  var intersectionSideTwo = turf.intersect(sideTwo, landcoverTurf);

  var intersectionData = {
    intersectionSideOneLength: intersectionSideOne ? turf.length(intersectionSideOne, options) : 0,
    intersectionSideTwoLength: intersectionSideTwo ? turf.length(intersectionSideTwo, options) : 0,
    intersectionSideOneCoordinatesFlatten: intersectionSideOne ? turf.getCoords(intersectionSideOne).flat(Infinity) : [],
    intersectionSideTwoCoordinatesFlatten: intersectionSideTwo ? turf.getCoords(intersectionSideTwo).flat(Infinity) : []
  };

  var lengthSide = [0, 0]
  var distancesFromBaseLine = [0, 0];
  var distancesFromShoreLine = [0, 0];
  var landCoverIntersectPoint = [null, null];
  var landCoverBiggestSide = -1;
  var waterSide = -1;
  var waterIntersectPoint = [0, 0];

  var oneCoordsFlatten = intersectionData.intersectionSideOneCoordinatesFlatten.reverse()
  var twoCoordsFlatten = intersectionData.intersectionSideTwoCoordinatesFlatten.reverse()

  function calcularDistancias(index, coordsFlatten, intersectionLength) {
    lengthSide[index] = intersectionLength;
    landCoverIntersectPoint[index] = [coordsFlatten[1], coordsFlatten[0]];

    if (landCoverIntersectPoint[index] !== null) {
      distancesFromBaseLine[index] = turf.distance(
          turf.point(landCoverIntersectPoint[index]),
          center,
          options
      );

      const pontoShoreLineProximo = turf.nearestPointOnLine(
          shoreline,
          turf.point(landCoverIntersectPoint[index]),
          options
      );

      distancesFromShoreLine[index] = turf.distance(
          turf.point(landCoverIntersectPoint[index]),
          pontoShoreLineProximo,
          options
      );
    } else {
      distancesFromBaseLine[index] = null;
      distancesFromShoreLine[index] = null;
    }
  }

  if (oneCoordsFlatten.length > 0 && twoCoordsFlatten.length > 0) {
    calcularDistancias(0, oneCoordsFlatten, intersectionData.intersectionSideOneLength);
    calcularDistancias(1, twoCoordsFlatten, intersectionData.intersectionSideTwoLength);

    landCoverBiggestSide = lengthSide[0] > lengthSide[1] ? 0 : 1;
  } else if (twoCoordsFlatten.length > 0) {
    calcularDistancias(1, twoCoordsFlatten, intersectionData.intersectionSideTwoLength);

    landCoverBiggestSide = 1;
  } else if (oneCoordsFlatten.length > 0) {
    calcularDistancias(0, oneCoordsFlatten, intersectionData.intersectionSideOneLength);

    landCoverBiggestSide = 0;
  }

  if (isWater) {
    waterSide = landCoverBiggestSide;
    waterIntersectPoint = landCoverIntersectPoint[landCoverBiggestSide];
  }

  var name = landcovers[landcover.id];
  var classe = landcovers[landcover.id];

  return {
    label: landcover.id,
    name: name,
    classe: classe,
    lengthSide: lengthSide,
    biggestSide: landCoverBiggestSide,
    intersectPoint: landCoverIntersectPoint,
    baseLineDistance: distancesFromBaseLine,
    shoreLineDistance: distancesFromShoreLine,
    waterSide: waterSide,
    waterIntersectPoint: waterIntersectPoint
  };
}

export const extractShoreLineTurf = (baseLine, waterGeometry) => {
  var waterTurf = turf.feature(waterGeometry);
  var intersection = turf.intersect(baseLine, waterTurf);
  return turf.polygonToLine(intersection);
}

export const landCoversIntersectionsTurf = (transect, landcoversdata, shoreLine, year) => {

  var sideOneCoords = transect.sides[0].coords.map(arr => Object.values(arr));
  var sideTwoCoords = transect.sides[1].coords.map(arr => Object.values(arr));

  var sideOne = sideOneCoords;
  var sideTwo = sideTwoCoords;

  var completeLine = [sideOne, sideTwo];
  var lineCenter = transect.center;

  var waterSide;
  var waterIntersectPoint;
  var landcoversOutput;

  var waters = landcoversdata.filter(function (l) { return l.id === 2 })
  var outputwater = waters.map(function (water) {
    return landParsingTurf(water, [sideOne, sideTwo], lineCenter, shoreLine)
  })[0]

  waterSide = outputwater.waterSide;
  waterIntersectPoint = outputwater.waterIntersectPoint;

  if (waterSide > -1) {
    if (waterSide === 0) {
      sideOne = sideTwoCoords
      sideTwo = [lineCenter, waterIntersectPoint]
      completeLine = [sideOne, sideTwo]
    }

    if (waterSide === 1) {
      sideOne = sideOneCoords
      sideTwo = [lineCenter, waterIntersectPoint]
      completeLine = [sideOne, sideTwo]
    }
  }

  landcoversOutput = landcoversdata.map(landcover => {
    return landParsingTurf(landcover, [sideOne, sideTwo], lineCenter, shoreLine)
  });

  return {
    id: transect.transect,
    year,
    center: lineCenter,
    completeLine,
    sides: transect.sides,
    waterSide: waterSide,
    waterIntersectPoint: waterIntersectPoint,
    landCoversIntersections: landcoversOutput
  };

}

export const filterElevation = (image, elevation, type = null, satellite = "LANDSAT") => {

  var elevationMask;

  // Digital Elevation Model (DEM)
  // var dem = ee.Image("USGS/SRTMGL1_003").lte(elevation);

  // ALOS DSM: Global 30m v3.2
  // var dem = ee.ImageCollection('JAXA/ALOS/AW3D30/V3_2').select('DSM').mosaic().lte(elevation);
  var dataset = ee.ImageCollection('JAXA/ALOS/AW3D30/V3_2').select(0).mosaic().lte(elevation);

  // var dem = ee.Image("users/nlang/ETH_GlobalCanopyHeightSD_2020_10m_v1").lte(elevation)

  // NADA ELEVATION
  // var nasa = ee.Image('NASA/NASADEM_HGT/001').select(0).lte(elevation);

  if (type === "mapbiomas") {
    // Reprojeta e redimensiona a imagem DSM para a mesma projeção e resolução espacial da imagem classification
    var dataset_reproj = dataset.reproject({
      crs: image.projection(),
      scale: image.projection().nominalScale(),
    });

    // Subtrai a imagem DSM da imagem classification
    var classification_minus_dataset = image.subtract(dataset_reproj);

    // Define a máscara para excluir a área do DSM
    var mask = dataset_reproj.eq(0).not();

    // Aplica a máscara à imagem classification_minus_dsm
    elevationMask = classification_minus_dataset.updateMask(mask);
    return image.updateMask(elevationMask)
  } else {
    if (satellite === "S2") {
      dataset = ee.Image('USGS/SRTMGL1_003');
      elevationMask = dataset.lte(elevation);
    } else {
      elevationMask = dataset.subtract(image.select(0));
    }
  }

  return image.updateMask(elevationMask);

}

export const applyScaleFactors = (image) => {
  var opticalBands = image.select('SR_B.').multiply(0.0000275).add(-0.2);
  var thermalBand = image.select('ST_B.*').multiply(0.00341802).add(149.0);
  return image.addBands(opticalBands, null, true)
    .addBands(thermalBand, null, true);
}

export const getMapBiomasClassificationsList = () => {
  var mapbiomas = ee.Image("projects/mapbiomas-public/assets/brazil/lulc/collection9/mapbiomas_collection90_integration_v1").getInfo();
  return mapbiomas.bands.map((m) => m.id);
}

export const getMapBiomasClassificationYear = (AOI, year, elevation = 10) => {
  let classified = ee.Image("projects/mapbiomas-public/assets/brazil/lulc/collection9/mapbiomas_collection90_integration_v1")
    .select("classification_" + year).clip(ee.Geometry.Polygon(AOI));
  return filterElevation(classified, elevation, "mapbiomas");
}

export function getLandCoverLabelMapBiomas(value) {
  let landcoverstypes = {
    mangrove: {
      label: 0,
      types: [5]
    },
    vegetation: {
      label: 1,
      types: [1, 3, 4, 5, 49, 10, 11, 12, 32, 29, 50, 13]
    },
    water: {
      label: 2,
      types: [26, 33]
    },
    human: {
      label: 3,
      types: [14, 15, 18, 19, 39, 20, 40, 62, 41, 36, 46, 47, 48, 9, 21, 22, 23, 24, 30, 25, 31, 27]
    },
  }

  for (const type of Object.entries(landcoverstypes)) {
    if (type[1].types.includes(value)) {
      return type[1].label;
    }
  }

  return 3;
}

export function maskCloudLandsat(image, qa_band, geometry) {
  return maskLandsatCloudsRatio (image,geometry,qa_band);
}

export function maskCloudSentinel(image, mission) {
  return maskS2ImageMask(image, mission).divide(10000);
}

export const applyNdviBand = (image, bands) =>
  image.expression('(NIR - RED) / (NIR + RED)', {
    'NIR': image.select(bands.nir),
    'RED': image.select(bands.red),
  }).rename('NDVI')

export const applyMndwiBand = (image, bands) =>
  image.expression('(GREEN - SWIR) / (GREEN + SWIR)', {
    'GREEN': image.select(bands.green),
    'SWIR': image.select(bands.swir),
  }).rename('MNDWI')

export const applyUiBand = (image, bands) =>
  image.expression('(SWIR - NIR) / (SWIR + NIR)', {
    'SWIR': image.select(bands.swir),
    'NIR': image.select(bands.nir),
  }).rename('UI')

export const trainAndClassify = (image, classificationAreas, bands) => {
  let trainingData = image
    .select(bands)
    .sampleRegions({
      collection: classificationAreas,
      properties: ["LandCover"],
      scale: 30,
    });

  let classifier = ee.Classifier.smileRandomForest(30).train({
    features: trainingData,
    classProperty: "LandCover",
    inputProperties: bands,
  });

  return image.select(bands).classify(classifier);
}

export const getMangroves = function () {
  var dataset = ee.ImageCollection("ESA/WorldCover/v200").first();

  // Cria uma máscara para manter apenas o mangue (valor 95 - mangue)
  var mangroveMask = dataset.select('Map').eq(95);

  // Aplica a máscara à imagem original
  var mangroves = dataset.updateMask(mangroveMask);

  var visualization = {
    min: 95,
    max: 95,
    palette: ['000000', 'red']
  };

  var slope = ee.Terrain.slope(mangroves);
  var mapId = slope.getMap(visualization);
  var tileSource = new ee.layers.EarthEngineTileSource(mapId);
  return new ee.layers.ImageOverlay(tileSource);
}