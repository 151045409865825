import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import ee from "../../../services/earth-engine";
import { Box, Button, Checkbox, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import StepperButtons from "../acquisition/StepperButtons";
import * as Map from "../../../common/map";
import { Actions as Acquisition } from "../../../store/ducks/acquisition";
import TourGuider from "../tour/TourGuider";
import { useLocalStorage } from "../../../common/utils";
import HelpButton from "../core/HelpButton";
import { getMangroves } from "../../../algorithms/csqueeze";

// useStyles is a hook for styling this component with Material UI's styling solution

// This is the page of the second step of the aquisition wizard
// it is supposed to get the Area of Interest (AOI) from the map
// and to save it in the storage.
// It also shows a button to start the third step.
const AOIChooser = ({ navigate, ...extra }) => {
  const dispatch = useDispatch();
  const mapDiv = useRef();
  const mapRef = useRef();
  const drawingManager = useRef();
  const mangroveLayer = useRef();

  // get the current language
  const [t] = useTranslation();
  const [overlay, setOverlay] = useState(null);
  const [coordinates, setCoordinates] = useState(null);
  const [areaOfPolygon, setAreaOfPolygon] = useState(null);

  const [isKmlDialogOpen, setKmlDialogOpen] = useState(false);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");

  const [mangrove, setMangrove] = useState(false);

  const [geeErrorOpen, setGeeErrorOpen] = useState(false);

  useEffect(() => {
    mapRef.current = new window.google.maps.Map(mapDiv.current, {
      center: { lat: -26.285757, lng: -48.73506 },
      zoom: 10,
      scaleControl: true,
      streetViewControl: false,
      mapTypeControl: true,
      mapTypeControlOptions: {
        style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: window.google.maps.ControlPosition.TOP_LEFT,
      },
      fullscreenControl: true,
      styles: [
        {
          featureType: "poi",
          stylers: [{ visibility: "off" }],
        },
      ],
    });

    Map.initializeMap(mapRef.current);

    drawingManager.current = new window.google.maps.drawing.DrawingManager({
      drawingControl: true,
      drawingControlOptions: {
        style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: window.google.maps.ControlPosition.TOP_CENTER,
        drawingModes: [
          window.google.maps.drawing.OverlayType.POLYGON,
          window.google.maps.drawing.OverlayType.RECTANGLE,
        ],
      },
      polygonOptions: {
        fillColor: "#00B3A1",
        fillOpacity: 0.2,
        strokeColor: "#003832",
        strokeOpacity: 1,
        strokeWeight: 0.5,
      },
      rectangleOptions: {
        fillColor: "#00B3A1",
        fillOpacity: 0.2,
        strokeColor: "#003832",
        strokeOpacity: 1,
        strokeWeight: 0.5,
      },
    });

    drawingManager.current.setMap(mapRef.current);

    window.google.maps.event.addListener(
      drawingManager.current,
      "overlaycomplete",
      handleDrawing
    );
  }, []);

  function getCoordinates(shape) {
    let coordinates = [];

    if (shape.type === "rectangle") {
      const bounds = shape.overlay.getBounds();
      const ne = bounds.getNorthEast();
      const sw = bounds.getSouthWest();
      coordinates = [
        [ne.lng(), ne.lat()],
        [ne.lng(), sw.lat()],
        [sw.lng(), sw.lat()],
        [sw.lng(), ne.lat()],
        [ne.lng(), ne.lat()],
      ];
    }

    if (shape.type === "polygon") {
      const path = shape.overlay.getPath();
      const array = path.getArray();
      coordinates = array.map((p) => [p.lng(), p.lat()]);
    }

    return coordinates;
  }

  // handle drawing the AOI on the map
  const handleDrawing = (overlay) => {
    let coords = getCoordinates(overlay);

    setOverlay(overlay.overlay);
    setCoordinates(coords);
    // disable the drawing controls, when the AOI is drawn
    drawingManager.current.setMap(null);

    // Calcule a área do polígono usando a função computeArea da biblioteca de geometria do Google Maps
    var areaM = window.google.maps.geometry.spherical.computeArea(
      coords.map((coord) => {
        return { lat: coord[0], lng: coord[1] };
      })
    );

    // Converta a área em metros quadrados para quilômetros quadrados
    var areaKm = areaM / 1000000;

    setAreaOfPolygon(areaKm);
  };

  // handle the undoing of the AOI
  const handleUndo = () => {
    overlay.setMap(null);
    setOverlay(null);
    setCoordinates(null);
    setAreaOfPolygon(null);
    // enable the drawing controls
    drawingManager.current.setMap(mapRef.current);
  };

  // handle the saving of the AOI
  const handleChoose = () => {
    try{
      dispatch(
        Acquisition.setAOI(
          overlay,
          coordinates,
          ee.Geometry.Polygon([coordinates])
        )
      );
    } catch (e) {
      setGeeErrorOpen(true);
    }
  };

  const handleMangrove = (e) => {
    let visible = typeof e === "boolean" ? e : e.target.checked;
    if (!mangroveLayer.current) mangroveLayer.current = getMangroves();

    if (visible) {
      mapRef.current.overlayMapTypes.push(mangroveLayer.current);
    } else {
      mapRef.current.overlayMapTypes.clear();
    }

    setMangrove(visible);
  };

  // check if the AOI is already saved
  const drawn = Boolean(coordinates);

  // defines the steps for the tour
  const steps = [
    {
      selector: "#areachooser",
      content: t("tour.csqueeze.2.aoi"),
    },
    {
      selector: "#mangrove",
      content: t("tour.csqueeze.2.mangrove"),
    },
    {
      selector: "#importKml",
      content: t("tour.csqueeze.2.importKml"),
    },
  ];

  // create a localStorage object to check if the user has already seen the tour
  const [isTourOpen, setIsTourOpen] = useLocalStorage("showROITour", true);

  const readFileData = function () {
    let fileField = document.getElementById("file_import_kml");

    if (fileField.files.length === 0) {
      openErrorDialog(t("forms.csqueeze.2.errorMessageSelectFile"));
      return false;
    }

    const file = fileField.files[0];
    const reader = new FileReader();

    reader.addEventListener("load", function () {
      const fileContent = reader.result;
      const kmlTokenBegin = "<coordinates>";
      const kmlTokenEnd = "</coordinates>";

      if (fileContent.indexOf(kmlTokenBegin) !== -1) {
        let coordinatesString = fileContent.split(kmlTokenBegin)[1].split(kmlTokenEnd)[0];

        let coordinatesArrayStrings = coordinatesString.trim().split(/\s+/);
        let coordinatesArray = [];

        coordinatesArrayStrings.forEach((coordinate) => {
          if (coordinate.trim() !== "") {
            let coordinatesValues = coordinate.split(",");
            coordinatesValues = coordinatesValues.map((value) => parseFloat(value));

            if (!isNaN(coordinatesValues[0]) && !isNaN(coordinatesValues[1])) {
              coordinatesArray.push([coordinatesValues[0], coordinatesValues[1]]);
            }
          }
        });

        if (coordinatesArray.length > 0) {
          let overlayFixed = Map.drawOutline(coordinatesArray);

          if (!overlayFixed) {
            openErrorDialog(t("forms.csqueeze.2.errorMessageDrawingOutline"));
            return;
          }

          overlayFixed.setOptions({
            fillColor: "#00B3A1",
            fillOpacity: 0.2,
            strokeColor: "#003832",
            strokeOpacity: 1,
            strokeWeight: 0.5,
          });

          setOverlay(overlayFixed);
          setCoordinates(coordinatesArray);

          Map.centralize(coordinatesArray[0][1], coordinatesArray[0][0]);
          Map.setDrawingControlsVisible(false);
          drawingManager.current.setMap(null);
          closeFilePanel();
          openInfoDialog(t("forms.csqueeze.2.infoMessageSuccess"));
        } else {
          openErrorDialog(t("forms.csqueeze.2.errorMessageNoValidCoord"));
        }
      } else {
        openErrorDialog(t("forms.csqueeze.2.errorMessageUnableInterpretKml"));
      }
    }, false);

    if (file) {
      reader.readAsText(file);
    }
  };

  const handleDisplayImportKML = function () {
    setKmlDialogOpen(true);
  };

  const closeFilePanel = function () {
    setKmlDialogOpen(false);
  };

  const openErrorDialog = (message) => {
    setDialogMessage(message);
    setErrorDialogOpen(true);
  };

  const openInfoDialog = (message) => {
    setDialogMessage(message);
    setInfoDialogOpen(true);
  };

  const closeErrorDialog = () => {
    setErrorDialogOpen(false);
  };

  const closeInfoDialog = () => {
    setInfoDialogOpen(false);
  };

  const handleCloseGeeError = () => {
    setGeeErrorOpen(false);
  };

  return (
    <React.Fragment>
      <Box>
        <Box
          textAlign="center"
          display="flex"
          justifyContent="center"
          marginBottom={areaOfPolygon ? -9 : 0}
          marginTop={areaOfPolygon ? +3 : 0}
          style={{ color: "white" }}
        >
          {areaOfPolygon && (
            <React.Fragment>
              {areaOfPolygon > 20 ? (
                <Typography
                  style={{
                    borderRadius: 10,
                    padding: "10px 20px",
                    backgroundColor: "#f02c2c",
                    fontWeight: 800,
                  }}
                >
                  {t("forms.csqueeze.2.performanceMessage")
                    .replace("{number}", areaOfPolygon.toFixed(2))
                    .replace(
                      "{level}",
                      t("forms.csqueeze.2.slow").toUpperCase()
                    )}
                </Typography>
              ) : areaOfPolygon > 10 ? (
                <Typography
                  style={{
                    borderRadius: 10,
                    padding: "10px 20px",
                    backgroundColor: "#ec942c",
                    fontWeight: 800,
                  }}
                >
                  {t("forms.csqueeze.2.performanceMessage")
                    .replace("{number}", areaOfPolygon.toFixed(2))
                    .replace(
                      "{level}",
                      t("forms.csqueeze.2.moderate").toUpperCase()
                    )}
                </Typography>
              ) : (
                <Typography
                  style={{
                    borderRadius: 10,
                    padding: "10px 20px",
                    backgroundColor: "#40a6ce",
                    fontWeight: 800,
                  }}
                >
                  {t("forms.csqueeze.2.performanceMessage")
                    .replace("{number}", areaOfPolygon.toFixed(2))
                    .replace(
                      "{level}",
                      t("forms.csqueeze.2.fast").toUpperCase()
                    )}
                </Typography>
              )}
            </React.Fragment>
          )}
        </Box>

        <Dialog
            open={errorDialogOpen}
            onClose={closeErrorDialog}
            PaperProps={{ style: { padding: 20, borderRadius: 12 } }}
        >
          <DialogContent style={{ textAlign: 'center' }}>
            <h2 style={{ marginTop: 0 }}>{t("forms.csqueeze.2.errorTitle")}</h2>
            <p style={{ fontSize: 18 }}>{dialogMessage}</p>
          </DialogContent>
          <DialogActions style={{ justifyContent: 'center' }}>
            <Button onClick={closeErrorDialog} color="secondary" variant="contained">
              {t("forms.csqueeze.2.close")}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
            open={infoDialogOpen}
            onClose={closeInfoDialog}
            PaperProps={{ style: { padding: 20, borderRadius: 12 } }}
        >
          <DialogContent style={{ textAlign: 'center' }}>
            <h2 style={{ marginTop: 0 }}>{t("forms.csqueeze.2.infoTitle")}</h2>
            <p style={{ fontSize: 18 }}>{dialogMessage}</p>
          </DialogContent>
          <DialogActions style={{ justifyContent: 'center' }}>
            <Button onClick={closeInfoDialog} color="primary" variant="contained">
              {t("forms.csqueeze.2.close")}
            </Button>
          </DialogActions>
        </Dialog>

        <Button
          id="importKml"
          onClick={handleDisplayImportKML}
          sx={{ boxShadow: 12 }}
          variant="outlined"
          style={{
            cursor: "pointer",
            color: "#26a69a",
            left: 10,
            top: 205,
            backgroundColor: "#FFF",
            zIndex: 99,
          }}
        >
          {t("forms.csqueeze.2.importTitle")}
        </Button>

        <Dialog
            open={isKmlDialogOpen}
            onClose={closeFilePanel}
            aria-labelledby="kml-dialog-title"
            aria-describedby="kml-dialog-description"
            PaperProps={{ style: { padding: 20, borderRadius: 12 } }}
        >
          <DialogContent>
            <fieldset style={{ borderColor: "#BBB", display: "block", fontSize: 15 }} id="import_kml_component">
              <legend>{t("forms.csqueeze.2.importLegend")}</legend>
              <input
                  id="file_import_kml"
                  accept=".kml"
                  type="file"
                  onChange={readFileData}
                  style={{ fontSize: 15 }}
              />
            </fieldset>
          </DialogContent>
          <DialogActions style={{ justifyContent: 'right', paddingRight: 28 }}>
            <Button onClick={closeFilePanel} color="secondary" variant="contained">
              {t("forms.map.cancel")}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={geeErrorOpen}
          onClose={handleCloseGeeError}
          aria-labelledby="gee-error-title"
          aria-describedby="gee-error-description"
          PaperProps={{ style: { padding: 20, borderRadius: 12, textAlign: 'center' } }}
        >
          <DialogTitle id="gee-error-title" style={{ textAlign: 'center' }}>
            {t("forms.csqueeze.2.geeError.title")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="gee-error-description" style={{ fontSize: '16px' }}>
              {t("forms.csqueeze.2.geeError.content")}
              <a href="https://earthengine.google.com/signup/" target="_blank" rel="noopener noreferrer">
                https://earthengine.google.com/signup/
              </a>
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ justifyContent: 'center' }}>
            <Button onClick={handleCloseGeeError} color="primary" variant="outlined">
              {t("forms.csqueeze.2.geeError.close")}
            </Button>
          </DialogActions>
        </Dialog>
        <HelpButton
          onClickFunction={() => {
            setIsTourOpen(true);
          }}
        />
        <Box
          id="mangrove"
          style={{
            height: "40px",
            position: "absolute",
            backgroundColor: "#FFF",
            boxShadow: "rgba(0, 0, 0, 0.3) 0px 1px 4px -1px",
            borderRadius: "2px",
            border: "1px solid rgba(0, 0, 0, 0.3)",
            padding: "0px 10px 0px 0px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: "99",
            marginTop: "60px",
            marginLeft: "10px",
            cursor: "pointer",
          }}
          sx={{ boxShadow: 12 }}
          onClick={() => handleMangrove(!mangrove)}
        >
          <Checkbox
            checked={mangrove}
            // onClick={handleMangrove}
            inputProps={{ "aria-label": "primary checkbox" }}
          />
          <Typography>{t("forms.csqueeze.2.viewMangrove")}</Typography>
        </Box>
        <div id="areachooser" style={{ width: "100%", height: 600 }}>
          <div
            id="google-maps"
            ref={(r) => (mapDiv.current = r)}
            style={{ height: "100%" }}
          ></div>
        </div>
        <StepperButtons
          navigate={navigate}
          nextDisabled={drawn === false}
          onNext={handleChoose}
        >
          <Button
            onClick={handleUndo}
            disabled={drawn === false}
            color="secondary"
            variant="outlined"
          >
            {t("forms.csqueeze.2.undo")}
          </Button>
        </StepperButtons>
        {
          // if the user hasn't seen the tour, show it
        }
        <TourGuider
          steps={steps}
          isOpen={isTourOpen}
          setIsTourOpen={setIsTourOpen}
        />
      </Box>
    </React.Fragment>
  );
};

export default AOIChooser;
