import React, { useEffect, useRef, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import ee from "../../../services/earth-engine";
import {
  Box,
  Button,
  FormLabel,
  Radio,
  Grid,
  Card,
  CardContent,
  Typography,
  CardActions,
  IconButton,
  Paper,
  TextField,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import Fullscreen from "@material-ui/icons/Fullscreen";
import DoneIcon from "@material-ui/icons/Done";

import Zoom from "react-medium-image-zoom";

import StepperButtons from "../acquisition/StepperButtons";

import { FINALIZE } from "../../pages/AcquisitionPage";

import { Actions as CSqueeze } from "../../../store/ducks/csqueeze";

import moment from "moment";

import {
  trainAndClassify,
  getMapBiomasClassificationYear,
  getMapBiomasClassificationsList,
} from "../../../algorithms/csqueeze";
import { useLocalStorage } from "../../../common/utils";
import TourGuider from "../tour/TourGuider";
import HelpButton from "../core/HelpButton";

const toTimestamp = (date) => parseInt(moment(date).format("x"), 10);

const mapbiomas_lulc_class_color = `
  <RasterSymbolizer>
  <ChannelSelection>
  <GrayChannel>
  <SourceChannelName>1</SourceChannelName>
  </GrayChannel>
  </ChannelSelection>
  <ColorMap type="values">
    <ColorMapEntry color="#129912" quantity="1" />
    <ColorMapEntry color="#129912" quantity="3" />
    <ColorMapEntry color="#129912" quantity="4" />
    <ColorMapEntry color="#687537" quantity="5" />
    <ColorMapEntry color="#129912" quantity="49" />
    <ColorMapEntry color="#129912" quantity="10" />
    <ColorMapEntry color="#129912" quantity="11" />
    <ColorMapEntry color="#129912" quantity="12" />
    <ColorMapEntry color="#129912" quantity="32" />
    <ColorMapEntry color="#129912" quantity="29" />
    <ColorMapEntry color="#129912" quantity="50" />
    <ColorMapEntry color="#129912" quantity="13" />
    <ColorMapEntry color="#af2a2a" quantity="14" />
    <ColorMapEntry color="#af2a2a" quantity="15" />
    <ColorMapEntry color="#af2a2a" quantity="18" />
    <ColorMapEntry color="#af2a2a" quantity="19" />
    <ColorMapEntry color="#af2a2a" quantity="39" />
    <ColorMapEntry color="#af2a2a" quantity="20" />
    <ColorMapEntry color="#af2a2a" quantity="40" />
    <ColorMapEntry color="#af2a2a" quantity="62" />
    <ColorMapEntry color="#af2a2a" quantity="41" />
    <ColorMapEntry color="#af2a2a" quantity="36" />
    <ColorMapEntry color="#af2a2a" quantity="46" />
    <ColorMapEntry color="#af2a2a" quantity="47" />
    <ColorMapEntry color="#af2a2a" quantity="48" />
    <ColorMapEntry color="#af2a2a" quantity="9" />
    <ColorMapEntry color="#af2a2a" quantity="21" />
    <ColorMapEntry color="#af2a2a" quantity="22" />
    <ColorMapEntry color="#af2a2a" quantity="23" />
    <ColorMapEntry color="#af2a2a" quantity="24" />
    <ColorMapEntry color="#af2a2a" quantity="30" />
    <ColorMapEntry color="#af2a2a" quantity="25" />
    <ColorMapEntry color="#0000ff" quantity="26" />
    <ColorMapEntry color="#0000ff" quantity="33" />
    <ColorMapEntry color="#af2a2a" quantity="31" />
    <ColorMapEntry color="#af2a2a" quantity="27" />
  </ColorMap>
  </RasterSymbolizer>`;

const useStyles = makeStyles((theme) => ({
  wrapper: {
    maxWidth: "100vw",
    flexGrow: 1,
    position: "relative",
  },
  mapContainer: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-end",
    flexFlow: "column",
  },
  mapOverlay: {
    position: "absolute",
    zIndex: 10,
    pointerEvents: "none",
    "& > *": {
      pointerEvents: "none",
      "& > *": {
        pointerEvents: "auto",
      },
    },
  },
  margin: {
    margin: "5px",
  },
  right: {
    textAlign: "right",
  },
  landCoverMarkButton: {
    backgroundColor: "white",
  },
}));

const LandCover = ({ navigate }) => {
  const [isTourOpen, setIsTourOpen] = useLocalStorage("showPeriodTour", true);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [t] = useTranslation();

  const images = useSelector((state) => state.imagery.images);
  const coordinates = useSelector((state) => state.acquisition.coordinates);
  const availableDates = useSelector(
    (state) => state.acquisition.availableDates
  );

  const mapDiv = useRef();
  const mapRef = useRef();
  const drawingManager = useRef();
  const landCoverRef = useRef(null);
  const tempTab = useRef(0);
  const AOI = useRef();
  const bounds = useRef();
  const selectedLandCoverRef = useRef(0);
  const markedPoints = useRef([]);

  const [markedPointsCount, setMarkedPointsCount] = useState(1);

  const [selectedLandCover, setSelectedLandCover] = useState(0);
  const [imagesLayers, setImagesLayers] = useState([]);
  const [layers, setLayers] = useState({});
  const [finish, setFinish] = useState([false, false]);

  const [mapMode, setMapMode] = useState(1);

  const [years, setYears] = useState([[], []]);
  const [tabKey, setTabKey] = useState(0);

  const [elevation, setElevation] = useState(10);
  const [defineElevation, setDefineElevation] = useState(true);

  const [datasets, setDatasets] = useState([]);

  const [falseColor, setFalseColor] = useState(false);

  const [mapBiomasDialogOpen, setMapBiomasDialogOpen] = useState(false);
  const [polygonsFileProcessedDialogOpen, setPolygonsFileProcessedDialogOpen] = useState(false);
  const [mapBiomasConfirmAction, setMapBiomasConfirmAction] = useState(() => () => {});


  const landCovers = [
    {
      value: 0,
      label: t("forms.csqueeze.landcovers.mangrove"),
      class: t("forms.csqueeze.landcovers.mangrove"),
      color: "#687537",
    },
    {
      value: 1,
      label: t("forms.csqueeze.landcovers.vegetation"),
      class: t("forms.csqueeze.landcovers.vegetation"),
      color: "#129912",
    },
    {
      value: 2,
      label: t("forms.csqueeze.landcovers.water"),
      class: t("forms.csqueeze.landcovers.water"),
      color: "#0000ff",
    },
    {
      value: 3,
      label: t("forms.csqueeze.landcovers.human"),
      class: t("forms.csqueeze.landcovers.human"),
      color: "#af2a2a",
    },
    {
      value: 4,
      label: t("forms.csqueeze.5.removePolygon"),
      class: t("forms.csqueeze.5.removePolygon"),
      color: "#000000",
    },
  ];

  const pointerColors = landCovers.map((landCover) => landCover.color);

  useEffect(() => {
    const datasetList = getMapBiomasClassificationsList();
    setDatasets(datasetList);

    mapRef.current = new window.google.maps.Map(mapDiv.current, {
      center: { lat: -26.285757, lng: -48.73506 },
      zoom: 13,
      scaleControl: true,
      streetViewControl: false,
      mapTypeControl: true,
      mapTypeControlOptions: {
        style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: window.google.maps.ControlPosition.TOP_CENTER,
      },
      styles: [
        {
          featureType: "poi",
          stylers: [{ visibility: "off" }],
        },
      ],
      fullscreenControl: false,
    });

    const formatedCoordinates = coordinates.map((coord) => {
      return { lat: coord[1], lng: coord[0] };
    });

    AOI.current = new window.google.maps.Polygon({
      path: formatedCoordinates,
      geodesic: true,
      fillColor: "transparent",
      strokeColor: "#00A391",
      strokeOpacity: 0.6,
      strokeWeight: 5,
      clicable: false,
    });

    AOI.current.setMap(mapRef.current);

    bounds.current = new window.google.maps.LatLngBounds();
    AOI.current
      .getPath()
      .getArray()
      .forEach((coord) => {
        let latLng = new window.google.maps.LatLng(coord.lat(), coord.lng());
        bounds.current.extend(latLng);
      });
    const center = bounds.current.getCenter();
    mapRef.current.setCenter(center);
    mapRef.current.panBy(1000, 0);

    setMapMode(0);
  }, []);

  useEffect(() => {
    console.log("images(input)",images);
    console.log("markedPoints (useEffect)", markedPoints);

    const values = Object.values(images);
    if (values.length > 0) {
      values.forEach((image, i) => {

        (() => {
          const imgOverlay = new Image();
          imgOverlay.src = image.url;
          const imgFalseColor = new Image();
          imgFalseColor.src = image.falseColorUrl;
        })();
        console.log("(layers)image", image);
        console.log("bounds", bounds, bounds.current);
        var overlayFalseColor = new window.google.maps.GroundOverlay(
          image.falseColorUrl,
          bounds.current
        );

        var overlay = new window.google.maps.GroundOverlay(
          image.url,
          bounds.current
        );
        console.log("overlay", overlay,"img.url", image.url);
        console.log("mapRef", mapRef, mapRef.current);
        overlayFalseColor.setMap(mapRef.current);
        overlay.setMap(mapRef.current);

        overlayFalseColor.setMap(null);

        if (i !== 0) {
          overlay.setMap(null);
        }

        setImagesLayers({
          ...imagesLayers,
          [image.date]: { ...image, overlay, overlayFalseColor },
        });

        setLayers({
          ...layers,
          [image.date]: { ...image, overlay, overlayFalseColor },
        });

        markedPoints.current = { ...markedPoints.current, [image.date]: [] };
      });

      setTabKey(values[0].date ?? 0);
      console.log("End layers:", layers, imagesLayers);
      window.layers = layers;
      window.imagesLayers = imagesLayers;
    }
  }, [images]);

  useEffect(() => {
    console.log(
      years.map((y) => y[0]),
      tabKey
    );
    if (years.map((y) => y[0]).indexOf(tabKey) === -1) return;
    changeTab(tabKey);
    tempTab.current = tabKey;
  }, [tabKey, falseColor]);

  useEffect(() => {
    drawingManager.current = new window.google.maps.drawing.DrawingManager({
      drawingMode: "rectangle",
      drawingControl: false,
      polygonOptions: {
        fillColor: "#00B3A1",
        fillOpacity: 0.2,
        strokeColor: "#003832",
        strokeOpacity: 1,
        strokeWeight: 0.5,
      },
    });

    drawingManager.current.setMap(mapRef.current);

    window.google.maps.event.addListener(
      drawingManager.current,
      "overlaycomplete",
      handleDrawing
    );
  }, [drawingManager]);

  const generateClassificationLayer = (image, polygsData, type = "") => {
    let imagem = image.imageObject;
    let bands = ["UI", "NDVI", "MNDWI"];
    var dataList = new window.google.maps.Data();
    var polygs = polygsData.filter((p) => p !== null);

    if (type === "") {
      polygs.forEach((m) => {
        var b = m.overlay.getBounds(),
          p = [
            b.getSouthWest(),
            {
              lat: b.getSouthWest().lat(),
              lng: b.getNorthEast().lng(),
            },
            b.getNorthEast(),
            {
              lng: b.getSouthWest().lng(),
              lat: b.getNorthEast().lat(),
            },
          ];
        dataList.add(
          new window.google.maps.Data.Feature({
            geometry: new window.google.maps.Data.Polygon([p]),
            properties: {
              LandCover: m.overlay.LandCover,
            },
          })
        );
      });
    }

    dataList.toGeoJson((obj) => {
      let classificationAreas = ee.FeatureCollection(obj);
      let classified, classifiedMap;

      if (type === "mapbiomas") {
        classified = getMapBiomasClassificationYear(
          coordinates,
          tabKey,
          elevation
        );

        classifiedMap = classified
          .sldStyle(mapbiomas_lulc_class_color)
          .getThumbURL({
            format: "png",
            dimensions: 800,
            tileScale: 512,
          });
      } else {
        classified = trainAndClassify(imagem, classificationAreas, bands, AOI);

        let vis = {
          palette: pointerColors,
          min: 0,
          max: pointerColors.length - 1,
        };
        classifiedMap = classified.visualize(vis).getThumbURL({
          format: "png",
          dimensions: 800,
          tileScale: 512,
        });
      }

      var overlay = new window.google.maps.GroundOverlay(
        classifiedMap,
        bounds.current
      );

      overlay.setMap(mapRef.current);
      console.log("GenerateClassificationLayer", "overlay", overlay, layers);
      setLayers({
        ...layers,
        [tabKey]: {
          ...layers[tabKey],
          "type": type,
          "classification": overlay,
          "classified": classified,
        },
      });

      if (polygs) {
        if (polygs.length > 0)
          polygs
            .filter((p) => p !== null)
            .forEach((point) => {
              point.overlay.setVisible(false);
              point.overlay.zIndex = 0;
            });
      }

      layers[tabKey].classification = null;

      setMapMode(2);

      let yearsMap = years.map((info) => parseInt(info[0]));
      let tempFinish = [...finish];
      tempFinish[yearsMap.indexOf(parseInt(tabKey))] = true;
      setFinish(tempFinish);
    });
  };

  const handleConfirmElevation = () => {
    let availImages = availableDates.sort(
      (a, b) => toTimestamp(b.date) - toTimestamp(a.date)
    );

    const listYears = availImages.reduce((result, item) => {
      var year = item.date.slice(0, 4);
      var key = `${year}`;

      result[key] = result[key] || [];
      result[key].push(item);

      return result;
    }, {});

    let imageYears = Object.entries(listYears);

    setYears(imageYears);

    dispatch(CSqueeze.acquireImagesMedian(imageYears, elevation));
    setDefineElevation(false);

    changeTab(tabKey);
  };

  const handleElevation = (event) => {
    let value = Math.abs(parseInt(event.target.value));
    if (value > 0) {
      setElevation(value);
    } else {
      setElevation(0);
    }
  };

  const isPolygonInside = (polygonInner, polygonOuter) => {
    for (let i = 0; i < polygonInner.getPath().getLength(); i++) {
      if (
        !window.google.maps.geometry.poly.containsLocation(
          polygonInner.getPath().getAt(i),
          polygonOuter
        )
      ) {
        return false;
      }
    }
    return true;
  };

  const isRectangleInside = (rectangle, polygon) => {
    const bounds = rectangle.getBounds();
    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();
    const polygonInner = new window.google.maps.Polygon({
      paths: [
        { lat: ne.lat(), lng: ne.lng() },
        { lat: ne.lat(), lng: sw.lng() },
        { lat: sw.lat(), lng: sw.lng() },
        { lat: sw.lat(), lng: ne.lng() },
      ],
    });

    return isPolygonInside(polygonInner, polygon);
  };

  const handleFinish = () => {
    console.log("LandCover","handleFinish", "layers", layers);
    dispatch(CSqueeze.setStudyData([layers, markedPoints]));
  };

  const handleUndo = () => {
    markedPoints.current[tempTab.current]
      .filter((m) => m !== null)
      .forEach((polyg) => {
        polyg.overlay.setVisible(false);
        polyg.overlay.setMap(null);
      });

    markedPoints.current[tempTab.current] = [];

    setMarkedPointsCount(Date.now());
  };

  const changeTab = (tab) => {
    console.log("tab", tab);
    console.log("markedPoints (changeTab)", markedPoints);
    if (Object.values(layers).length < 2) return;

    const layersValues = Object.values(layers);

    const old = layersValues.filter((l) => l.date !== tab);
    const atual = layersValues.filter((l) => l.date === tab);

    console.log(old, atual);

    old.forEach((layer) => {
      layer.overlay.setMap(null);
      layer.overlayFalseColor.setMap(null);

      if (layer.classification) layer.classification.setMap(null);

      if (
        markedPoints.current[layer.date] &&
        markedPoints.current[layer.date].length > 0
      )
        markedPoints.current[layer.date].forEach((polyg) => {
          if (polyg) polyg.overlay.setVisible(false);
        });
    });

    atual.forEach((layer) => {
      if (layer.classification) {
        layer.classification.setMap(mapRef.current);
      } else if (falseColor) {
        layer.overlayFalseColor.setMap(mapRef.current);
      } else {
        console.log("set layer....",layer.overlay);
        layer.overlay.setMap(mapRef.current);
      }

      if (
        markedPoints.current[layer.date] &&
        markedPoints.current[layer.date].length > 0 &&
        !layers[layer.date].classification
      )
        markedPoints.current[layer.date].forEach((polyg) => {
          console.log("markedPoints",polyg);
          if (polyg) polyg.overlay.setVisible(true);
        });
    });
  };

  const handleDrawing = (polyg, type = null) => {
    if (isRectangleInside(polyg.overlay, AOI.current)) {
      polyg.overlay.setOptions({
        fillColor: landCovers[type ?? selectedLandCoverRef.current].color,
        fillOpacity: 0.5,
        strokeColor: landCovers[type ?? selectedLandCoverRef.current].color,
        strokeOpacity: 1,
        strokeWeight: 1,
        clicable: true,
        zIndex: 90,
      });

      polyg.overlay.set("from", "user");
      polyg.overlay.set(
        "LandCover",
        landCovers[type ?? selectedLandCoverRef.current].value
      );
      polyg.LandCover = landCovers[type ?? selectedLandCoverRef.current].value;

      if (markedPoints.current[tempTab.current] === undefined)
        markedPoints.current[tempTab.current] = [];

      markedPoints.current[tempTab.current].push(polyg);

      let positionArray = markedPoints.current[tempTab.current].length - 1;

      window.google.maps.event.addListener(polyg.overlay, "click", () => {
        polyg.overlay.setVisible(false);
        polyg.overlay.setMap(null);
        markedPoints.current[tempTab.current][positionArray] = null;
        setMarkedPointsCount(Math.random());
      });
    } else {
      polyg.overlay.setMap(null);
    }

    setMarkedPointsCount(Date.now());
  };

  const handleSelect = (value) => {
    selectedLandCoverRef.current = value;
    setSelectedLandCover(value);
    setMapMode(value);
  };

  const handleFullScreen = async () => {
    if (document.fullscreenElement) {
      landCoverRef.current.style =
        "width: 95vw; height: 100vh; top: 0; left: 0; position: relative; overflow: hidden;";
      await document.exitFullscreen();
    } else {
      landCoverRef.current.style =
        "width: 100vw; height: 100vh; top: 0; left: 0; position: fixed; z-index: 999999; overflow: hidden;";
      await document.body.requestFullscreen();
    }
  };

  const listenerCtrlKey = useCallback((e) => {
    if (e.ctrlKey || e.cmdKey) {
      drawingManager.current.setDrawingMode(null);
    } else {
      drawingManager.current.setDrawingMode("rectangle");
    }
  });

  useEffect(() => {
    if (mapMode !== 4) {
      document.addEventListener("keydown", listenerCtrlKey);
      document.addEventListener("keyup", listenerCtrlKey);
      drawingManager.current.setDrawingMode("rectangle");
    } else {
      document.removeEventListener("keydown", listenerCtrlKey);
      document.removeEventListener("keyup", listenerCtrlKey);
      drawingManager.current.setDrawingMode(null);
    }

    return () => {
      document.removeEventListener("keydown", listenerCtrlKey);
      document.removeEventListener("keyup", listenerCtrlKey);
    };
  }, [mapMode]);

  const handleGenerateClassificationDiscard = (polygs) => {
    layers[tabKey].classification.setMap(null);

    setLayers({
      ...layers,
      [tabKey]: { ...layers[tabKey], classification: null },
    });
    setMapMode(1);

    polygs
      .filter((p) => p !== null)
      .forEach((point) => {
        point.overlay.zIndex = 90;
        point.overlay.setVisible(true);
      });
  };

  const readFileTrainingData = () => {
    let fileField = document.getElementById("file_import_training_data");
    if (fileField.files.length === 0) {
      window.alert("Please select a file first.");
      return false;
    }
    const file = fileField.files[0];
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      () => {
        console.log("File data:", file.name);
        const fileContent = reader.result;
        let lines = fileContent.split("\n");
        lines = lines.splice(1, lines.length);

        lines.forEach((line) => {
          let columns = line.split(";");
          if (
            columns.length === 5 &&
            columns.every((column) => column !== undefined || column !== null)
          ) {
            let landCoverCode = parseInt(columns[0]);
            let overlay = new window.google.maps.Rectangle({
              map: mapRef.current,
              bounds: {
                south: parseFloat(columns[1]),
                west: parseFloat(columns[2]),
                north: parseFloat(columns[3]),
                east: parseFloat(columns[4]),
              },
            });

            handleDrawing({ type: "imported", overlay }, landCoverCode);
          }
        });
        setPolygonsFileProcessedDialogOpen(true);
        fileField.value = null;
      },
      false
    );
    if (file) {
      reader.readAsText(file);
    }
  };

  const exportToCsv = (filename, rows) => {
    const csvContent =
      "data:text/csv;charset=utf-8," +
      "type;south;west;north;east \n" +
      rows.map((row) => row.join(";")).join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const exportRectanglesToCsv = (rectangles) => {
    const rows = [];
    rectangles.forEach((rectangle) => {
      const type = rectangle.overlay.LandCover;
      const coordinates = Object.values(
        rectangle.overlay.getBounds().toJSON()
      ).join(";");
      rows.push([type, coordinates]);
    });

    exportToCsv(
      `cassie_csqueeze_${tempTab.current}_landcovers_${new Date().getTime()}`,
      rows
    );
  };

  const exportPoints = () => {
    exportRectanglesToCsv(
      markedPoints.current[tabKey].filter((m) => m !== null)
    );
  };

  const handleFalseColor = (event) => {
    setFalseColor(event.target.checked);
  };

  document.addEventListener("fullscreenchange", () => {
    if (!document.fullscreenElement)
      landCoverRef.current.style = landCoverRef.current.style =
        "width: 95vw; height: 100vh; top: 0; left: 0; position: relative; overflow: hidden;";
  });

  const nextDisabled = !Object.values(layers).every(layer =>
      layer && layer.classification && typeof layer.classification === 'object' && Object.keys(layer.classification).length > 0
  );

  // defines the steps for the tour
  const steps = [
    {
      selector: "#selectType",
      content: t("tour.csqueeze.5.selectType"),
    },
    {
      selector: "#falseColorButton",
      content: t("tour.csqueeze.5.falseColorButton"),
    },
    {
      selector: "#availableDataset",
      content: t("tour.csqueeze.5.availableDataset"),
    },
    {
      selector: "#importPolygons",
      content: t("tour.csqueeze.5.importPolygons"),
    },
    {
      selector: "#generateRaster",
      content: t("tour.csqueeze.5.generateRaster"),
    },
    {
      selector: "#yearsButtons",
      content: t("tour.csqueeze.5.yearsButtons"),
    },
  ];

  const handleMapBiomasClick = () => {
    setMapBiomasConfirmAction(() => () => {
      generateClassificationLayer(
        layers[tabKey],
        markedPoints.current[tabKey],
        "mapbiomas",
      );
      setMapBiomasDialogOpen(false);
    });
    setMapBiomasDialogOpen(true);
  };

  const handleMapBiomasDialogConfirm = () => {
    mapBiomasConfirmAction();
  };

  const handleDialogCancel = () => {
    if (mapBiomasDialogOpen) {
      setMapBiomasDialogOpen(false);
    }
    if (polygonsFileProcessedDialogOpen) {
      setPolygonsFileProcessedDialogOpen(false);
    }
  };

  return (
    <React.Fragment>
      <Dialog
        open={mapBiomasDialogOpen}
        onClose={handleDialogCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ style: { padding: 20, borderRadius: 12, textAlign: 'center' } }}
      >
        <DialogTitle id="alert-dialog-title" style={{ textAlign: 'center' }}>
          {t("forms.csqueeze.5.mapBiomasAlertTitle")}
        </DialogTitle>
        <DialogContent style={{ fontSize: '16px' }}>
          <Typography variant="body1">
            {t("forms.csqueeze.5.mapBiomasAlertText")}
          </Typography>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
          <Button onClick={handleDialogCancel} color="default">
            {t("forms.csqueeze.5.alertDialogCancel")}
          </Button>
          <Button onClick={handleMapBiomasDialogConfirm} color="primary" autoFocus>
            {t("forms.csqueeze.5.alertDialogConfirm")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
          open={polygonsFileProcessedDialogOpen}
          onClose={handleDialogCancel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{ style: { padding: 20, borderRadius: 12 } }}
      >
        <DialogContent style={{ textAlign: 'center' }}>
          <h2 style={{ marginTop: 0 }}>{t("forms.csqueeze.2.infoTitle")}</h2>
          <p style={{ fontSize: 18 }}>{t("forms.csqueeze.5.polygonsFileProcessed")}</p>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
          <Button onClick={handleDialogCancel} color="primary" variant="contained">
            {t("forms.csqueeze.5.alertDialogClose")}
          </Button>
        </DialogActions>
      </Dialog>

      {defineElevation && (
        <Box
          style={{
            display: "flex",
            position: "fixed",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            zIndex: 9999,
            backgroundColor: "rgba(0,0,0,0.8)",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Paper
            elevation={3}
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 8,
              width: "400px",
              padding: 30,
            }}
          >
            <Typography variant="body1">
              {t("forms.csqueeze.5.cardText")}
            </Typography>
            <Typography variant="caption">
              Landsat dataset: JAXA/ALOS/AW3D30/V3_2
            </Typography>
            <Typography variant="caption">
              Sentinel dataset: USGS/SRTMGL1_003
            </Typography>
            <Typography>{t("forms.csqueeze.5.example")}:</Typography>
            <Box
              display="flex"
              flexDirection="rows"
              justifyContent="space-between"
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              <Box
                display="flex"
                flexDirection="column"
                textAlign="center"
                style={{ gap: 8 }}
              >
                <Zoom
                  zoomMargin={50}
                  overlayBgColorStart={"rgba(255, 255, 255, 0)"}
                  overlayBgColorEnd={"rgba(255, 255, 255, 0.5)"}
                >
                  <img
                    src="/sem-elevacao.png"
                    alt="Sem elevação"
                    style={{ height: "100px", borderRadius: 5 }}
                  />
                </Zoom>
                <Typography variant="caption">
                  {t("forms.csqueeze.5.noElevation")}
                </Typography>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                textAlign="center"
                style={{ gap: 8 }}
              >
                <Zoom
                  zoomMargin={50}
                  overlayBgColorStart={"rgba(255, 255, 255, 0)"}
                  overlayBgColorEnd={"rgba(255, 255, 255, 0.8)"}
                >
                  <img
                    src="/com-elevacao.png"
                    alt="Com elevação"
                    style={{ height: "100px", borderRadius: 5 }}
                  />
                </Zoom>
                <Typography variant="caption">
                  {t("forms.csqueeze.5.withElevation")}
                </Typography>
              </Box>
            </Box>
            <Typography variant="body2">
              {t("forms.csqueeze.5.defineElevation")}
            </Typography>
            <TextField
              style={{ width: "100%", marginTop: 8 }}
              type="number"
              value={elevation}
              label={t("forms.csqueeze.5.elevation")}
              onChange={handleElevation}
            ></TextField>
            <Button
              color="primary"
              variant="contained"
              style={{ marginTop: 8 }}
              onClick={handleConfirmElevation}
            >
              {t("forms.csqueeze.5.confirm")}
            </Button>
          </Paper>
        </Box>
      )}
      <Box className={classes.wrapper}>
        <HelpButton
          onClickFunction={() => {
            setIsTourOpen(true);
          }}
        />
        <div
          id="landcover"
          ref={landCoverRef}
          style={{
            width: "95vw",
            height: "100vh",
            top: 0,
            rigth: 0,
            position: "relative",
            overflow: "hidden",
          }}
        >
          <Box
              style={{
                width: "fit-content",
                textAlign: "center",
                height: "fit-content",
                padding: 10,
                display: "block",
                position: "absolute",
                zIndex: 2,
                marginTop: 85,
                left: "50%",
                transform: "translateX(-50%)"
              }}
          >
            <Typography
              style={{
                boxShadow: `0px 2px 5px 2px #00000`,
                border: `2px solid ${landCovers[selectedLandCover].color}`,
                backgroundColor: "white",
                width: 300,
                margin: "0 auto",
                borderRadius: 5,
                padding: "10px 20px",
              }}
            >
              <b style={{ textTransform: 'uppercase' }}>
                {mapMode !== 4 ? t("forms.csqueeze.5.drawingMode") : t("forms.csqueeze.5.drawingModeRemove")}
              </b>
              <br />
              {mapMode !== 4
                ? <>
                  {t("forms.csqueeze.5.drawingModeText")}
                  <b style={{ color: landCovers[selectedLandCover].color }}>
                    {landCovers[selectedLandCover].class}
                  </b>
                  .
                </>
                : t("forms.csqueeze.5.drawingModeTextRemove")}
            </Typography>
          </Box>

          <Grid
            id="landcovermarker"
            className={classes.mapOverlay}
            style={{
              display: "grid",
              marginLeft: "10px",
              marginTop: "10px",
              width: "350px",
              gap: 10,
              overflow: "auto",
              maxHeight: "97vh",
              "&::WebkitScrollbar": {
                display: "none",
              },
              scrollbarWidth: "none",
              msOverflowStyle: "none",
            }}
          >
            {layers[tabKey] !== undefined && (
              <React.Fragment>
                <Card style={{ padding: 10 }} id="yearsButtons">
                  <CardContent style={{ padding: 0 }}></CardContent>
                  <CardActions>
                    <Box
                      width="100%"
                      display="grid"
                      gridTemplateColumns="auto auto"
                      style={{
                        gap: 5,
                        "&:lastChild": { gridColumn: "1 / 3" },
                      }}
                      fontSize="small"
                      fontWeight={300}
                    >
                      {years.map(([year, _]) => (
                        <Button
                          key={year}
                          variant={year === tabKey ? "contained" : "outlined"}
                          color={year === tabKey ? "primary" : "default"}
                          onClick={(b) => {
                            let clickValue = b.target.innerText;
                            if (years.map((y) => y[0]).indexOf(clickValue) > -1)
                              setTabKey(clickValue);
                          }}
                        >
                          {layers[year] !== undefined &&
                            layers[year].classification && <DoneIcon />}
                          {year}
                        </Button>
                      ))}
                    </Box>
                  </CardActions>
                </Card>

                {!layers[tabKey].classification && (
                  <React.Fragment>
                    <Card style={{ padding: 10 }} id="selectType">
                      <CardContent style={{ padding: 0 }}>
                        <Typography variant="subtitle2" align="center">
                          {t("forms.csqueeze.5.defineLandCovers")}
                        </Typography>
                      </CardContent>
                      <CardActions
                        style={{
                          display: "flex",
                          flexDirection: "rows",
                          gap: "10px",
                        }}
                      >
                        <Grid
                          style={{
                            display: "grid",
                            gridTemplateColumns: "auto 1fr",
                            gridTemplateRows: "auto auto",
                            alignItems: "center",
                          }}
                        >
                          {landCovers.map((land, i) => (
                            <React.Fragment key={i}>
                              <Radio
                                checked={selectedLandCover === land.value}
                                onChange={(e) => {
                                  handleSelect(land.value);
                                }}
                                value={land.value}
                                name="radio-button-demo"
                                inputProps={{ "aria-label": land.label }}
                              />
                              <FormLabel
                                style={{
                                  color: "white",
                                  backgroundColor: land.color,
                                  padding: "5px 10px",
                                  borderRadius: "10px",
                                }}
                                onClick={(e) => {
                                  handleSelect(land.value);
                                }}
                              >
                                {land.label}
                              </FormLabel>
                            </React.Fragment>
                          ))}

                          <Typography
                            variant="caption"
                            align="center"
                            style={{
                              gridColumn: "1 / 3",
                              marginTop: 10,
                            }}
                          >
                            {t("forms.csqueeze.5.shortcut")}
                          </Typography>
                        </Grid>
                      </CardActions>
                    </Card>
                    {datasets.filter((d) => d.includes(tabKey)).length > 0 && (
                      <Card style={{ padding: 10 }} id="availableDataset">
                        <CardContent style={{ padding: 0 }}>
                          <Typography variant="subtitle2" align="center">
                            {t("forms.csqueeze.5.availableDataset")}
                          </Typography>
                        </CardContent>
                        <CardActions
                          style={{
                            display: "grid",
                            gridTemplateColumns: "1fr auto",
                            gap: "10px",
                          }}
                        >
                          <Typography>
                            {t("forms.csqueeze.5.availableDatasetText")}{" "}
                            <b>MapBiomas</b>.
                          </Typography>
                          <Button
                            variant="contained"
                            style={{ height: 35, padding: "inherit 50px" }}
                            onClick={handleMapBiomasClick}
                          >
                            {t("forms.csqueeze.5.apply")}
                          </Button>
                        </CardActions>
                      </Card>
                    )}
                    <Card style={{ padding: 10 }} id="importPolygons">
                      <CardContent style={{ padding: 0 }}>
                        <Typography variant="subtitle2" align="center">
                          {t("forms.csqueeze.5.importPolygons")}
                        </Typography>
                        <Box align="center" fontSize={10}>
                          {t("forms.csqueeze.5.formatImport")}
                        </Box>
                      </CardContent>
                      <CardActions>
                        <Box
                          justifyContent="center"
                          alignItems="center"
                          width="100%"
                          display="flex"
                          flexDirection="column"
                          style={{ gap: 20 }}
                        >
                          <Box
                            style={{
                              width: "100%",
                              backgroundColor: "rgba(0, 0, 0, 0.09)",
                              borderTopLeftRadius: "4px",
                              borderTopRightRadius: "4px",
                              padding: "15px 20px",
                              borderBottom: "1px solid",
                            }}
                          >
                            <input
                              id="file_import_training_data"
                              accept=".csv"
                              type="file"
                              onChange={readFileTrainingData}
                            />
                          </Box>
                        </Box>
                      </CardActions>
                      <Box align="center" fontSize="smaller" fontStyle="italic">
                        ({t("forms.csqueeze.5.spanImport")})
                      </Box>
                    </Card>
                    <Card style={{ padding: 10 }} id="generateRaster">
                      <CardActions>
                        <Box
                          justifyContent="center"
                          alignItems="center"
                          width="100%"
                          display="flex"
                          flexDirection="column"
                          style={{ gap: 20 }}
                        >
                          <Box
                            display="grid"
                            width="100%"
                            justifyContent="center"
                            gridTemplateColumns="1fr 1fr"
                            style={{ gap: 20 }}
                          >
                            <Button
                              color="secondary"
                              variant="contained"
                              style={{ height: 35, padding: "inherit 20px" }}
                              disabled={
                                markedPointsCount &&
                                (markedPoints.current[tabKey] || []).length === 0
                              }
                              onClick={exportPoints}
                            >
                              {t("forms.csqueeze.5.export")}
                            </Button>
                            <Button
                              onClick={handleUndo}
                              disabled={(markedPoints.current[tabKey] || []).length === 0}
                              color="secondary"
                              variant="outlined"
                            >
                              {t("forms.csqueeze.5.undo")}
                            </Button>

                            {markedPointsCount &&
                            markedPoints.current[tabKey] !== undefined &&
                            markedPoints.current[tabKey].filter(
                              (e1) => e1 !== null && e1.LandCover > 0
                            ).length > 0 &&
                            markedPoints.current[tabKey].filter(
                              (e2) => e2 !== null && e2.LandCover === 0
                            ).length > 0 ? (
                              <Button
                                color="primary"
                                variant="contained"
                                onClick={() =>
                                  generateClassificationLayer(
                                    layers[tabKey],
                                    markedPoints.current[tabKey]
                                  )
                                }
                                style={{ gridColumn: "1 / 3" }}
                              >
                                {t("forms.csqueeze.5.generate")}
                              </Button>
                            ) : (
                              <Box
                                display="block"
                                fontSize={12}
                                style={{ gridColumn: "1 / 3" }}
                                align="center"
                              >
                                {t("forms.csqueeze.5.toContinue")}
                              </Box>
                            )}
                          </Box>
                        </Box>
                      </CardActions>
                    </Card>
                  </React.Fragment>
                )}

                {layers[tabKey].classification && (
                  <React.Fragment>
                    <Card style={{ padding: 10 }}>
                      <CardContent style={{ padding: 0 }}>
                        <Typography variant="subtitle2" align="center">
                          {t("forms.csqueeze.5.labels")}
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Box
                          width="100%"
                          display="grid"
                          gridTemplateColumns="auto auto"
                          style={{
                            gap: 5,
                            "&:lastChild": { gridColumn: "1 / 3" },
                          }}
                          fontSize="small"
                          fontWeight={300}
                        >
                          {landCovers
                            .filter(land => land.value !== 5)
                            .map((land, i) => (
                              <Box
                                key={i}
                                display="flex"
                                alignItems="center"
                                style={{
                                  position: "relative",
                                  gridColumn: land.value === 6 ? "1 / 3" : "unset",
                                }}
                              >
                                <FiberManualRecordIcon style={{ color: land.color }} />
                                {land.label}
                                <Box fontSize={9} marginLeft={0.5}>
                                  (cod {land.value})
                                </Box>
                              </Box>
                            ))
                          }
                        </Box>
                      </CardActions>
                    </Card>
                    <Card style={{ padding: 10 }}>
                      <CardContent style={{ padding: 0 }}>
                        <Typography variant="subtitle2" align="center">
                          {t("forms.csqueeze.5.confirmRaster")} {tabKey}
                        </Typography>
                      </CardContent>
                      <CardActions
                        display="flex"
                        style={{ flexDirection: "column" }}
                      >
                        <Box display="block" align="center" fontSize={12}>
                          {t("forms.csqueeze.5.confirmRasterText")}
                        </Box>
                        <Button
                          style={{ marginTop: 10 }}
                          color="secondary"
                          variant="outlined"
                          onClick={() =>
                            handleGenerateClassificationDiscard(
                              markedPoints.current[tabKey]
                            )
                          }
                        >
                          {t("forms.csqueeze.5.editPolygons")}
                        </Button>
                      </CardActions>
                    </Card>{" "}
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </Grid>
          <div
            id="google-maps"
            ref={(r) => (mapDiv.current = r)}
            style={{ height: "100%" }}
          ></div>
          <Box
            id="falseColorButton"
            style={{
              width: "fit-content",
              height: 40,
              position: "absolute",
              top: 12,
              right: 60,
              backgroundColor: "white",
              boxShadow: "rgb(0 0 0 / 30%) 0px 1px 4px -1px",
              borderRadius: 2,
              padding: "0 10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography>{t("forms.csqueeze.5.falseColor")}</Typography>
            <Checkbox
              checked={falseColor}
              onClick={handleFalseColor}
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </Box>
          <IconButton
            onClick={handleFullScreen}
            style={{
              width: 40,
              height: 40,
              position: "absolute",
              top: 12,
              right: 10,
              backgroundColor: "white",
              boxShadow: "rgb(0 0 0 / 30%) 0px 1px 4px -1px",
              borderRadius: 2,
            }}
          >
            <Fullscreen fontSize="large" />
          </IconButton>
        </div>
        <Typography
          variant="body2"
          style={{ marginTop: 10, marginBottom: 10, textAlign: "center" }}
        >
          {t("forms.csqueeze.5.nextStepAlert")}
        </Typography>

        <StepperButtons
          navigate={navigate}
          onNext={handleFinish}
          nextTarget={FINALIZE}
          nextDisabled={nextDisabled}
        />

        <TourGuider
          steps={steps}
          isOpen={isTourOpen}
          setIsTourOpen={setIsTourOpen}
        />
      </Box>
    </React.Fragment>
  );
};

export default LandCover;
