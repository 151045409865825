import React, { useState } from "react";
import moment from "moment";
import ColorConvert from "color-convert";
import { Slider, Tooltip } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { formatDate } from "../../../common/utils";
import { Box } from "@material-ui/core";

const toTimestamp = (date) => parseInt(moment(date).format("x"), 10);
const fromTimestamp = (timestamp) => moment(timestamp).toISOString();

// useStyles is a hook for styling this component with Material UI's styling solution
const useStyles = makeStyles((theme) => {
  const { primary, secondary } = theme.palette;

  const secRgb = ColorConvert.hex.rgb(secondary.main);

  return {
    wrapper: {
      width: "80%",
    },
    labels: {
      marginTop: 4,
      marginBottom: 30,
      justifyContent: "space-between",
    },
    container: {
      position: "relative",
    },
    label: {
      position: "absolute",
      top: 18,
    },
    sliderRail: {
      height: 6,
    },
    sliderMark: {
      height: 13,
      width: 4,
      backgroundColor: `rgba(${secRgb[0]}, ${secRgb[1]}, ${secRgb[2]}, 0.5)`,
      bottom: 6,
    },
    sliderThumb: {
      borderColor: primary.main,
      backgroundColor: primary.main,
      width: 22,
      height: 22,
      marginTop: -8,
      marginLeft: -11,
    },
    sliderTooltip: {
      zIndex: 8,
    },
  };
});

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#009688",
    color: "white",
    borderRadius: "30px",
    fontWeight: "bolder",
    fontSize: "large",
    padding: "5px 10px;",
    zIndex: 10,
  },
}))(Tooltip);

// main export
const TimePeriodSelector = ({
  dates = [],
  onChange = () => {},
  onDoubleClick = () => {},
}) => {
  const [value, setValue] = useState(0);
  const [lastClickTime, setLastClickTime] = useState(0);
  const [clickCount, setClickCount] = useState(0);

  const classes = useStyles();

  const timestamps = dates.map(toTimestamp);

  function ValueLabelComponent(props) {
    const { children, open, value } = props;

    return (
      <HtmlTooltip
        open={open}
        placement="top"
        title={formatDate(fromTimestamp(timestamps[value]))}
        PopperProps={{style:{zIndex:0}}}
      >
        {children}
      </HtmlTooltip>
    );
  }

  const handleChange = (event, newValue) => {
    console.log(newValue);
    setValue(newValue);
    onChange(
      fromTimestamp(timestamps[newValue]),
      fromTimestamp(timestamps[newValue])
    );
  };

  const handleMouseDown = () => {
    const currentTime = new Date().getTime();
    const timeDifference = currentTime - lastClickTime;

    if (timeDifference < 300) {
      setClickCount(clickCount + 1);
      if (clickCount === 1) {
        onDoubleClick();
        setClickCount(0);
      }
    } else {
      setClickCount(1);
    }
    setLastClickTime(currentTime);
  };

  return (
    <Box className={classes.wrapper} id="timeselector">
      <Slider
        value={value}
        valueLabelDisplay="on"
        ValueLabelComponent={ValueLabelComponent}
        track={false}
        step={1}
        min={0}
        max={timestamps.length - 1}
        marks
        onChangeCommitted={handleChange}
        classes={{
          mark: classes.sliderMark,
          rail: classes.sliderRail,
          thumb: classes.sliderThumb,
        }}
        onMouseDown={handleMouseDown}
        style={{ width: '100%' }}
      />
    </Box>
  );
};

export default TimePeriodSelector;
