import React, { useEffect, useState } from "react"; 
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ee from "../../../services/earth-engine";
import { Box, Button, Slider } from "@material-ui/core";
import GoogleMap from "../map/GoogleMap";
import StepperButtons from "../acquisition/StepperButtons";
import * as MapGoogle from "../../../common/map";
import { Actions as Acquisition } from "../../../store/ducks/acquisition";
import TourGuider from "../tour/TourGuider";
import { useLocalStorage } from "../../../common/utils";
import HelpButton from "../core/HelpButton";
import {acquireFromDate as acquireFromDateSentinel, maskS2Clouds, maskS2ImageMask} from "../../../algorithms/satellite/sentinel";
import {acquireFromDate as acquireFromDateLandSat, maskLandsatImageMask, maskLandsatCloudsRatio} from "../../../algorithms/satellite/landsat";
import Dygraph from 'dygraphs'; 
//export shapefile dependencies
import Export from "../../../services/export";
import { INTERNALS } from "../../../common/metadata";
import { omit } from "lodash";

export class OverlayHelper{
  static overlayCache = [];
  static imgDownloadURL = [];
  static urlImagMapLayerDownload = "";
  static vectorsToExport = null;
  static imgsToStep5 = [];
}

const IntertidalZoneDefiner = ({ navigate, ...extra }) => {
  const dispatch = useDispatch();
  
  // get the current language
  const [t] = useTranslation();
  const [overlay, setOverlay] = useState(null);
  const [coordinates, setCoordinates] = useState(null);
  const [manualThreshold, setManualThreshold] = useState(-1);
  const [layers, setLayers] = useState({});
  const dates = useSelector((state) =>state.acquisition.availableDates);

  let toCalculate = window.sessionStorage.getItem("toCalculate");
  toCalculate =  toCalculate !== "0";
  let coordinatesArray =  JSON.parse(window.localStorage.getItem("AOIIntertidal"));
  let geometry = ee.Geometry.Polygon(coordinatesArray); 
  let mission = JSON.parse(window.sessionStorage.getItem("selectedMission"));

  let saveZoomChange = function () {
      let zoom=MapGoogle.getZoomLevel();
      if(typeof(zoom)!=="undefined" && zoom !=="undefined"){
        window.sessionStorage.setItem("zoom_level",zoom);
      }
   };
   window.sessionStorage.removeItem("zoom_level"); //clear previous saved zoom (aquisition -first use case)
   setInterval(saveZoomChange, 5000); 
  

let images = [];
dates.forEach(el =>{
  let date = el.date;
  let mosaicImage =  acquireFromDateSentinel(date, mission, geometry);
  images.push(mosaicImage);  /*ee.Image(el.id) - imagem vem cortada*/ 
});

   /////////// PARA BABITONGA
/*  
var img0 = ee.Image('COPERNICUS/S2_SR_HARMONIZED/20230320T132239_20230320T132815_T22JGS');
var img1 = ee.Image('COPERNICUS/S2_SR_HARMONIZED/20230330T132239_20230330T132858_T22JGS');
var img2 = ee.Image('COPERNICUS/S2_SR_HARMONIZED/20230404T132231_20230404T132232_T22JGS');
var img3 = ee.Image('COPERNICUS/S2_SR_HARMONIZED/20230419T132239_20230419T132235_T22JGS');
var img4 = ee.Image('COPERNICUS/S2_SR_HARMONIZED/20230514T132231_20230514T132233_T22JGS');
var img5 = ee.Image('COPERNICUS/S2_SR_HARMONIZED/20230519T132239_20230519T132237_T22JGS');
var img6 = ee.Image('COPERNICUS/S2_SR_HARMONIZED/20230603T132241_20230603T132236_T22JGS');
var img7 = ee.Image('COPERNICUS/S2_SR_HARMONIZED/20230608T132239_20230608T132237_T22JGS');
var img8 = ee.Image('COPERNICUS/S2_SR_HARMONIZED/20230628T132239_20230628T132237_T22JGS');
var img9 = ee.Image('COPERNICUS/S2_SR_HARMONIZED/20230713T132241_20230713T132606_T22JGS');
var img10 = ee.Image('COPERNICUS/S2_SR_HARMONIZED/20230807T132239_20230807T132237_T22JGS');
images = [img0,img1, img2, img3, img4, img5, img6, img7,  img8, img9, img10];
*/
let markedPoints = {};
let mapRef = window.initMap;//useRef();
let visibleMap = document.getElementById("google-maps");
mapRef.current= mapRef; 

 
let geometryThumb = JSON.parse(window.localStorage.getItem("AOIIntertidal")); 
const formatedCoordinates = geometryThumb.map((coord) => {
  return { lat: coord[1], lng: coord[0] };
});
let AOI = new window.google.maps.Polygon({
  path: formatedCoordinates,
  geodesic: true,
  fillColor: "transparent",
  strokeColor: "#00A391",
  strokeOpacity: 1,
  strokeWeight: 1,
  clicable: false,
});
AOI.setMap(mapRef.current);

var bounds = new window.google.maps.LatLngBounds();
bounds.current = new window.google.maps.LatLngBounds();

AOI.current = AOI;
AOI.current
  .getPath()
  .getArray()
  .forEach((coord) => {
    let latLng = new window.google.maps.LatLng(coord.lat(), coord.lng());
    bounds.current.extend(latLng);
  });
const center = bounds.current.getCenter(); 
mapRef.current.setCenter(center);
mapRef.current.panBy(1000, 0);

geometryThumb = ee.Geometry.Polygon(geometryThumb);

let thumbnailParams ={region: geometryThumb,bands:[mission.bands.red,mission.bands.green,mission.bands.blue],dimensions: 512,max:mission.vizParams.max, min:mission.vizParams.min, format: 'jpg'};       

//new code to add layers
useEffect(() => {      
        let values = Object.values(images); 
        //hide existing layers
        OverlayHelper.overlayCache.forEach(el => {
          el.setMap(null);
        });
        if (toCalculate && values.length > 0 ){//create new layers in case it is the first time or there is new calculations
            values = values.map((value) =>{ 
            let thumbnailURL = value.getThumbURL(thumbnailParams);
            return {
                    date: "intertidal", 
                    falseColorUrl: thumbnailURL,
                    imageObject: value, 
                    missionName: "COPERNICUS/S2_SR_HARMONIZED",
                    name: "intertidal",
                    url: thumbnailURL               
                  };
            });
          
          values.forEach((image, i) => {
              var overlay = new window.google.maps.GroundOverlay(
                image.url,
                bounds.current
              );
              overlay.setMap(mapRef.current);  

              OverlayHelper.overlayCache.push(overlay);
              
              setLayers({
                ...layers, 
                [image.date]: { ...image, overlay },
              });
              
              markedPoints.current = { [image.date]: [] };
            });
          window.layers = layers;
        }else{ //layers already calculated: just rerender
          window.layers = layers;
          if(layers !== undefined && layers["intertidal"] !== undefined ){
            let layer = layers["intertidal"]; //first calculated layer
            if (window.layersCalculated !== undefined){            
                window.layersCalculated.forEach( el=>{
                  el.setMap(mapRef.current); 
                }); 
            }else{
              layer.overlay.setMap(mapRef.current); 
            }
          }
        }
        mapRef.current.setCenter(center);
    }  , [images]);
  
  //end: new code to add layers

  function getCoordinates(shape) {
    let coordinates = [];
    if (shape.type === "rectangle") {
      const bounds = shape.overlay.getBounds();
      const ne = bounds.getNorthEast();
      const sw = bounds.getSouthWest();
      coordinates = [
        [ne.lng(), ne.lat()],
        [ne.lng(), sw.lat()],
        [sw.lng(), sw.lat()],
        [sw.lng(), ne.lat()],
        [ne.lng(), ne.lat()],
      ];
    }

    if (shape.type === "polygon") {
      const path = shape.overlay.getPath();
      const array = path.getArray();
      coordinates = array.map((p) => [p.lng(), p.lat()]);
    }

    return coordinates;
  }
  
  // handle drawing the AOI on the map
  const handleDrawing = (overlay, coordinates) => {
    setOverlay(overlay);
    setCoordinates(coordinates);
    window.localStorage.setItem("AOIIntertidal",JSON.stringify(coordinates));
    // disable the drawing controls, when the AOI is drawn
    MapGoogle.setDrawingControlsVisible(false);
  };

  // handle the undoing of the AOI
  const handleUndo = () => {
    showThresholdDialog();
  };

  // handle the saving of the AOI
  const handleChoose = () => {
    try{
      /*
        dispatch(

          Acquisition.setAOI(
            overlay,
            coordinates,
            ee.Geometry.Polygon([coordinates])
          )
        );
        */
    }catch(e){
      window.alert("Your account still is not allowed to use GEE API. You should complete your register using this link: https://earthengine.google.com/signup/");
      window.location = "https://earthengine.google.com/signup/";
    } 
  };


  // defines the steps for the tour
  const steps = [
    {
      selector: "#areachooser",
      content: t("tour.csqueeze.2.aoi"),
    },
    {
      selector: "#mangrove",
      content: t("tour.csqueeze.2.mangrove"),
    },
    {
      selector: "#importKml",
      content: t("tour.csqueeze.2.importKml"),
    },
  ];

  // create a localStorage object to check if the user has already seen the tour
  const [isTourOpen, setIsTourOpen] = useLocalStorage("showROITour", true);

//init procedure 
let previousCalculatedDatabar =  window.sessionStorage.getItem("dataBar");
var dataBar = previousCalculatedDatabar === null ? [] :  JSON.parse(previousCalculatedDatabar);
var thresholdJSNum = 0; 
//null: first time the screen is opened
//0: it has already calculated and do not need a new calculation
//1: needs to recalculate
if(toCalculate){
  let eeImageList = dates.map(el => ee.Image(el.id));
  //let imageCollection = ee.ImageCollection.fromImages(eeImageList);
  // Create an image collection from the images
  let  imageCollection = ee.ImageCollection.fromImages(images); //[img0,img1, img2, img3, img4, img5, img6, img7, img8, img9, img10]  

  // Mask out land and clouds
  function mask(image) { 
    let imgMasked =  maskS2ImageMask(image, mission); 
    let ratio = maskS2Clouds(imgMasked, geometry, mission.bands.qa, mission.opticalResolution );
    return imgMasked.set("CLOUDS", ratio);
  }

  var Bay_Plenty_collection = imageCollection;
  Bay_Plenty_collection = Bay_Plenty_collection.map(mask);
  function reflec_corr (image){
    var opticalBands = image.select('B.*').multiply(0.0001); //applying the scale factor of Sentinel-2 collection
      return image
      .addBands(opticalBands, null, true);
    }

  var Bay_Plenty_collection = Bay_Plenty_collection.map(reflec_corr);

  var vis_rgb = {
    max: 0.25,
    bands: ['B4', 'B3', 'B2'], // to visualize in true color
    };

  var singleBandVis = {
                'min': -0.5,
                'max': 1,
                };

  ///// PANSHARPENING
  var geeSharp = require('./../../../common/geeSharp/sharpeners'); // Import the geeSharp module
  var entry = require('./../../../common/geeSharp/entry'); 
  // After analysing the charts, choose the band that showed the bigest r^2
  function sharpened (image) {
    var sharp1 = entry.sharpen(image.select(['B11']), image.select(['B8'])).rename ('B11_sharp');
    var sharp2 = entry.sharpen(image.select(['B12']), image.select(['B8'])).rename ('B12_sharp');
    
    return image.addBands([sharp1,sharp2])
  }

  var Bay_Plenty_collection = Bay_Plenty_collection.map(sharpened);
  //// NDWI e outros indices
  var NDWIindex = function(image) {
  var ndwi = image.normalizedDifference(['B3', 'B8']).rename ('ndwi'); //Mc Feeters, 1996
  var mndwi_sharp = image.normalizedDifference(['B3', 'B11_sharp']).rename ('mndwi_sharp');
  var mndwi = image.normalizedDifference(['B3', 'B11']).rename ('mndwi');
  return image.addBands([ndwi, mndwi_sharp,mndwi]);
  };

  var AWEIindex = function(image) {
    var awei = image.expression('(B+ (2.5*G) -1.5*(N+S1) -(0.25*S2)) ',{ //Feyisa etal, 2014 (4 * (G - S)) - (0.25 * N + 2.75 * S)
    B: image.select('B2'),
    G: image.select('B3'), 
    S1: image.select('B11_sharp'), 
    S2: image.select('B12_sharp'),
    N: image.select('B8'),
    }).rename('awei');
    return image.addBands([awei]);
  };


  var clip_image = function (image){
    return image.clip(geometry)
  }; // corta para a área de interesse
    
  var mask_land = function (image){
    var ndwi = image.select('mndwi_sharp');
    return image.updateMask(ndwi.gte(0.2)) 
  }; // deixa apenas valores maiores ou iguais a 0.2 //-0,2 para macromaré


  ///// APLICANDO AS FUNÇÕES E CORTANDO NA GEOMETRIA
  var NWI = Bay_Plenty_collection.map(NDWIindex);// calcula o NDWI;  
  var NWINoMask=NWI.map(clip_image); // Faz o corte
 
  //// MÁSCARA DE ÁGUA/TERRA
  var NWI=NWINoMask.map(mask_land);  //// aplica máscara a aprtir do valor do histograma

  //// APLICAÇÃO AWEI
  var awei = NWI.map(AWEIindex);
  var aweiVis = {
    'max': 0.2,
    'min': -0.2,
  };
 
  var NWI_STD = awei.select('awei').reduce(ee.Reducer.stdDev()); // Agora só tem uma imagem que mostra o STD dos NDWI
  // UTILIZANDO A METODOLOGIA DE THRESHOLD OTSU:
  var histogram = NWI_STD.reduceRegion({
    reducer: ee.Reducer.histogram(),
    geometry: geometry, 
    scale: 10
  }); 
  var histogramData = histogram.getInfo();
  var AWEI_stdDev = histogram.get('awei_stdDev');

  var x = histogramData.awei_stdDev.bucketMeans.map(function (el){ return el});
  var y = histogramData.awei_stdDev.histogram.map(function (el){ return el});
  for(var i = 0; i < x.length; i++){
    dataBar.push([x[i], y[i]]);
  }

  var otsu = function(histogram) {
    var counts = ee.Array(ee.Dictionary(AWEI_stdDev).get('histogram'));
    var means = ee.Array(ee.Dictionary(AWEI_stdDev).get('bucketMeans'));
    var size = means.length().get([0]);
    var total = counts.reduce(ee.Reducer.sum(), [0]).get([0]);
    var sum = means.multiply(counts).reduce(ee.Reducer.sum(), [0]).get([0]);
    var mean = sum.divide(total);

    var indices = ee.List.sequence(1, size);
    // Compute between sum of squares, where each mean partitions the data.
    var bss = indices.map(function(i) {
      var aCounts = counts.slice(0, 0, i);
      var aCount = aCounts.reduce(ee.Reducer.sum(), [0]).get([0]);
      var aMeans = means.slice(0, 0, i);
      var aMean = aMeans.multiply(aCounts)
          .reduce(ee.Reducer.sum(), [0]).get([0])
          .divide(aCount);
      var bCount = total.subtract(aCount);
      var bMean = sum.subtract(aCount.multiply(aMean)).divide(bCount);
      return aCount.multiply(aMean.subtract(mean).pow(2)).add(
            bCount.multiply(bMean.subtract(mean).pow(2)));
    });

    // Return the mean value corresponding to the maximum BSS.
    return means.sort(bss).get([-1]);
  };
  var threshold = null;

  if (manualThreshold === -1){
    threshold = otsu(histogram.get('histogram'));
  }else{
    threshold = ee.Number(Number.parseFloat(manualThreshold));
  }

  thresholdJSNum = threshold.getInfo();
  window.sessionStorage.setItem("thresholdIntertidal", thresholdJSNum );

  ///// Máscara a partir do histograma do desvio pasdrão
  // valores maiores que X*STD (retirar X do histograma do NWI_STD)
  var stdMasked = NWI_STD.updateMask(NWI_STD.gte(threshold)); //EQ("="), GTE(">="), GT(">"), LT("<"), LTE("<="); // Threshold para Barra do Sul: 0.05
  var zones=NWI_STD.gte(threshold); //threshold is mean value from the histogram
  var zones = zones.updateMask(zones.neq(0));
  // Visualizar histograma pós corte
  var hist_intermare = stdMasked.reduceRegion({
    reducer: ee.Reducer.histogram(),
    geometry: geometry, 
    scale: 10
  });

  //Transformar para vetores
  var vectors = zones.addBands(NWI_STD).reduceToVectors({ 
    crs: 'EPSG:4326',
    //crsTransform: [60, 0, 399960, 0, -60, 5900020],
    scale: 10,
    geometryType:'polygon',
    labelProperty: 'stdMasked', 
    eightConnected: false,
    geometry: geometry,
    maxPixels: 100e9,
    geometryInNativeProjection: true,
    reducer: ee.Reducer.mean()
  });
  OverlayHelper.vectorsToExport = vectors;
  //Função para cortar de acordo com os vetores
  var clip_image2 = function(image){
    return image.clip(vectors);
  };

  //var NWI2 = Bay_Plenty_collection.map(AWEIindex).select('awei'); //para exportar img com AWEI
  var NWI2 = Bay_Plenty_collection.map(NDWIindex);
  var intertidal_zones = NWI2.map(clip_image2);

  // Displaying
  var vis_params = {
    'min': -0.5,
    'max': 1,
    'region': geometry,
    'dimensions': 512,
    'palette':['white','blue'],     
    /*format: 'jpg'*/    
    };
    
  window.layersCalculated = [];
  OverlayHelper.imgDownloadURL = []
  var n = intertidal_zones.getInfo().features.length;
  var list = intertidal_zones.toList(n,0);
  var i = 0;
  OverlayHelper.imgsToStep5 = []; 
  for(i=0;i<n;i++){
    var img = ee.Image(list.get(i));
    let imgMapLayer = img.select('ndwi');
    OverlayHelper.imgsToStep5.push(img);
    let url = imgMapLayer.getThumbURL(vis_params);  /*vis_rgb*/; 
    let fileNamePrefix = typeof dates[i].date === "string" ? dates[i].date.substring(0,10) : i.toString();
    OverlayHelper.urlImagMapLayerDownload = imgMapLayer.getDownloadURL({
      region: geometry,
      name: fileNamePrefix,
      scale: mission.opticalResolution,
    })  
    let overlayIntertidalZone = new window.google.maps.GroundOverlay(
    url,
    bounds.current 
    ); 
    overlayIntertidalZone.setMap(mapRef.current); 
    window.layersCalculated.push(overlayIntertidalZone);
    OverlayHelper.overlayCache.push(overlayIntertidalZone); 

    //get download URL
    OverlayHelper.imgDownloadURL.push( 
      img.getDownloadURL({
        region: geometry,
        scale: mission.opticalResolution,
        name: fileNamePrefix ,
      }) 
    );

  };
  mapRef.current.setCenter(center);
  window.sessionStorage.setItem("toCalculate","0");
}else{
  console.log("Skip calculations");
}
setTimeout( () =>{
  let dialogLoading = document.getElementById("dialog_loading");
  if(dialogLoading !== null) dialogLoading.close();
}, 1000);

//start: import crosshair plugin
Dygraph.Plugins.Crosshair = (function() {
  "use strict";
  var crosshair = function(opt_options) {
    this.canvas_ = document.createElement("canvas");
    opt_options = opt_options || {};
    this.direction_ = opt_options.direction || null;
  };

  crosshair.prototype.toString = function() {
    return "Crosshair Plugin";
  };
  crosshair.prototype.activate = function(g) {
    g.graphDiv.appendChild(this.canvas_);

    return {
      select: this.select,
      deselect: this.deselect
    };
  };

  crosshair.prototype.select = function(e) {
    if (this.direction_ === null) {
      return;
    }
    var width = e.dygraph.width_;
    var height = e.dygraph.height_;
    this.canvas_.width = width;
    this.canvas_.height = height;
    this.canvas_.style.width = width + "px";    // for IE
    this.canvas_.style.height = height + "px";  // for IE
    var ctx = this.canvas_.getContext("2d");
    ctx.clearRect(0, 0, width, height);
    ctx.strokeStyle = "rgba(0, 0, 0,0.3)";
    ctx.beginPath();
    var canvasx = Math.floor(e.dygraph.selPoints_[0].canvasx) + 0.5; // crisper rendering
    if (this.direction_ === "vertical" || this.direction_ === "both") {
      ctx.moveTo(canvasx, 0);
      ctx.lineTo(canvasx, height);
    }
    if (this.direction_ === "horizontal" || this.direction_ === "both") {
      for (var i = 0; i < e.dygraph.selPoints_.length; i++) {
        var canvasy = Math.floor(e.dygraph.selPoints_[i].canvasy) + 0.5; // crisper rendering
        ctx.moveTo(0, canvasy);
        ctx.lineTo(width, canvasy);
      }
    }
    ctx.stroke();
    ctx.closePath();
  };

  crosshair.prototype.deselect = function(e) {
    var ctx = this.canvas_.getContext("2d");
    ctx.clearRect(0, 0, this.canvas_.width, this.canvas_.height);
  };

  crosshair.prototype.destroy = function() {
    this.canvas_ = null;
  };

  return crosshair;
})();
//end: import crosshair plugin
  
//start: histogram graph parameters
dataBar =  dataBar.map(el=> {
  el[0] = Number.parseFloat(el[0].toFixed(4));
  return el;
});  
window.sessionStorage.setItem("dataBar", JSON.stringify(dataBar));
thresholdJSNum = Number.parseFloat(thresholdJSNum.toFixed(4));
const labels = dataBar.map(el=> el[0]);
const minRange = Math.min(...labels );
const maxRange = Math.max(...labels );
const [rangeValue, setRangeValue]  = useState(thresholdJSNum);//minRange
const handleChange = function(event) {
  this.setState({rangeValue: event.target.value}); 
};


//end procedure
const showThresholdDialog = function (){
  let dialog = document.getElementById("change_threshold");
  document.getElementById("threshold").value =   window.sessionStorage.getItem("thresholdIntertidal"); 
  dialog.showModal();

  //start: histogram graph parameters
  setTimeout( ()=>{
    //https://dygraphs.com/gallery/#g/linear-regression
    const g = new Dygraph(
      document.getElementById('graph'),
      dataBar,
                {
                    labels: ["Bin", "Frequency"],
                    plotter: barPlotter,
                    
                    plugins: [
                      new Dygraph.Plugins.Crosshair({
                        direction: "vertical"
                      }) 
                    ],

                    pointClickCallback: function(e, pt) {
                      setRangeValue(pt.xval);
                  },
                color: "orange",    //Accepts CSS colour values.
                //There are other properties you can set too:
                strokeWidth: 1,
                drawPoints: true,
                pointSize: 15,
                highlightCircleSize: 3
                      
                }, {
        fillGraph: false
      });
  }, 2000);
  //end: histogram graph parameters

}

const closeThresholdDialog = function (){
  let dialog = document.getElementById("change_threshold");
  dialog.close();
}


const redoThreshold = function (){
  const threshold = document.getElementById("threshold").value;
  window.sessionStorage.setItem("toCalculate","1");
  closeThresholdDialog();
  document.getElementById("dialog_loading").showModal();
  setTimeout( ()=> {
    setManualThreshold(threshold); //update screen
  }, 1000);
}

// Custom bar plotter function
function barPlotter(e) {
      var ctx = e.drawingContext;
      var points = e.points;
      var y_bottom = e.dygraph.toDomYCoord(0);
      ctx.fillStyle = e.color;
      // Set the width of the bars
      var barWidth = 0.5 * (points[1].canvasx - points[0].canvasx);
      for (var i = 0; i < points.length; i++) {
          var p = points[i];
          var center_x = p.canvasx;
          ctx.fillRect(center_x - barWidth / 2, p.canvasy, barWidth, y_bottom - p.canvasy);
          ctx.strokeRect(center_x - barWidth / 2, p.canvasy, barWidth, y_bottom - p.canvasy);
      }
  }

  return (
    <React.Fragment>
      <dialog id="change_threshold" style={{width:"80%", height:600}}>
        <div>
          <div>
            <div id="graph" style={{width: "100%", height: 320}}></div>
             <br />
               <div style={{width:"100%",textAlign:"right"}}>
                  <div style={{width:"94%", display:"inline-block"}}>
                    <Slider
                            key={1}
                            value={rangeValue}
                            step={0.0005}
                            min={minRange}
                            max={maxRange}
                            onChange={(_, value) => {
                              setRangeValue(value);
                            }}
                              
                          />
                  </div>
                </div>
                  <div style={{width:"100%"}}>
                      <div style={{float:"left"}}>{minRange}</div>
                      <div style={{float:"right"}}>{maxRange}</div>
                  </div>
                  <p align="center">
                        {rangeValue}
                  </p>
                
           </div>   
        </div>
        <input type="text" id="threshold" value={rangeValue} style={{display:"none"}} /><br/>
        <p align="center">
          
           
          <Button
              onClick={closeThresholdDialog}
              color="secondary"
              variant="contained"
              style={{
                cursor: "pointer",
                padding: "30",
                margin: 10
              }}
            >
              CANCEL
            </Button>
            &nbsp;
            <Button
              onClick={redoThreshold}
              color="primary"
              variant="contained"
              style={{
                cursor: "pointer",
                padding: "30",
                margin: 10
              }}
            >
              {t("forms.intertidal.next")}
            </Button>
        </p>
      </dialog>

      <Box>

        <HelpButton
          onClickFunction={() => {
            setIsTourOpen(true);
          }}
        />
        <GoogleMap
          style={{ width: "100%", height: 500 } /* @TODO pass as props */}
          onRegionDrawn={handleDrawing}
        />

        <br />
        

      <dialog id="dialog_loading">
        {t("general.loading")}
      </dialog>

      <div style={{textAlign:"center"}}>
          <br /> <br />
          <Button
            color="primary"
            variant="outlined"
            style={{display:"inline"}}
            onClick = {
              () => {
                Export.table.toDevice.asShapefileGroup(  
                 [

                  {
                    features: OverlayHelper.vectorsToExport.getInfo().features.map((intertidalArea) => ({
                      ...intertidalArea,
                      properties: omit(intertidalArea.properties, [INTERNALS]),
                    })),
                  },
                 ],
                 Export.defaultOptions.device.shapefileGroup(
                  "output",
                  "vectors"
                 )
               ); 
             }

            }
          >
            Download Vectors (shapefile/zip)
          </Button>
          &nbsp;&nbsp;

          <Button
            color="primary"
            variant="outlined"
            style={{display:"inline"}}
            onClick = {()=>{
              OverlayHelper.imgDownloadURL.forEach(url =>{
                window.open(url);
              })
            }}
          >
            {t("forms.acquisition.3.bathymetry.downloadImage")} - Satellite Images (ZIP)
          </Button>
          &nbsp;&nbsp;
          <Button
            color="primary"
            variant="outlined"
            style={{display:"inline"}}
            onClick = {()=>{
                window.open(OverlayHelper.urlImagMapLayerDownload);
            }}
          >
            {t("forms.acquisition.3.bathymetry.downloadImage")} - Map layer (ZIP)
          </Button> 
          <br /> <br />
        </div>

        <StepperButtons
          navigate={navigate}
          onNext={handleChoose}
        >
          <Button
            onClick={handleUndo}
            color="secondary"
            variant="contained"
          >
            {t("forms.intertidal.4.returnAndRedo")}
          </Button>

        </StepperButtons>
      
        {
          // if the user hasn't seen the tour, show it
        }
        <TourGuider
          steps={steps}
          isOpen={isTourOpen}
          setIsTourOpen={setIsTourOpen}
        />
      </Box>
    </React.Fragment>
  );
};

export default IntertidalZoneDefiner;