import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { loadScript } from "../../../services/dynamic-script";
import {
  Box,
  Card,
  Grid,
  Grow,
  IconButton,
  Slider,
  Button,
  Paper,
  Typography,
  Radio,
  TextField,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle
} from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import TimelineIcon from "@material-ui/icons/Timeline";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import DoneIcon from "@material-ui/icons/Done";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import GetAppIcon from "@material-ui/icons/GetApp";
import GestureIcon from "@material-ui/icons/Gesture";

import { useTranslation } from "react-i18next";

import { Actions as CSqueeze } from "../../../store/ducks/csqueeze";
import {
  landCoversIntersectionsTurf,
  extractShoreLineTurf,
} from "../../../algorithms/csqueeze";

import * as turf from "@turf/turf";
import TransectResult from "../../components/csqueeze/_TransectResult";
import Export from "../../../services/export";

const timer = (ms) => new Promise((res) => setTimeout(res, ms));

// useStyles is a hook for Material-UI's styling.
const useStyles = makeStyles((theme) => ({
  wrapper: {
    maxHeight: "100vh",
    flexGrow: 1,
    position: "relative",
  },
  mapContainer: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexFlow: "column",
    paddingTop: 10,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 10,
    gap: 10,
  },
  mapOverlay: {
    display: "grid",
    width: "100%",
    position: "absolute",
    top: 0,
    bottom: 0,
    zIndex: 10,
    gridTemplateColumns: "430px 280px",
    pointerEvents: "none",
    "& > *": {
      pointerEvents: "none",
      "& > *": {
        pointerEvents: "auto",
      },
    },
  },
  margin: {
    margin: "5px",
  },
  right: {
    textAlign: "right",
  },
  card: {
    height: 'fit-content !important',
    overflow: 'unset !important',
    "& .MuiCard-root": {
      overflow: 'unset !important',
    }
  },
}));

// main function
const ProcessingPage = () => {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const classes = useStyles();

  const mapDiv = useRef();
  const mapRef = useRef();
  const drawingManager = useRef();
  const AOI = useRef();
  const bounds = useRef();
  const transectMarker = useRef();

  const [images] = useSelector((state) => state.csqueeze.studyData);
  const classLandCoversVectors = useSelector(
      (state) => state.csqueeze.classLandCoversVectors
  );
  const waterLandCoverVectors = useSelector(
      (state) => state.csqueeze.waterLandCoverVectors
  );

  const coordinates = useSelector((state) => state.acquisition.coordinates);

  const [isDrawing, setIsDrawing] = useState(false);
  const [confirmDrawing, setConfirmDrawing] = useState(false);
  const [isSettings, setIsSettings] = useState(false);
  const [finished, setFinished] = useState(false);
  const [baseLine, setBaseLine] = useState(false);

  const [drawingDisabled, setDrawingDisabled] = useState(false);
  const [settingsDisabled, setSettingsDisabled] = useState(false);
  const [analysisDisabled, setAnalysisDisabled] = useState(false);

  const [transects, setTransects] = useState(5);
  const [transectsScale, setTransectsScale] = useState(100);
  const [transectsData, setTransectsData] = useState([]);
  const [transectsResults, setTransectsResults] = useState(false);
  const [transectsObjects, setTransectsObjects] = useState([]);
  const [transectsCsv, setTransectsCsv] = useState("");
  const [transectsCalcs, setTransectsCalcs] = useState({});

  const [selectedTransect, setSelectedTransect] = useState(1);

  const [previsao, setPrevisao] = useState(10);

  const [years, setYears] = useState([]);

  const [opacity, setOpacity] = useState({
    0: { overlay: 0, raster: 1 },
    1: { overlay: 0, raster: 0 },
    2: { transects: true, baseline: true },
  });

  const [calcMessage, setCalcMessage] = useState(
      t("forms.csqueeze.result.loading.preparing")
  );

  const [showLegendCard, setShowLegendCard] = useState(false);

  useEffect(() => {
    loadScript(
        "google-charts",
        "https://www.gstatic.com/charts/loader.js",
        () => {
          window.google.charts.load("current", {
            packages: ["corechart", "bar", "table"],
          });
        }
    );

    mapRef.current = new window.google.maps.Map(mapDiv.current, {
      zoom: 14,
      scaleControl: true,
      streetViewControl: false,
      mapTypeControl: true,
      mapTypeControlOptions: {
        style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: window.google.maps.ControlPosition.TOP_CENTER,
      },
      fullscreenControl: false,
      styles: [
        {
          featureType: "poi",
          elementType: "labels",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
      ],
    });

    const formatedCoordinates = coordinates.map((coord) => {
      return { lat: coord[1], lng: coord[0] };
    });

    AOI.current = new window.google.maps.Polygon({
      path: formatedCoordinates,
      geodesic: true,
      fillColor: "transparent",
      strokeColor: "#00A391",
      strokeOpacity: 0.6,
      strokeWeight: 5,
      clicable: false,
    });

    AOI.current.setMap(mapRef.current);

    bounds.current = new window.google.maps.LatLngBounds();
    AOI.current
        .getPath()
        .getArray()
        .forEach((coord) => {
          let latLng = new window.google.maps.LatLng(coord.lat(), coord.lng());
          bounds.current.extend(latLng);
        });
    const center = bounds.current.getCenter();
    mapRef.current.setCenter(center);
    mapRef.current.panBy(1000, 0);

    drawingManager.current = new window.google.maps.drawing.DrawingManager({
      drawingMode: null,
      drawingControl: false,
      polylineOptions: {
        strokeColor: "yellow",
        strokeOpacity: 1,
        strokeWeight: 3,
      },
    });

    drawingManager.current.setMap(mapRef.current);

    window.google.maps.event.addListener(
        drawingManager.current,
        "overlaycomplete",
        handleDrawing
    );

    transectMarker.current = new window.google.maps.Marker({
      map: mapRef.current,
      label: "",
    });
  }, []);

  useEffect(() => {
    const values = Object.values(images);
    if (values.length > 0) {
      let tempYears = [];
      values.forEach((image, i) => {
        if(image.overlay !== undefined && image.classification !== undefined){
          image.overlay.setMap(mapRef.current);
          image.overlay.setOpacity(0);

          image.classification.setMap(mapRef.current);
          image.classification.setOpacity(i === values.length - 1 ? 1 : 0);
        }else{
          console.error("Image without overlay", image, i);
        }

        tempYears.push(image.date);
      });
      setYears(tempYears);
    }
  }, [images]);

  useEffect(() => {
    if (baseLine) {
      let tempTransectsObjects = [];

      if (transectsObjects.length > 0) {
        transectsObjects.forEach((object) => {
          object.setMap(null);
        });
        setTransectsObjects(tempTransectsObjects);
      }

      let newTransects = generateTransects();

      newTransects.forEach((object) => {
        let sidesMaps0 = object.sides[0].coords.map((arr) => {
          return { lat: arr[1], lng: arr[0] };
        });
        let sidesMaps1 = object.sides[1].coords.map((arr) => {
          return { lat: arr[1], lng: arr[0] };
        });

        let complete = [sidesMaps0.flat(), sidesMaps1.flat()].flat();

        const polyline = new window.google.maps.Polyline({
          path: complete,
          geodesic: true,
          strokeColor: "white",
          strokeOpacity: 1.0,
          strokeWeight: 3,
          transect: object.transect,
          clickable: true,
          zIndex: 90,
        });

        polyline.setMap(mapRef.current);

        tempTransectsObjects.push(polyline);
      });

      setTransectsData(newTransects);
      setTransectsObjects(tempTransectsObjects);
    }
  }, [transects, transectsScale]);

  useEffect(() => {
    /// TODO: REFATORAR TODO ESSE BLOCO

    if (Object.keys(transectsResults).length <= 0) {
      return;
    }

    let tempTransectsCalcs = {};
    // organiza data em ordem crescente
    let tempYears = years.sort((a, b) => a - b);

    // diferença de anos
    let yearsDifference = tempYears.sort().reduce((a, b) => b - a);

    // obtem o numero de transectos calculados
    let numberTransects = Object.values(transectsResults)[0].length;

    // juntas os dois anos de arrays
    let flattenResults = Object.values(transectsResults).flat();

    let csvData = `${t("forms.csqueeze.result.labels.id")};`;
    let csvYears = [...years.map(String), String(Number(years[years.length - 1]) + Number(previsao))];

    for (let csvYear of csvYears) {
      for (let landCover of flattenResults[0].landCoversIntersections) {
        csvData += `${csvYear}_${t(`forms.csqueeze.result.labels.${landCover.label}`)};`;
      }
    }

    csvData = csvData.slice(0, -1) + `\n`;

    // percorre todos os transectos calculados a partir de numberTransects
    for (let transect = 1; transect <= numberTransects; transect++) {
      if (flattenResults[transect] === null) continue;
      // cria variaveis auxiliares
      // eslint-disable-next-line no-unused-vars
      let centerCoords,
          landcoversOutPut = {},
          rate = {
            water: null,
            human: null,
            mangroove: null,
            vegetation: null,
          },
          extra = {
            humanLastYear: null,
            mangrooveLastYear: null,
          },
          outputtext = "";

      // obtem o objeto de transecto que ja foi desenhado no mapa
      const mapObject = transectsObjects.filter(
          (object) => object.transect === transect
      )[0];

      // filtra do flatten apenas resultados do transecto em questão
      // e organiza do ano mais recente para o mais antigo
      const dataTransect = flattenResults
          .filter((result) => result.id === transect)
          .sort((a, b) => b.year - a.year);

      // percorre os dados filtrados referentes ao transecto
      dataTransect.forEach((data, index) => {
        // obtem as coordenadas do centro do transecto
        centerCoords = data.center;

        // percorre cada landcover que teve interseção com o transecto
        data.landCoversIntersections.forEach((land) => {
          // cria um objeto com os dados do landcover em landcoversOutPut
          // caso ele ainda não tenha sido criado
          if (landcoversOutPut[land.label] === undefined)
            landcoversOutPut[land.label] = {
              id: land.label,
              landcover: land.classe,
              years: [],
              completeLine: data.completeLine,
            };

          // calcula o tamanho total do landcover no transecto
          let length = Number(
              land.lengthSide.reduce((a, b) => a + b).toFixed(2)
          );

          // o ponto mais próximo do landcover até a shoreline
          // esse valor é para obter recuo e avanço do mar
          let shoreLineDistance = Number(
              land.shoreLineDistance.sort()[1].toFixed(2)
          );

          // a distancia do maior lado do landcover até o centro do transecto
          // esse valor é para saber quanto de espaço há entre a humano e agua
          let baseLineDistance = Number(
              land.baseLineDistance.sort()[0].toFixed(2)
          );

          // organiza valores pra cálculo da taxa
          switch (land.label) {
              // se for mangue
            case 0:
              if (index === 0) extra.mangrooveLastYear = length;
              if (rate.mangroove === null) {
                rate.mangroove = length;
              } else {
                rate.mangroove -= length;
              }
              break;

              // se for vegetação
            case 1:
              if (rate.vegetation === null) {
                rate.vegetation = length;
              } else {
                rate.vegetation -= length;
              }
              break;

              // se for agua
            case 2:
              if (rate.water === null) {
                rate.water = baseLineDistance;
              } else {
                rate.water -= baseLineDistance;
              }
              break;

              // se for humano
            case 3:
              if (index === 0) extra.humanLastYear = shoreLineDistance;
              if (rate.human === null) {
                rate.human = shoreLineDistance;
              } else {
                rate.human = rate.human - shoreLineDistance;
              }
              break;

            default:
              break;
          }

          landcoversOutPut[land.label].years.push({
            year: data.year,
            length,
            completeLine: data.completeLine,
          });
        });
      });

      let rowsTemp = {};

      Object.values(landcoversOutPut)
          .sort((l1, l2) => l2.id - l1.id)
          .forEach((land) => {
            if (land.id === 2) return;

            // TODO: refatorar isso
            let id;
            switch (land.id) {
              case 0:
                id = 2;
                break;
              case 1:
                id = 1;
                break;
              case 2:
                id = 3;
                break;
              case 3:
                id = 0;
                break;

              default:
                break;
            }

            land.years
                .sort((a, b) => a.year - b.year)
                .forEach((year) => {
                  if (rowsTemp[year.year] === undefined)
                    rowsTemp[year.year] = [0.0, 0.0, 0.0, 0.0];
                  rowsTemp[year.year][id] = parseFloat(year.length);
                });

            if (rowsTemp[parseInt(years[1]) + previsao] === undefined)
              rowsTemp[parseInt(years[1]) + previsao] = [0.0, 0.0, 0.0, 0.0];

            let tempRateNumber = land.years
                .sort((a, b) => a.year - b.year)
                .reduce((a, b) => b.length - a.length);

            let rate = tempRateNumber / yearsDifference;

            let yearLength =
                land.years[1] !== undefined ? land.years[1].length : 0;

            let numberFuture = (rate * previsao + yearLength).toFixed(2);

            rowsTemp[parseInt(years[1]) + previsao][id] = parseFloat(
                numberFuture > 0 ? numberFuture : 0
            );
          });

      Object.values(landcoversOutPut)
          .sort((l1, l2) => l2.id - l1.id)
          .forEach((land) => {
            if (land.id !== 2) return;

            let id = 3;

            land.years
                .sort((a, b) => a.year - b.year)
                .forEach((year) => {
                  if (rowsTemp[year.year] === undefined)
                    rowsTemp[year.year] = [0.0, 0.0, 0.0, 0.0];

                  rowsTemp[year.year][id] = parseFloat(year.length);
                });

            if (rowsTemp[parseInt(years[1]) + previsao] === undefined)
              rowsTemp[parseInt(years[1]) + previsao] = [0.0, 0.0, 0.0, 0.0];

            let tempRateNumber = land.years
                .sort((a, b) => a.year - b.year)
                .reduce((a, b) => b.length - a.length);

            let rate = tempRateNumber / yearsDifference;

            let yearLength =
                land.years[1] !== undefined ? land.years[1].length : 0;

            let numberFuture = (rate * previsao + yearLength).toFixed(2);

            rowsTemp[parseInt(years[1]) + previsao][id] = parseFloat(
                numberFuture > 0 ? numberFuture : 0
            );
          });

      const originalTransectLength = rowsTemp[parseInt(years[1])].reduce((a, b) => a + b, 0);
      const transectLength = rowsTemp[parseInt(years[1])].reduce((a, b) => a + b, 0) + yearsDifference;

      if (transectLength > originalTransectLength) {
        let availableArea = originalTransectLength - rowsTemp[parseInt(years[1])][3]

        if (rowsTemp[parseInt(years[1]) + previsao][0] > availableArea) {
          rowsTemp[parseInt(years[1]) + previsao][0] = availableArea
        }
      }

      let rowsInfos = Object.entries(rowsTemp);
      let rows = [
        [
          t("forms.csqueeze.year"),
          t("forms.csqueeze.landcovers.human"),
          t("forms.csqueeze.landcovers.vegetation"),
          t("forms.csqueeze.landcovers.mangrove"),
          t("forms.csqueeze.landcovers.water"),
        ],
      ];

      for (let x = 0; x < rowsInfos.length; x++) {
        let rowInfo = rowsInfos[x];
        rows.push([rowInfo[0], ...rowInfo[1]]);
      }

      // mangue
      rows[3][3] = rows[2][3];

      // vegetação
      let futureVegetation = rows[2][2] - (rows[3][1] - rows[2][1]);
      rows[3][2] = futureVegetation > 0 ? futureVegetation : 0;

      if (futureVegetation < 0) {
        let finalMangue = rows[3][3] + futureVegetation;
        rows[3][3] = finalMangue > 0 ? finalMangue : 0;
      }

      // agua
      let diffWater = rows[2][4] - rows[1][4];
      let rateWater = diffWater / yearsDifference;
      let waterResult = rows[2][4] + rateWater * previsao;
      rows[3][4] = waterResult > 0 ? waterResult : 0;

      if (waterResult > rows[2][4]) {
        let diffMangue = rows[3][3] - (waterResult - rows[2][4]);
        rows[3][3] = diffMangue > 0 ? diffMangue : 0;
      }

      if (rows[2][3] > 0) {
        let diffMangrove = rows[3][3] - rows[2][3];
        let divMangrove = rows[3][3] / rows[2][3];

        if (diffMangrove < 0) {
          let squeeze = (divMangrove - 1) * 100;

          const threshold = -100;
          const epsilon = 1e-6;

          if (Math.abs(squeeze - threshold) < epsilon || squeeze <= -100) {
            outputtext = t("forms.csqueeze.result.output.fullSqueeze");
            mapObject.setOptions({ strokeColor: "red" });
          } else {
            outputtext = t("forms.csqueeze.result.output.squeeze").replace("{number}", Math.abs(squeeze.toFixed(2)));
            mapObject.setOptions({ strokeColor: "orange" });
          }

        } else {
          outputtext = t("forms.csqueeze.result.output.noSqueeze");
          mapObject.setOptions({ strokeColor: "green" });
        }

      } else {
        outputtext = t("forms.csqueeze.result.output.noMangrove");
        mapObject.setOptions({ strokeColor: "gray" });
      }

      let yearData = `${transect};`;

      for (let r = 1; r < rows.length; r++) {
        for (let c = 1; c < rows[r].length; c++) {
          yearData += `${(rows[r][c]).toFixed(2)};`;
        }
      }

      csvData += yearData.slice(0, -1) + `\n`;

      for (let r = 0; r < rows.length; r++) {
        if (tempTransectsCalcs[transect] === undefined)
          tempTransectsCalcs[transect] = [];

        tempTransectsCalcs[transect].push(rows[r]);
      }

      setTransectsCalcs({ ...tempTransectsCalcs });

      window.google.maps.event.clearInstanceListeners(mapObject);

      window.google.maps.event.addListener(mapObject, "mouseover", () => {
        mapObject.setOptions({ strokeWeight: 9 });
      });

      window.google.maps.event.addListener(mapObject, "mouseout", () => {
        mapObject.setOptions({ strokeWeight: 3 });
      });

      window.google.maps.event.addListener(mapObject, "click", () => {
        setSelectedTransect(transect);
      });
    }

    setTransectsCsv(csvData);
  }, [transectsResults, previsao]);

  useEffect(async () => {
    if (
        analysisDisabled &&
        Object.values(classLandCoversVectors).length === 2 &&
        waterLandCoverVectors.length === 2
    ) {
      let baseLineCoords = baseLine.overlay
          .getPath()
          .getArray()
          .map((coord) => {
            return Object.values(coord.toJSON()).reverse();
          });

      let baseLineTurf = turf.lineString(baseLineCoords);

      let baseLineBuffer = turf.buffer(baseLineTurf, transectsScale, {
        units: "meters",
      });

      let results = {};

      let yearsArray = Object.values(years);

      for (let i = 0; i < yearsArray.length; i++) {
        const year = yearsArray[i];

        results[year] = [];

        let yearClass = classLandCoversVectors[year];

        let waterGeometry = waterLandCoverVectors.filter(
            (water) => water.year === year
        )[0].geometry;

        let shoreLine = extractShoreLineTurf(baseLineBuffer, waterGeometry);

        setCalcMessage(
            t("forms.csqueeze.result.output.organizeYear").replace("{year}", year)
        );

        let messageTranslate = t(
            "forms.csqueeze.result.output.calculatingTransect"
        );

        await timer(10);
        for (let t = 0; t < transectsData.length; t++) {
          const transect = transectsData[t];

          let message = `${year}: ${messageTranslate} ${transect.transect}/${transectsData.length}`;

          setCalcMessage(message);

          let landCoversResult = landCoversIntersectionsTurf(
              transect,
              yearClass,
              shoreLine,
              year
          );

          await timer(10);

          results[year].push(landCoversResult);
        }

        setCalcMessage(
            t("forms.csqueeze.result.output.calculatingComplete").replace(
                "{year}",
                year
            )
        );
      }

      setTransectsResults(results);
      setFinished(true);
      setCalcMessage(t("forms.csqueeze.result.output.ready"));
      setShowLegendCard(true);
      setSelectedTransect(2);
      setTimeout(() => {
        setSelectedTransect(1);
        setAnalysisDisabled(false);
      }, 2000);
    }
  }, [analysisDisabled, classLandCoversVectors, waterLandCoverVectors]);

  useEffect(() => {
    transectsObjects.forEach((t) => {
      if (t.transect === parseInt(selectedTransect)) {
        const bounds = new window.google.maps.LatLngBounds();
        t.getPath()
            .getArray()
            .forEach((coord) => {
              let latLng = new window.google.maps.LatLng(
                  coord.lat(),
                  coord.lng()
              );
              bounds.extend(latLng);
            });
        const center = bounds.getCenter();

        transectMarker.current.setLabel(`${selectedTransect}`);
        transectMarker.current.setPosition(center);
        transectMarker.current.setVisible(true);

        t.setOptions({ strokeWeight: 9 });
      } else t.setOptions({ strokeWeight: 3 });
    });
  }, [selectedTransect]);

  function createCircle(point, distance) {
    const circleArea = Math.PI * distance * distance;
    const circleRadius = Math.sqrt(circleArea / Math.PI);
    return new window.google.maps.Circle({
      center: point,
      radius: circleRadius,
    });
  }

  function circleToPolyline(circle) {
    const circleCenter = circle.getCenter();
    const circleRadius = circle.getRadius();
    const path = [];

    for (let angle = 0; angle <= 360; angle += 10) {
      const point = window.google.maps.geometry.spherical.computeOffset(
          circleCenter,
          circleRadius,
          angle
      );
      path.push(point);
    }

    return path;
  }

  function lineToPoints(lineString, count, distance) {
    let numberTransects = count + 1;
    const path = lineString.getPath();
    const length = window.google.maps.geometry.spherical.computeLength(path);
    const step = distance || length / numberTransects;
    const points = [];

    for (let i = 1; i < path.length; i++) {
      const segmentLength =
          window.google.maps.geometry.spherical.computeDistanceBetween(
              path.getAt(i - 1),
              path.getAt(i)
          );
      const numPoints = Math.round(segmentLength / step);
      const heading = window.google.maps.geometry.spherical.computeHeading(
          path.getAt(i - 1),
          path.getAt(i)
      );

      for (let j = 0; j < numPoints; j++) {
        const distance = j * (segmentLength / numPoints);
        const point = window.google.maps.geometry.spherical.computeOffset(
            path.getAt(i - 1),
            distance,
            heading
        );
        points.push(point);
      }
    }

    return points;
  }

  const startCalc = async () => {
    setAnalysisDisabled(true);

    setCalcMessage(t("forms.csqueeze.result.loading.preparing"));

    await timer(50);

    if (
        classLandCoversVectors.length !== 2 &&
        waterLandCoverVectors.length !== 2
    ) {
      let baseLineCoords = baseLine.overlay
          .getPath()
          .getArray()
          .map((coord) => {
            return Object.values(coord.toJSON()).reverse();
          });

      let baseLineTurf = turf.lineString(baseLineCoords);

      // eslint-disable-next-line no-unused-vars
      let baseLineBuffer = turf.buffer(baseLineTurf, transectsScale, {
        units: "meters",
      }).geometry;

      let AOICoords = AOI.current
          .getPath()
          .getArray()
          .map((coord) => {
            return Object.values(coord.toJSON()).reverse();
          });

      Object.values(images).forEach((image) => {
        dispatch(
            CSqueeze.transformClassToPolygons(
                image.classified,
                image.imageObject.projection(),
                image.date,
                image.type,
                AOICoords
            )
        );
      });
    }
  };

  const generateTransects = () => {
    var points = lineToPoints(baseLine.overlay, transects);

    var outputTransects = [];
    points.forEach((point, i) => {
      if (i === 0) return;
      let circle = circleToPolyline(createCircle(point, 1));

      let circleLineCoords = circle.map((coord) => {
        return Object.values(coord.toJSON());
      });

      let baseLineCoords = baseLine.overlay
          .getPath()
          .getArray()
          .map((coord) => {
            return Object.values(coord.toJSON());
          });

      let turfCircle = turf.lineString(circleLineCoords);
      let turfBaseLine = turf.lineString(baseLineCoords);
      let turfIntersection = turf.lineIntersect(turfCircle, turfBaseLine);

      let turfTransect = turf.lineString([
        [
          turfIntersection.features[0].geometry.coordinates[1],
          turfIntersection.features[0].geometry.coordinates[0],
        ],
        [point.toJSON().lng, point.toJSON().lat],
      ]);
      let turfTransect2 = turfTransect;

      let turfTransectRotate = turf.transformRotate(turfTransect, 90, {
        pivot: [point.toJSON().lng, point.toJSON().lat],
      });
      let turfTransectRotate2 = turf.transformRotate(turfTransect2, -90, {
        pivot: [point.toJSON().lng, point.toJSON().lat],
      });

      let turfFullTransect = turf.multiLineString([
        turfTransectRotate.geometry.coordinates,
        turfTransectRotate2.geometry.coordinates,
      ]);

      let scaleTransect = turf.transformScale(turfFullTransect, transectsScale);

      outputTransects.push({
        transect: i,
        center: Object.values(point.toJSON()).reverse(),
        sides: [
          {
            side: 1,
            coords: scaleTransect.geometry.coordinates[0],
          },
          {
            side: 2,
            coords: scaleTransect.geometry.coordinates[1],
          },
        ],
      });
    });

    return outputTransects;
  };

  const handleTransects = (event, value) => {
    // let value = Math.abs(parseInt(event.target.innerText));
    if (!isNaN(value)) {
      if (value > 0) {
        setTransects(value);
      } else {
        setTransects(1);
      }
    }
  };

  const handleTransectsScale = (event, value) => {
    // let value = Math.abs(parseInt(event.target.innerText));
    if (!isNaN(value)) {
      if (value > 0) {
        setTransectsScale(value);
      } else {
        setTransectsScale(1);
      }
    }
  };

  const handlePrevisao = (event) => {
    let value = Math.abs(parseInt(event.target.value));
    if (typeof value === "number" && !isNaN(value)) {
      if (value > 0) {
        setPrevisao(value);
      }
    } else {
      setPrevisao(1);
    }
  };

  function handleDrawing(polyg) {
    if (isPolygonInside(polyg.overlay, AOI.current)) {
      setBaseLine(polyg);
      drawingManager.current.setDrawingMode(null);
      setConfirmDrawing(true);
    } else {
      polyg.overlay.setMap(null);
    }
  }

  function isPolygonInside(polygonInner, polygonOuter) {
    for (let i = 0; i < polygonInner.getPath().getLength(); i++) {
      if (
          !window.google.maps.geometry.poly.containsLocation(
              polygonInner.getPath().getAt(i),
              polygonOuter
          )
      ) {
        return false;
      }
    }
    return true;
  }

  const opacityChange = (level, image) => {
    image.setOpacity(level);
    return level;
  };

  const opacityBaseLineChange = (state) => {
    let visible = state ?? !baseLine.overlay.visible;
    baseLine.overlay.setVisible(visible);
    return visible;
  };

  const removeBaseLine = () => {
    if (baseLine.overlay) {
      baseLine.overlay.setVisible(false);
      baseLine.overlay.setMap(null);
    }
    setBaseLine(false);
  };

  const opacityTransectsChange = (state) => {
    transectsObjects.forEach((transect) => {
      transect.setVisible(state ?? !opacity[2].transects);
    });
    return !opacity[2].transects;
  };

  const removeTransects = () => {
    if (transectsObjects.length > 0) {
      transectsObjects.forEach((transect) => {
        transect.setMap(null);
      });
    }
    setTransectsData([]);
    setTransectsResults(false);
    setTransectsObjects([]);
  };

  const reset = () => {
    removeBaseLine();
    removeTransects();
    setDrawingDisabled(false);
    setSettingsDisabled(false);
    setAnalysisDisabled(false);
    setFinished(false);
    setShowLegendCard(false);
    transectMarker.current.setVisible(false);
  };

  function exportToCsv(filename, csv) {
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

    if (navigator.msSaveBlob) {
      // Para o Internet Explorer 10 e posterior
      navigator.msSaveBlob(blob, filename);
    } else {
      // Para outros navegadores
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);

      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      URL.revokeObjectURL(url);
    }
  }

  const handleRedo = () => {
    baseLine.overlay.setVisible(false);
    baseLine.overlay.setMap(null);
    setConfirmDrawing(false);
    drawingManager.current.setDrawingMode("polyline");
  };

  const handleConfirm = () => {
    setConfirmDrawing(false);
    setIsDrawing(false);
    setDrawingDisabled(true);
  };

  const handleShapefileExport = () => {
    try {
      const transectsCsvData = parseCsv(transectsCsv);

      const getTransectDataFromCsv = (index) => {
        return transectsCsvData[index - 1];
      };

      const baselineShp = {
        features: [
          {
            geometry: {
              type: "LineString",
              coordinates: baseLine.overlay.getPath().getArray().map(coord => [
                coord.lng(), coord.lat()
              ]),
            },
            properties: {
              name: "Baseline",
            },
          },
        ],
      };

      const transectsShp = {
        features: transectsData.map((transect) => {
          const csvData = getTransectDataFromCsv(transect.transect);

          return {
            geometry: {
              type: "LineString",
              coordinates: [
                ...transect.sides[0].coords,
                ...transect.sides[1].coords.reverse(),
              ].map(coord => [coord[0], coord[1]]),
            },
            properties: {
              ...csvData,
            },
          };
        }),
      };

      Export.table.toDevice.asShapefileGroup(
          [
            baselineShp,
            transectsShp,
          ],
          Export.defaultOptions.device.shapefileGroup(
              "layers",
              "baseline",
              "transects"
          )
      );
    } catch (error) {
      console.error("Erro ao exportar shapefiles:", error);
    }
  };

  const parseCsv = (csvString) => {
    const rows = csvString.trim().split("\n").map(row => row.split(";"));
    const headers = rows[0];
    return rows.slice(1).map((row) => {
      const obj = {};
      row.forEach((value, columnIndex) => {
        obj[headers[columnIndex]] = value;
      });
      return obj;
    });
  };

  return (
      <Box className={classes.wrapper}>
        <Grow in={!isDrawing} unmountOnExit>
          <Grid
              container
              justifyContent="space-between"
              id="mapScreen"
              className={classes.mapOverlay}
          >
            <Grid
                item
                className={classes.mapContainer}
                style={{
                  overflow: "auto",
                  maxHeight: "97vh",
                  "&::WebkitScrollbar": {
                    display: "none",
                  },
                  scrollbarWidth: "none",
                  msOverflowStyle: "none",
                }}
            >
              {drawingDisabled && (
                  <Card style={{ padding: 15, width: "100%" }} elevation={3} className={classes.card}>
                    <Button
                        color="secondary"
                        variant="outlined"
                        style={{
                          width: "100%",
                          height: 35,
                          padding: "inherit 20px",
                        }}
                        onClick={reset}
                    >
                      {t("forms.csqueeze.result.restart").toUpperCase()}
                    </Button>
                  </Card>
              )}

              {finished && transectsResults && (
                  <Box
                      display="flex"
                      flexDirection="column"
                      style={{ gap: 10, width: "100%" }}
                  >
                    <Card style={{ padding: 15 }} elevation={3}>
                      <Typography
                          fontSize={20}
                          style={{ fontWeight: "bold", marginBottom: 5 }}
                      >
                        {t("forms.csqueeze.result.forecast")}
                      </Typography>
                      <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          style={{ gap: 4 }}
                      >
                        <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              textAlign: "center",
                              alignItems: "center",
                            }}
                        >
                          <Radio
                              checked={previsao === 10}
                              onChange={handlePrevisao}
                              value="10"
                              name="radio-button-demo"
                              inputProps={{ "aria-label": 10 }}
                          />
                          <Typography variant="caption">
                            10 {t("forms.csqueeze.years")}
                          </Typography>
                        </div>
                        <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              textAlign: "center",
                              alignItems: "center",
                            }}
                        >
                          <Radio
                              checked={previsao === 25}
                              onChange={handlePrevisao}
                              value="25"
                              name="radio-button-demo"
                              inputProps={{ "aria-label": 25 }}
                          />
                          <Typography variant="caption">
                            25 {t("forms.csqueeze.years")}
                          </Typography>
                        </div>
                        <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              textAlign: "center",
                              alignItems: "center",
                            }}
                        >
                          <Radio
                              checked={previsao === 50}
                              onChange={handlePrevisao}
                              value="50"
                              name="radio-button-demo"
                              inputProps={{ "aria-label": 50 }}
                          />
                          <Typography variant="caption">
                            50 {t("forms.csqueeze.years")}
                          </Typography>
                        </div>
                        <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              textAlign: "center",
                              alignItems: "center",
                            }}
                        >
                          <Radio
                              checked={previsao === 100}
                              onChange={handlePrevisao}
                              value="100"
                              name="radio-button-demo"
                              inputProps={{ "aria-label": 100 }}
                          />
                          <Typography variant="caption">
                            100 {t("forms.csqueeze.years")}
                          </Typography>
                        </div>
                      </Box>
                    </Card>

                    <Card style={{ padding: 15 }} elevation={3}>
                      <Box
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                          justifyContent="center"
                          marginBottom={2}
                          style={{ gap: 10 }}
                      >
                        <Typography variant="h6">
                          {t("forms.csqueeze.result.transect")}
                        </Typography>
                        <TextField
                            type="number"
                            id="outlined-basic"
                            variant="outlined"
                            size="small"
                            inputProps={{ min: 1, max: transectsData.length }}
                            onChange={(e) => setSelectedTransect(e.target.value)}
                            value={selectedTransect}
                            style={{ width: 80, textAlign: "center" }}
                        />
                      </Box>

                      <TransectResult
                          data={[...transectsCalcs[selectedTransect]]}
                      />
                    </Card>

                    <Card style={{ padding: 15 }} elevation={3}>
                      <Button
                          color="secondary"
                          variant="contained"
                          style={{
                            width: "100%",
                            height: 35,
                            padding: "inherit 20px",
                            marginBottom: 10
                          }}
                          onClick={() =>
                              exportToCsv(
                                  `cassie_csqueeze_
                        ${years.join("-")}_results_
                        ${new Date().toLocaleDateString()}`,
                                  transectsCsv,
                              )
                          }
                      >
                        {t("forms.csqueeze.result.export").toUpperCase()}
                      </Button>

                      <Button
                          color="secondary"
                          variant="contained"
                          style={{
                            width: "100%",
                            height: 35,
                            padding: "inherit 20px",
                          }}
                          onClick={handleShapefileExport}
                      >
                        {t("forms.csqueeze.result.shp").toUpperCase()}
                      </Button>
                    </Card>
                  </Box>
              )}
              {!finished && (
                  <Card
                      style={{
                        padding: 15,
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        gap: 10,
                      }}
                      elevation={3}
                  >
                    <div
                        style={{
                          fontWeight: "bold",
                          borderBottom: "1px solid gray",
                          paddingBottom: 5,
                          marginBottom: 5,
                          fontSize: 20,
                        }}
                    >
                      {t("forms.csqueeze.result.steps")}
                    </div>
                    <Box
                        style={{ display: "flex", flexDirection: "column", gap: 10 }}
                    >
                      <Button
                          style={{ justifyContent: "left" }}
                          disabled={drawingDisabled}
                          color="primary"
                          startIcon={<GestureIcon />}
                          onClick={() => {
                            drawingManager.current.setDrawingMode("polyline");
                            if (baseLine) baseLine.overlay.setMap(null);
                            setBaseLine(false);
                            setIsDrawing(true);
                          }}
                      >
                        {t("forms.csqueeze.result.drawBaseline")}
                      </Button>
                      <Button
                          style={{ justifyContent: "left" }}
                          disabled={settingsDisabled || !drawingDisabled}
                          color="primary"
                          startIcon={<TimelineIcon />}
                          onClick={() => {
                            setTransects(6);
                            setIsSettings(true);
                            const center = bounds.current.getCenter();
                            mapRef.current.setCenter(center);
                          }}
                      >
                        {t("forms.csqueeze.result.configTransects")}
                      </Button>

                      <Button
                          style={{ justifyContent: "left" }}
                          disabled={!drawingDisabled || !settingsDisabled}
                          color="primary"
                          startIcon={<PlayArrowIcon />}
                          onClick={startCalc}
                      >
                        {t("forms.csqueeze.result.calcule")}
                      </Button>
                    </Box>
                  </Card>
              )}
            </Grid>
            <Grid
                style={{
                  display: "grid",
                  paddingTop: 10,
                  paddingRight: 10,
                  gap: "10px",
                  alignContent: "start",
                  overflow: "auto",
                  maxHeight: "79vh",
                  zIndex: 10,
                  "&::WebkitScrollbar": {
                    display: "none",
                  },
                  scrollbarWidth: "none",
                  msOverflowStyle: "none",
                }}
            >
              { showLegendCard && (
                  <Card style={{ padding: 15 }} elevation={3} className={classes.card}>
                    <Typography
                        fontSize={20}
                        style={{ fontWeight: "bold", marginBottom: 10 }}
                    >
                      {t("forms.csqueeze.result.colorLegend")}{" "}
                      {t("forms.csqueeze.result.transects").toLowerCase()}
                    </Typography>
                    <Box
                        display="grid"
                        gridTemplateColumns="auto 1fr"
                        alignItems="center"
                        style={{ gap: "10px", rowGap: "5px" }}
                    >
                      <Box
                          width={30}
                          height={5}
                          style={{ backgroundColor: "red" }}
                          borderRadius={5}
                      ></Box>
                      <Typography variant="body2">
                        {t("forms.csqueeze.result.legend.fullSqueeze")}
                      </Typography>
                      <Box
                          width={30}
                          height={5}
                          style={{ backgroundColor: "orange" }}
                          borderRadius={5}
                      ></Box>
                      <Typography variant="body2">
                        {t("forms.csqueeze.result.legend.moderateSqueeze")}
                      </Typography>
                      <Box
                          width={30}
                          height={5}
                          style={{ backgroundColor: "green" }}
                          borderRadius={5}
                      ></Box>
                      <Typography variant="body2">
                        {t("forms.csqueeze.result.legend.noSqueeze")}
                      </Typography>
                      <Box
                          width={30}
                          height={5}
                          style={{ backgroundColor: "gray" }}
                          borderRadius={5}
                      ></Box>
                      <Typography variant="body2">
                        {t("forms.csqueeze.result.legend.noMangrove")}
                      </Typography>
                      {/* <Box
                        width={30}
                        height={5}
                        style={{
                          backgroundColor: "white",
                          border: ".5px solid rgba(0,0,0,0.3)",
                        }}
                        borderRadius={5}
                      ></Box>
                      <Typography variant="body2">Não calculado</Typography> */}
                    </Box>
                  </Card>
              )}
              <Card style={{ padding: 15, height: "fit-content", overflow: "unset" }} elevation={3} className={classes.card}>
                <Typography
                    style={{
                      fontWeight: "bold",
                      paddingBottom: 5,
                      marginBottom: 5,
                    }}
                >
                  {t("forms.csqueeze.result.visibilityController")}
                </Typography>
                <Typography variant="caption">
                  {t("forms.csqueeze.result.visibilityControllerDesc")}
                </Typography>
              </Card>
              {Object.values(images)
                  .reverse()
                  .map((image, index) => (
                      <Card
                          style={{ padding: 15, height: "fit-content", overflow: "unset" }}
                          key={index}
                          elevation={3}
                      >
                        <div
                            style={{
                              fontWeight: "bold",
                              borderBottom: "1px solid gray",
                              paddingBottom: 5,
                              marginBottom: 5,
                            }}
                        >
                          {image.name} ({image.missionName})
                        </div>
                        <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "10px",
                              padding: "10px 0",
                            }}
                        >
                          <div
                              style={{
                                display: "grid",
                                gridTemplateColumns: "90px 1fr auto auto",
                                gap: 10,
                              }}
                          >
                            <div>Raster</div>
                            <Slider
                                key={opacity[index].raster > 0 ? 1 : 0}
                                value={opacity[index].raster}
                                step={0.05}
                                min={0.0}
                                max={1.0}
                                onChange={(_, value) => {
                                  let level = opacityChange(
                                      value,
                                      image.classification
                                  );
                                  setOpacity({
                                    ...opacity,
                                    [index]: { ...opacity[index], raster: level },
                                  });
                                }}
                                aria-labelledby="continuous-slider"
                            />
                            <IconButton
                                onClick={() => {
                                  let level = opacityChange(
                                      image.classification.opacity ? 0 : 1,
                                      image.classification
                                  );
                                  setOpacity({
                                    ...opacity,
                                    [index]: { ...opacity[index], raster: level },
                                  });
                                }}
                                style={{
                                  marginLeft: 14,
                                  width: 10,
                                  height: 10,
                                }}
                            >
                              {opacity[index].raster > 0 ? (
                                  <VisibilityIcon fontSize="large" color="primary" />
                              ) : (
                                  <VisibilityOffIcon fontSize="large" />
                              )}
                            </IconButton>
                            <IconButton
                                onClick={() =>
                                    window.open(image.classification.url, "download")
                                }
                                style={{
                                  width: 10,
                                  height: 10,
                                }}
                            >
                              <GetAppIcon fontSize="large" />
                            </IconButton>
                          </div>
                          <div
                              style={{
                                display: "grid",
                                gridTemplateColumns: "90px 1fr auto auto",
                                gap: 10,
                              }}
                          >
                            <div>Satellite image</div>
                            <Slider
                                key={opacity[index].overlay > 0 ? 1 : 0}
                                value={opacity[index].overlay}
                                step={0.05}
                                min={0.0}
                                max={1.0}
                                onChange={(_, value) => {
                                  let level = opacityChange(value, image.overlay);
                                  setOpacity({
                                    ...opacity,
                                    [index]: { ...opacity[index], overlay: level },
                                  });
                                }}
                                aria-labelledby="non-linear-slider"
                            />
                            <IconButton
                                onClick={() => {
                                  let level = opacityChange(
                                      image.overlay.opacity ? 0 : 1,
                                      image.overlay
                                  );
                                  setOpacity({
                                    ...opacity,
                                    [index]: { ...opacity[index], overlay: level },
                                  });
                                }}
                                style={{
                                  marginLeft: 14,
                                  width: 10,
                                  height: 10,
                                }}
                            >
                              {opacity[index].overlay > 0 ? (
                                  <VisibilityIcon fontSize="large" color="primary" />
                              ) : (
                                  <VisibilityOffIcon fontSize="large" />
                              )}
                            </IconButton>
                            <IconButton
                                onClick={() => window.open(image.url, "download")}
                                style={{
                                  width: 10,
                                  height: 10,
                                }}
                            >
                              <GetAppIcon fontSize="large" />
                            </IconButton>
                          </div>
                        </div>
                      </Card>
                  ))}

              {drawingDisabled && (
                  <Card style={{ padding: 15, height: "fit-content" }} elevation={3} className={classes.card}>
                    <div
                        style={{
                          fontWeight: "bold",
                          borderBottom: "1px solid gray",
                          paddingBottom: 5,
                          marginBottom: 5,
                        }}
                    >
                      {t("forms.csqueeze.result.polygons")}
                    </div>
                    <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                          padding: "10px 0",
                        }}
                    >
                      {settingsDisabled && (
                          <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                gap: 10,
                              }}
                          >
                            <div>{t("forms.csqueeze.result.transects")}</div>
                            <IconButton
                                onClick={() => {
                                  let level = opacityTransectsChange();
                                  setOpacity({
                                    ...opacity,
                                    2: { ...opacity[2], transects: level },
                                  });
                                }}
                                style={{
                                  marginLeft: 14,
                                  width: 10,
                                  height: 10,
                                }}
                            >
                              {opacity[2].transects ? (
                                  <VisibilityIcon fontSize="large" color="primary" />
                              ) : (
                                  <VisibilityOffIcon fontSize="large" />
                              )}
                            </IconButton>
                          </div>
                      )}

                      <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            gap: 10,
                          }}
                      >
                        <div>{t("forms.csqueeze.result.baseLine")}</div>
                        <IconButton
                            onClick={() => {
                              let level = opacityBaseLineChange();
                              setOpacity({
                                ...opacity,
                                2: { ...opacity[2], baseline: level },
                              });
                            }}
                            style={{
                              marginLeft: 14,
                              width: 10,
                              height: 10,
                            }}
                        >
                          {opacity[2].baseline ? (
                              <VisibilityIcon fontSize="large" color="primary" />
                          ) : (
                              <VisibilityOffIcon fontSize="large" />
                          )}
                        </IconButton>
                      </div>
                    </div>
                  </Card>
              )}
            </Grid>
          </Grid>
        </Grow>
        <Grow in={isDrawing} unmountOnExit paddingTop={2}>
          <Box
              display="flex"
              position="absolute"
              marginTop={6}
              justifyContent="center"
              width="100%"
              zIndex={99}
          >
            {!confirmDrawing && (
                <Card
                    style={{ width: "300px", padding: 15, textAlign: "center" }}
                    elevation={3}
                >
                  {t("forms.csqueeze.result.tipBaseline")}
                </Card>
            )}

            <Dialog
                open={confirmDrawing}
                onClose={() => setConfirmDrawing(false)}
                aria-labelledby="confirm-dialog-title"
                aria-describedby="confirm-dialog-description"
                PaperProps={{ style: { padding: 20, borderRadius: 12, textAlign: 'center' } }}
            >
              <DialogTitle id="confirm-dialog-title" style={{ textAlign: 'center' }}>
                {t("forms.csqueeze.result.confirmBaseline")}
              </DialogTitle>
              <DialogContent style={{ fontSize: '16px' }}>
                <p>{t("forms.csqueeze.result.confirmBaseline")}</p>
              </DialogContent>
              <DialogActions style={{ justifyContent: 'center' }}>
                <Button onClick={handleRedo} color="primary">
                  {t("forms.csqueeze.result.redo")}
                </Button>
                <Button onClick={handleConfirm} color="primary" autoFocus>
                  {t("forms.csqueeze.result.confirm")}
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        </Grow>
        <Grow in={isSettings} unmountOnExit paddingTop={2}>
          <Box
              display="flex"
              position="absolute"
              paddingTop={2}
              paddingLeft={2}
              justifyContent="start"
              alignItems="center"
              width="100%"
              height="100%"
              zIndex={99}
              style={{ backgroundColor: "rgba(0,0,0,0.3)" }}
          >
            <Card
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "350px",
                  gap: 10,
                  padding: 15,
                  textAlign: "center",
                }}
                elevation={3}
            >
              <p>{t("forms.csqueeze.result.defineTransects")}</p>

              <Typography id="discrete-slider" gutterBottom>
                {t("forms.csqueeze.result.quantityTransects")}
              </Typography>
              <Slider
                  defaultValue={10}
                  aria-labelledby="discrete-slider"
                  valueLabelDisplay="auto"
                  step={2}
                  marks
                  min={2}
                  max={200}
                  onChange={handleTransects}
                  onChangeCommitted={handleTransects}
              />

              <Typography id="discrete-slider" gutterBottom>
                {t("forms.csqueeze.result.lengthTransects")}
              </Typography>
              <Slider
                  defaultValue={100}
                  aria-labelledby="discrete-slider"
                  valueLabelDisplay="auto"
                  step={50}
                  marks
                  min={100}
                  max={5000}
                  onChange={handleTransectsScale}
                  onChangeCommitted={handleTransectsScale}
              />
              <Button
                  disabled={analysisDisabled}
                  color="primary"
                  onClick={() => {
                    setSettingsDisabled(true);
                    setIsSettings(false);
                  }}
              >
                {t("forms.csqueeze.result.confirm")}
              </Button>
              <Button
                  disabled={analysisDisabled}
                  color="primary"
                  onClick={() => {
                    setIsSettings(false);
                  }}
              >
                {t("forms.csqueeze.result.cancel")}
              </Button>
            </Card>
          </Box>
        </Grow>
        <Grow in={analysisDisabled} unmountOnExit paddingTop={2}>
          <Box
              display="flex"
              position="absolute"
              paddingTop={2}
              paddingLeft={2}
              justifyContent="center"
              alignItems="center"
              width="100%"
              height="100%"
              zIndex={99}
              style={{ backgroundColor: "rgba(0,0,0,0.3)" }}
          >
            <Paper
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 10,
                  paddingTop: 15,
                  paddingBottom: 15,
                  paddingLeft: 30,
                  paddingRight: 30,
                  textAlign: "center",
                  justifyContent: "center",
                  alignItems: "center",
                }}
            >
              {finished ? (
                  <DoneIcon fontSize="large" style={{ color: "green" }} />
              ) : (
                  <HourglassEmptyIcon color="secondary" fontSize="large" />
              )}
              <Typography align="center">{calcMessage}</Typography>
              <Typography variant="caption" align="center">
                {t("forms.csqueeze.result.loading.wait")}
              </Typography>
            </Paper>
          </Box>
        </Grow>
        <div
            id="google-maps"
            ref={(r) => (mapDiv.current = r)}
            style={{ width: "100%", height: "calc(100vh - 48px)" }}
        ></div>
        {/* <TourGuider
        steps={steps}
        isOpen={isTourOpen}
        setIsTourOpen={setIsTourOpen}
      /> */}
      </Box>
  );
};

export default ProcessingPage;
